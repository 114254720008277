.user-history-mobile {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 100px;
  padding-bottom: 70px;
  position: relative;
}

.user-history-mobile__sticky-block {
  display: flex;
  align-items: center;
  padding-left: 20px;
  height: 80px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background: #ffffff;
  box-shadow: 0px -1px 30px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.user-history-mobile__button-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  height: 40px;
  border: 1px solid #519025;
  box-sizing: border-box;
  border-radius: 10px;
  margin-bottom: 30px;
}

.user-history-mobile__button {
  border: none;
  background: none;
  outline: none;
  color: #519025;
  font-family: IBM Plex Sans;

  &--active {
    background: #519025;
    border: 1px solid #519025;
    box-sizing: border-box;
    border-radius: 10px;
    color: #ffffff;
  }
}

.paid-ticket {
  background: #ffffff;
  box-shadow: 0px 3px 10px 8px rgba(0, 0, 0, 0.06);
  border-radius: 20px;
  margin-bottom: 30px;
}

.paid-ticket-info {
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f3f3f3;

  &:last-of-type {
    background: #f9f9f9;
    border-radius: 20px 20px 0px 0px;
  }
}

.paid-ticket-info__title {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  color: #afafaf;
}

.paid-ticket-info__data {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: right;

  color: #0a0a0a;
}

.user-history-mobile__visit-add__title {
  margin-bottom: 15px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  /* identical to box height */

  color: #020202;
}

.user-history-mobile__visit-add__button {
  background: #519025;
  border-radius: 10px;
  height: 50px;
  width: 100%;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;

  color: #ffffff;
  margin-bottom: 30px;

  &:active {
    background: #698a52;
  }
}

.user-history-mobile-medical_visit-block {
  border-radius: 20px;
  background: #FFF;
  margin-bottom: 40px;
  box-shadow: 0px 3px 10px 8px rgba(0, 0, 0, 0.25);
}

.user-history-mobile-medical-info-comment {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #c6c6c6;
  padding: 20px 15px 20px 15px;

  .user-history-mobile-text {
    color: #AFAFAF;
    font-size: 14px;
    font-family: IBM Plex Sans;
    font-weight: 500;
  }

  .user-history-mobile-vale {
    color: #0A0A0A;
    font-size: 14px;
    font-family: IBM Plex Sans;
    font-weight: 500;
    margin-top: 8px;
    word-break: break-word;
  }
}

.user-history-mobile-medical-info {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #c6c6c6;
  padding: 20px 15px 20px 15px;

  .user-history-mobile-text {
    color: #AFAFAF;
    font-size: 14px;
    font-family: IBM Plex Sans;
    font-weight: 500;
  }

  .user-history-mobile-vale {
    color: #0A0A0A;
    text-align: right;
    font-size: 14px;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.user-history-mobile-medical-edit-button {
  display: block;
  color: #519025;
  font-size: 14px;
  font-family: IBM Plex Sans;
  font-weight: 500;
  background-color: transparent;
  border: none;
  margin: 0 auto;
  padding: 5px;

.user-history-icon {
  fill: #519025;
  margin-right: 5px;
}
}
