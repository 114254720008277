.refund-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;
  position: relative;
  // padding-top: 140px;
}

.refund-info__title {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;

  z-index: 2;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #2d2d2e;
  padding: 39px 0 18px 20px;
  margin-bottom: 40px;
  background: #ffffff;
  box-shadow: 0px -1px 30px rgba(0, 0, 0, 0.1);
}

.refund-info__container {
  margin-right: 20px;
  margin-left: 20px;
  border-radius: 20px;
  box-shadow: 0px 3px 10px 8px rgba(0, 0, 0, 0.06);
  position: relative;
  margin-bottom: 60px;
  width: 100%;
}

.refund-info__person-title {
  margin-bottom: 15px;
  align-self: flex-start;
  max-width: 290px;
  justify-self: flex-start;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;

  color: #020202;
}

.appointment_whith_doctor {
   width: 100%;
  height: 50px;
  left: 20px;
  top: 167px;

  // background: #519025;
  border-radius: 10px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  border: 2px solid #519025;
  color: #519025;

  margin-bottom: 30px;

}

.appointment_block {
  margin-bottom: 24px;
}

.category-form__block-doctor {
  display: flex;
  justify-content: space-between;
  width: 95px;
  padding: 15px;
  border: 1px solid #D9D9D9;
  background: #F9F9F9;
  border-radius: 10px;
}

.record_time_container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.record_time_block {
  border: 1px solid #D9D9D9;
  padding: 11px 18px 11px 18px;
  border-radius: 10px;
}

.record_time_text {
  color: #0A0A0A;
  font-size: 14px;
  font-family: IBM Plex Sans;
  font-weight: 500;
}

.speccialist-check-block {
  width: 100%;
  max-height: 300px;
  padding: 10px;
  background-color: #ffff;
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  margin-top: 10px;
  overflow: auto;
}

.textarea-styles {
  border-radius: 10px;
  resize: none;
  border: 1px solid #d9d9d9;
  padding: 15px;
  color: #0A0A0A;
  font-size: 14px;
  font-family: IBM Plex Sans;
  font-weight: 500;
}

.specialist-item {
  padding: 5px 0 5px 0;
  cursor: pointer;
}

.selected_time {
  background-color: #4F8B24;
}

.selected_time-text {
 color: #ffffff;
}

.category-form__block-input-doctor {
  //display: none;
  --webket-appearance: none;
  --moz-appearance: none;
  appearance: none;
  width: 24px;
  height: 24px;
}

.checked-sick-block {
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
    background-position: center;
  background-image: url("../../../../../assets/img/map-filter-check.svg");
}

.not-checked-sick-block {
  width: 24px;
  height: 24px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  background-repeat: no-repeat;
    background-position: center;
  background-image: url("../../../../../assets/img/doctor-sick-not-check.svg");  
}

.category-form__block-label-doctor {
  margin-bottom: 0 !important;
  line-height: 22px !important;
}

.doctor_form-checkbox {
  display: flex;
  gap: 14px;
  margin-top: 5px;
}

.refund_info__ticket-button {
  width: 100%;
  height: 50px;
  left: 20px;
  top: 167px;

  background: #519025;
  border-radius: 10px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;

  color: #ffffff;

  margin-bottom: 30px;
}

.refund-list-mobile {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 70px;
}

.refund-list-mobile__title {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  /* identical to box height */

  color: #020202;
  margin-bottom: 15px;
}

.refund-ticket {
  background: #ffffff;
  box-shadow: 0px 3px 10px 8px rgba(0, 0, 0, 0.16);
  border-radius: 20px;
  width: 100%;
  margin-bottom: 30px;
}

.refund-ticket__title {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;

  color: #ffffff;
  background: #12C0AB;
  border-radius: 20px 20px 0px 0px;
  padding: 15px 35px;
}

.refund-ticket__info-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
}

.refund-ticket__info-block-name {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  color: #afafaf;
}

.refund-ticket__info-block-value {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: right;

  color: #0a0a0a;
}

.refund-ticket__info-block-value-files {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  cursor: pointer;
}

.refund-ticket__file-name-block {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 13px;
  text-align: center;
  color: #757575;
  background: #e4e4e4;
  border-radius: 5px;
  padding: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.refund-ticket__info-block-button-wrapper {
  padding: 10px;
}

.refund-ticket__info-block-button {
  height: 40px;
  width: 100%;
  background: #e0ffd8;
  border-radius: 10px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;

  color: #519025 !important;

  &:active {
    background: #cdffc0;
    border-radius: 10px;
  }

  &:hover {
    border-radius: 10px;
  }

  &:disabled {
    background: #f2f2f2;
    border-radius: 10px;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;

    color: #cccccc !important;
  }
}

.refund-files-page__file-block {
  width: 100%;
  padding: 0 20px 80px 20px;
}

.refund-files-page__file-block__item {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.refund-files-page__file-block__title {
  margin-bottom: 15px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  /* identical to box height */

  color: #020202;
}

.refund-files-page__file-block__item-file {
  display: flex;
  align-items: center;
  height: 50px;
  background: #f9f9f9;
  border-radius: 10px;
  margin-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
}

.refund-files-page__file-block__item-file-image {
  width: 33px;
  height: 20px;
  margin-right: 15px;
}

.refund-files-page__file-block__item-file-text {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  color: #787e8a;
  margin-right: auto;
}

.refund-file-page-wrapper__mobile {
  height: 80px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px -1px 30px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

.calculate-container {
  padding: 10px;
}

.user-calculate-buttons {
  padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 70px;
    position: relative;
}

.polis-iframe {
  width: 100%;
  height: 70vh;
}