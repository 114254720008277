.svg-arrow-right-dims {
	width: 18px;
	height: 18px;
}

.svg-arrow-right__green3-dims {
	width: 18px;
	height: 14px;
}

.svg-arrow-right__white-dims {
	width: 18px;
	height: 14px;
}

.svg-arrow__btn-dims {
	width: 24px;
	height: 24px;
}

.svg-arrow__green-dims {
	width: 16px;
	height: 16px;
}

.svg-fb-dims {
	width: 40px;
	height: 40px;
}

.svg-fb-dark-dims {
	width: 40px;
	height: 40px;
}

.svg-in-dims {
	width: 40px;
	height: 40px;
}

.svg-in-dark-dims {
	width: 40px;
	height: 40px;
}

.svg-insta-dims {
	width: 40px;
	height: 40px;
}

.svg-insta-dark-dims {
	width: 40px;
	height: 40px;
}

.svg-load-dims {
	width: 18px;
	height: 18px;
}

.svg-quote-dims {
	width: 47px;
	height: 32px;
}

.svg-search-dims {
	width: 24px;
	height: 24px;
}

.svg-send-message-dims {
	width: 24px;
	height: 24px;
}

.svg-share-dims {
	width: 24px;
	height: 24px;
}

.svg-slick_arrow-dims {
	width: 24px;
	height: 24px;
}

.svg-star-dims {
	width: 24px;
	height: 24px;
}

.svg-telegram-dims {
	width: 40px;
	height: 40px;
}

.svg-telegram-dark-dims {
	width: 40px;
	height: 40px;
}

.svg-time-dims {
	width: 24px;
	height: 24px;
}

.svg-view-dims {
	width: 22px;
	height: 15px;
}

.svg-youtube-dims {
	width: 40px;
	height: 40px;
}

.svg-youtube-dark-dims {
	width: 40px;
	height: 40px;
}

