/* prefixed by https://autoprefixer.github.io (PostCSS: v7.0.23, autoprefixer: v9.7.3) */

@charset "UTF-8";
@import url(../svgSprite/symbol/sprite.css);
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

img,
fieldset,
a img {
  border: none;
}

input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
  -webkit-appearance: none;
}

input[type="submit"],
button {
  cursor: pointer;
}
input[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

textarea {
  overflow: auto;
}

input,
button {
  margin: 0;
  padding: 0;
  border: 0;
}

div,
input,
textarea,
select,
button,
h1,
h2,
h3,
h4,
h5,
h6,
a,
span,
a:focus {
  outline: none;
}

ul,
ol {
  list-style-type: none;
}

a {
  text-decoration: none;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
}

html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

@media (prefers-reduced-motion: reduce) {
  * {
    -webkit-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
  }
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 100;
  src: local("IBMPlexSans Thin"), local("Roboto-Thin"),
    url("../fonts/IBMPlexSans/IBMPlexSans-Thin.ttf");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("IBMPlexSans"), local("IBMPlexSans-Regular"),
    url("../fonts/IBMPlexSans/IBMPlexSans-Regular.ttf");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  src: local("IBMPlexSans Medium"), local("IBMPlexSans-Medium"),
    url("../fonts/IBMPlexSans/IBMPlexSans-Medium.ttf");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: local("IBMPlexSans Bold"), local("IBMPlexSans-Bold"),
    url("../fonts/IBMPlexSans/IBMPlexSans-Bold.ttf");
}

/* @font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  src: local("IBMPlexSans Thin"), local("IBMPlexSans-Black"), url("../fonts/fontsIBMPlexSans/IBMPlexSans-Thin.ttf"); } */

@media (max-width: 1600px) {
  .container-l {
    width: 100%;
    padding-right: 50px;
    padding-left: 50px;
  }
}

@media (min-width: 1600px) {
  .container-l {
    width: 100%;
    padding-right: 146px;
    padding-left: 146px;
  }
}

.container-m {
  max-width: 1125px;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
}

.content-m {
  width: calc((((100% - 0px - (0px * (12 - 1))) / 12) * 8) + (0px * (8 - 1)));
  margin-left: 0px;
  margin-right: 0px;
  margin: 0 auto;
}
@media only screen and (max-width: 768px) {
  .content-m {
    width: calc(
      (((100% - 0px - (0px * (12 - 1))) / 12) * 12) + (0px * (12 - 1))
    );
    margin-left: 0px;
    margin-right: 0px;
  }
}
.content-m .block__text p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #242e33;
  margin-bottom: 30px;
}

.button {
  padding: 14px 30px;
  border-radius: 50px;
  border: 1px solid transparent;
  display: inline-block;
  text-decoration: none;
  color: white;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.button:hover {
  cursor: pointer;
}
.button__success {
  background-color: #519025;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
.button__success:hover {
  background-color: #87ce55;
}
.button__red {
  background-color: #e50b0b;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
.button__red:hover {
  background-color: #d45c5c;
}
.button__white {
  border: 1px solid white;
  background-color: transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.button__white:hover {
  background-color: white;
  color: #242e33;
}
.button__silver {
  border: 1px solid #9ea8ab;
  background-color: transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #3d5157;
}
.button__silver:hover {
  color: white;
  background: #9ea8ab;
}
.button__gray {
  border: 1px solid #9ea8ab;
  background-color: transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  color: #3d5157;
}
.button__gray:hover {
  background-color: #9ea8ab;
  color: white;
}
.button__gray__load {
  border: 1px solid #3d5157;
  background-color: transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
.button__gray__load svg {
  margin-right: 10px;
}
.button__gray__load:hover {
  background-color: #3d5157;
  color: white;
}
.button.button__load {
  color: #242e33;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.button__text {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}
.button--outline {
  border-color: #3d5157;
}
.button--outline .button__text {
  color: #242e33;
}
.button .icon-right_arrow {
  fill: #3d5157;
}

.button-l {
  min-width: 230px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

h3.caption {
  font-style: normal;
  font-weight: bold;
  line-height: 36px;
  font-size: 32px;
  margin: 30px 0px;
}

h2.caption {
  font-style: normal;
  font-weight: bold;
  line-height: 36px;
  font-size: 32px;
}

h1.caption {
  font-style: normal;
  font-weight: bold;
  line-height: 52px;
  font-size: 46px;
}

.caption-ml {
  line-height: 26px;
  font-size: 18px;
  color: #242e33;
}

.caption-m {
  font-style: normal;
  font-weight: bold;
  line-height: 36px;
  font-size: 32px;
  margin: 30px 0px;
}

.caption-l {
  font-style: normal;
  font-weight: bold;
  line-height: 52px;
  font-size: 46px;
}

.l-wrap {
  overflow-x: hidden;
  overflow-y: hidden;
}

.header {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-bottom: 10px;
}
@media only screen and (max-width: 1100px) {
  .header {
    padding-bottom: 0px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100px;
    background: #f5f6f7;
  }
}
.header:after {
  content: "";
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  height: 1px;
  width: calc(100% - 30px);
  position: absolute;
  bottom: 0px;
  background: rgba(255, 255, 255, 0.5);
}
.header__logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  max-width: 165px;
}
@media only screen and (max-width: 1100px) {
  .header__logo {
    padding: 10px 0px;
  }
}
.header__logo img {
  -o-object-fit: contain;
  object-fit: contain;
  width: 100%;
}
.header .header__mobile__naw {
  display: none;
}
@media only screen and (max-width: 1100px) {
  .header .header__mobile__naw {
    width: calc(
      (((100% - 20px - (20px * (12 - 1))) / 12) * 10) + (20px * (10 - 1))
    );
    margin-left: 10px;
    margin-right: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .header .header__mobile__naw .button {
    padding: 10px 15px;
  }
}
.header__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
@media only screen and (max-width: 1100px) {
  .header__wrapper {
    display: none;
  }
}
.header__wrapper__about {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 20px 0px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.header__wrapper__about__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: calc(
    (((100% - 40px - (40px * (12 - 1))) / 12) * 0px) + (40px * (0px - 1))
  );
  margin-left: 20px;
  margin-right: 20px;
  font-style: normal;
  color: #ffffff;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}
.header__wrapper__about__item:last-child {
  margin-right: 0px;
  margin-left: 10px;
}
@media only screen and (max-width: 1156px) {
  .header__wrapper__about__item {
    margin: 0 10px;
  }
}
.header__wrapper__about__item a {
  font-style: normal;
  color: #ffffff;
  cursor: pointer;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}
.header__wrapper__about__item a:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;
  height: 2px;
  background: white;
  opacity: 0;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: translateY(-5px) scale(0);
  -ms-transform: translateY(-5px) scale(0);
  transform: translateY(-5px) scale(0);
}
.header__wrapper__about__item a:hover:after {
  opacity: 1;
  -webkit-transform: translateY(2px) scale(1);
  -ms-transform: translateY(2px) scale(1);
  transform: translateY(2px) scale(1);
}
.header__wrapper__about__item .social__link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 5px;
  border-radius: 50%;
  overflow: hidden;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.header__wrapper__about__item .social__link:first-child {
  margin-left: 0px;
}
.header__wrapper__about__item .social__link:last-child {
  margin-right: 0px;
}
.header__wrapper__about__item .social__link:after {
  display: none;
}
.header__wrapper__about__item .social__link:hover {
  -webkit-transform: translateY(-3px);
  -ms-transform: translateY(-3px);
  transform: translateY(-3px);
}
.header__wrapper__about__item .social__link svg {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.header__wrapper__about__item .social__link svg:hover {
  background: #519025;
}
.header__wrapper__about .header__wrapper__about__item__button {
  margin: 0px;
}
.header__wrapper__about .header__wrapper__about__item__button .button__success {
  font-size: 14px;
  line-height: 21px;
  padding: 10px 30px;
}
.header__wrapper__about .header__wrapper__about__item__lang {
  position: relative;
}
.header__wrapper__about .header__wrapper__about__item__lang .lang {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
}
.header__wrapper__about .header__wrapper__about__item__lang .nav__link__sub {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: absolute;
  top: 40px;
  left: -15px;
  padding: 10px 0px;
  border-radius: 10px;
  background-color: #519025;
  -webkit-box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15);
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(-30px);
  -ms-transform: translateY(-30px);
  transform: translateY(-30px);
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.header__wrapper__about
  .header__wrapper__about__item__lang
  .nav__link__sub:after {
  content: "";
  width: 10px;
  position: absolute;
  height: 10px;
  left: 45px;
  top: -20px;
  border: 10px solid transparent;
  border-bottom: 10px solid #519025;
  z-index: 10;
}
.header__wrapper__about .header__wrapper__about__item__lang .nav__link__sub a {
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
  white-space: nowrap;
  margin-bottom: 5px;
  text-transform: initial;
  padding: 10px 30px;
  width: 100%;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}
.header__wrapper__about
  .header__wrapper__about__item__lang
  .nav__link__sub
  a:after {
  display: none;
}
.header__wrapper__about
  .header__wrapper__about__item__lang
  .nav__link__sub
  a:hover {
  background-color: #6ea945;
}
.header__wrapper__about
  .header__wrapper__about__item__lang.open
  .nav__link__sub {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}
.header__wrapper__nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 20px 0px;
}
.header__wrapper__nav .nav__link {
  margin-left: 40px;
  position: relative;
}
@media only screen and (max-width: 1280px) {
  .header__wrapper__nav .nav__link {
    margin-left: 15px;
  }
}
.header__wrapper__nav .nav__link a {
  font-size: 14px;
  line-height: 21px;
  color: white;
  font-weight: 500;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  cursor: pointer;
  position: relative;
}
.header__wrapper__nav .nav__link .nav__link--link {
  font-size: 14px;
  line-height: 21px;
  color: white;
  font-weight: 500;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  cursor: pointer;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.header__wrapper__nav .nav__link .nav__link--link:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -33px;
  width: 100%;
  height: 4px;
  background: white;
  opacity: 0;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: translateY(0px) scale(1);
  -ms-transform: translateY(0px) scale(1);
  transform: translateY(0px) scale(1);
}
.header__wrapper__nav .nav__link .nav__link--link:hover:after {
  opacity: 1;
  -webkit-transform: translateY(0px) scale(1);
  -ms-transform: translateY(0px) scale(1);
  transform: translateY(0px) scale(1);
}
.header__wrapper__nav .nav__link a:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -33px;
  width: 100%;
  height: 3px;
  background: white;
  opacity: 0;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: translateY(0px) scale(1);
  -ms-transform: translateY(0px) scale(1);
  transform: translateY(0px) scale(1);
}
.header__wrapper__nav .nav__link a:hover:after {
  opacity: 1;
  -webkit-transform: translateY(0px) scale(1);
  -ms-transform: translateY(0px) scale(1);
  transform: translateY(0px) scale(1);
}
.header__wrapper__nav .nav__link__sub {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  position: absolute;
  left: -30px;
  top: 40px;
  padding: 10px 0px;
  border-radius: 10px;
  background-color: #519025;
  -webkit-box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15);
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(-24px);
  -ms-transform: translateY(-24px);
  transform: translateY(-24px);
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.header__wrapper__nav .nav__link__sub:after {
  content: "";
  width: 10px;
  position: absolute;
  height: 10px;
  left: 45px;
  top: -20px;
  border: 10px solid transparent;
  border-bottom: 10px solid #519025;
  z-index: 10;
}
.header__wrapper__nav .nav__link__sub a {
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
  white-space: nowrap;
  margin-bottom: 5px;
  text-transform: initial;
  padding: 10px 30px;
  width: 100%;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}
.header__wrapper__nav .nav__link__sub a:after {
  display: none;
}
.header__wrapper__nav .nav__link__sub a:hover {
  background-color: #6ea945;
}
.header__wrapper__nav .nav__link.open .nav__link__sub {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.header__dark .logo_color__mobile {
  display: none;
}

.header__dark .header__wrapper__about__item__lang i {
  color: #242e33;
}

.header__dark .logo_color {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.header__dark .header__wrapper__about__item a {
  color: #3d5157;
}

.header__dark .header__wrapper__nav .nav__link a {
  color: #3d5157;
}

.header__dark .header__wrapper__nav .nav__link .nav__link--link {
  color: #3d5157;
}

.header__dark .header__wrapper__nav .nav__link__sub a {
  color: white;
}

.header__dark .header__wrapper__nav .nav__link a:after {
  background-color: #3d5157;
  bottom: -34px;
}

.header__dark .header__wrapper__nav .nav__link .nav__link--link:after {
  background-color: #3d5157;
  bottom: -34px;
}

.header__dark .header__wrapper__about__item a:after {
  background-color: #3d5157;
}

.header__dark .mobile__menu i {
  background-color: #242e33;
}

.sitemep {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: fixed;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0px;
  background-color: rgba(0, 0, 0, 0.2);
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  opacity: 0.3;
}
.sitemep:hover {
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 1;
}
.sitemep a {
  font-size: 18px;
  line-height: 32px;
  font-weight: 500;
  color: white;
  -webkit-transform: translateX(-20px) scale(1);
  -ms-transform: translateX(-20px) scale(1);
  transform: translateX(-20px) scale(1);
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  padding-left: 20px;
}
.sitemep a:hover {
  -webkit-transform: translateX(20px) scale(1.1);
  -ms-transform: translateX(20px) scale(1.1);
  transform: translateX(20px) scale(1.1);
  background-color: rgba(212, 212, 212, 0.9);
  color: #242e33;
}
.sitemep a:visited {
  background-color: #6a9a49;
  color: white;
}

.mobile__menu {
  width: 25px;
  height: 30px;
  display: none;
}
@media only screen and (max-width: 1100px) {
  .mobile__menu {
    display: block;
  }
}
.mobile__menu i {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 3px;
  width: 100%;
  background-color: white;
  margin: 6px 0px;
}

.mibile__menu__wrap {
  display: none;
}
@media only screen and (max-width: 1100px) {
  .mibile__menu__wrap {
    position: fixed;
    top: 0px;
    right: 0px;
    display: block;
    -webkit-transform: translateX(450px);
    -ms-transform: translateX(450px);
    transform: translateX(450px);
    height: 100vh;
    background-color: white;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
  }
  .mibile__menu__wrap.open {
    -webkit-transform: translateX(0px);
    -ms-transform: translateX(0px);
    transform: translateX(0px);
    display: block;
    z-index: 300;
    overflow: auto;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
  }
}
@media only screen and (max-width: 1100px) and (max-width: 588px) {
  .mibile__menu__wrap.open {
    width: 100%;
  }
}
@media only screen and (max-width: 1100px) {
  .mibile__menu__wrap__head {
    padding: 10px 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background-color: #f9f9f9;
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 20;
    height: 100px;
  }
}
@media only screen and (max-width: 1100px) and (max-width: 768px) {
  .mibile__menu__wrap__head {
    background-color: #f9f9f9;
  }
}
@media only screen and (max-width: 1100px) {
  .mibile__menu__wrap__head i {
    font-size: 24px;
    color: black;
  }
  .mibile__menu__wrap__head .logo {
    max-height: 55px;
    height: 55px;
    max-width: 165px;
  }
  .mibile__menu__wrap__head .logo img {
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
  }
}
.mibile__menu__wrap .mibile__menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: white;
}
.mibile__menu__wrap .mibile__menu .header__wrapper__about .nav__link {
  margin: 0px;
  margin-bottom: 30px;
}
.mibile__menu__wrap .mibile__menu .header__wrapper__about .nav__link a {
  color: #3d5157;
  font-weight: 600;
  font-size: 16px;
  line-height: normal;
  letter-spacing: 0.8px;
  text-transform: uppercase;
}
.mibile__menu__wrap
  .mibile__menu
  .header__wrapper__about
  .nav__link
  .nav__link__sub {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  position: relative;
  width: 100%;
  left: 0px;
  top: 0px;
  max-height: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  overflow: hidden;
}
.mibile__menu__wrap
  .mibile__menu
  .header__wrapper__about
  .nav__link
  .nav__link__sub
  a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0px;
  color: #242424;
  line-height: 30px;
  font-weight: 500;
  font-size: 18px;
  width: 100%;
  letter-spacing: 0.8px;
  text-transform: none;
}
.mibile__menu__wrap
  .mibile__menu
  .header__wrapper__about
  .nav__link
  .nav__link__sub:after {
  display: none;
}
.mibile__menu__wrap
  .mibile__menu
  .header__wrapper__about
  .nav__link.open
  .nav__link__sub {
  max-height: 300px;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin-top: 0px;
}
.mibile__menu__wrap
  .mibile__menu
  .header__wrapper__about
  .nav__link.open
  .nav__link__sub:after {
  display: none;
}
.mibile__menu__wrap .mibile__menu .header__wrapper__nav {
  padding: 0 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-bottom: 0px;
}
.mibile__menu__wrap .mibile__menu .header__wrapper__nav .nav__link {
  margin: 0px;
  margin-bottom: 30px;
}
.mibile__menu__wrap .mibile__menu .header__wrapper__nav .nav__link a {
  color: #3d5157;
  font-weight: 600;
  font-size: 21px;
  line-height: normal;
  letter-spacing: 0.8px;
  text-transform: uppercase;
}
.mibile__menu__wrap
  .mibile__menu
  .header__wrapper__nav
  .nav__link
  .nav__link--link {
  color: #3d5157;
  font-weight: 600;
  font-size: 21px;
  line-height: normal;
  letter-spacing: 0.8px;
  text-transform: uppercase;
}
.mibile__menu__wrap
  .mibile__menu
  .header__wrapper__nav
  .nav__link
  .nav__link__sub {
  position: relative;
  width: 100%;
  left: 0px;
  top: 0px;
  max-height: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.mibile__menu__wrap
  .mibile__menu
  .header__wrapper__nav
  .nav__link
  .nav__link__sub
  a {
  padding: 0px;
  color: #242424;
  line-height: 30px;
  font-weight: 500;
  font-size: 14px;
  width: 100%;
  padding: 0px 10px;
  letter-spacing: 0.8px;
  text-transform: none;
}
.mibile__menu__wrap
  .mibile__menu
  .header__wrapper__nav
  .nav__link
  .nav__link__sub:after {
  display: none;
}
.mibile__menu__wrap
  .mibile__menu
  .header__wrapper__nav
  .nav__link.open
  .nav__link__sub {
  max-height: 300px;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin-top: 10px;
}
.mibile__menu__wrap
  .mibile__menu
  .header__wrapper__nav
  .nav__link.open
  .nav__link__sub:after {
  display: none;
}
.mibile__menu__wrap .mibile__menu .header__wrapper__about {
  padding: 0 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.mibile__menu__wrap .mibile__menu .header__wrapper__about__item {
  margin: 0px;
}
.mibile__menu__wrap .mibile__menu .header__wrapper__about__item a {
  color: #242e33;
  font-size: 18px;
  line-height: 32px;
  font-weight: 500;
}
.mibile__menu__wrap .mibile__menu .header__wrapper__about .button {
  margin-bottom: 30px;
}

.logo__color__index {
  display: none;
}

.hero .container-header {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.container-header {
  -webkit-box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.2);
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.container-header .logo__color__index__mobile__color {
  display: none;
}
.container-header .logo__color__index__mobile {
  display: none;
}
@media only screen and (max-width: 475px) {
  .container-header .logo__color__index__mobile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
@media only screen and (max-width: 475px) {
  .container-header .logo__white {
    display: none;
  }
}
.container-header.mini {
  -webkit-box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.2);
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  z-index: 99;
  background-color: white;
  position: fixed;
  width: 100%;
  top: -1px;
  left: 0px;
}
.container-header.mini .lang__mobile .lang {
  color: #282828;
}
.container-header.mini .lang__mobile i {
  color: #282828;
}
@media only screen and (max-width: 475px) {
  .container-header.mini .logo__color__index__mobile {
    display: none;
  }
}
.container-header.mini .logo__color__index__mobile__color {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.container-header.mini .mobile__menu {
  cursor: pointer;
}
.container-header.mini .mobile__menu i {
  background-color: #242e33;
}
.container-header.mini .header {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  padding-bottom: 0px;
}
.container-header.mini .logo__color__index {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media only screen and (max-width: 475px) {
  .container-header.mini .logo__color__index {
    display: none;
  }
}
.container-header.mini .logo__white {
  display: none;
}
.container-header.mini .header__wrapper__about {
  height: 0px;
  overflow: hidden;
  margin: 0;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.container-header.mini .header__wrapper__nav .nav__link a {
  color: #242e33;
}
.container-header.mini .header__wrapper__nav .nav__link .nav__link--link {
  color: #242e33;
}
.container-header.mini .nav__link__sub a {
  color: white !important;
}

.header__wrapper__nav .nav__link:first-child > .nav__link__sub {
  background-color: #519025;
}
.header__wrapper__nav .nav__link:first-child > .nav__link__sub:after {
  border-bottom-color: #519025;
}

.header__wrapper__about__item__lang.lang__mobile {
  margin: 0px 0px 0px auto;
  position: relative;
}
.header__wrapper__about__item__lang.lang__mobile .lang {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
}
.header__wrapper__about__item__lang.lang__mobile .nav__link__sub {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: absolute;
  top: 40px;
  left: -15px;
  padding: 10px 0px;
  border-radius: 10px;
  background-color: #519025;
  -webkit-box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15);
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(-30px);
  -ms-transform: translateY(-30px);
  transform: translateY(-30px);
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.header__wrapper__about__item__lang.lang__mobile .nav__link__sub:after {
  content: "";
  width: 10px;
  position: absolute;
  height: 10px;
  left: 45px;
  top: -20px;
  border: 10px solid transparent;
  border-bottom: 10px solid #519025;
  z-index: 10;
}
.header__wrapper__about__item__lang.lang__mobile .nav__link__sub a {
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
  white-space: nowrap;
  margin-bottom: 5px;
  text-transform: initial;
  padding: 10px 30px;
  width: 100%;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}
.header__wrapper__about__item__lang.lang__mobile .nav__link__sub a:after {
  display: none;
}
.header__wrapper__about__item__lang.lang__mobile .nav__link__sub a:hover {
  background-color: #6ea945;
}
.header__wrapper__about__item__lang.lang__mobile.open .nav__link__sub {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.hero {
  background: -webkit-gradient(
      linear,
      left bottom,
      left top,
      from(rgba(0, 0, 0, 0.45)),
      to(rgba(0, 0, 0, 0.45))
    ),
    -webkit-gradient(linear, left bottom, left top, from(rgba(193, 213, 85, 0.6)), to(rgba(193, 213, 85, 0.6)));
  background: -o-linear-gradient(
      bottom,
      rgba(0, 0, 0, 0.45),
      rgba(0, 0, 0, 0.45)
    ),
    -o-linear-gradient(bottom, rgba(193, 213, 85, 0.6), rgba(193, 213, 85, 0.6));
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)),
    linear-gradient(0deg, rgba(193, 213, 85, 0.6), rgba(193, 213, 85, 0.6));
  background-blend-mode: normal, lighten, normal;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  overflow: hidden;
  top: 0px;
  left: 0px;
}
.hero .hero__wrapper {
  margin-top: 80px;
  z-index: 10;
}
@media only screen and (max-width: 768px) {
  .hero .hero__wrapper {
    padding-bottom: 80px;
  }
}
.hero .hero__wrapper .caption {
  font-size: 46px;
  line-height: 52px;
  font-weight: 600;
  color: white;
  max-width: 500px;
}
@media only screen and (max-width: 1260px) {
  .hero .hero__wrapper .caption {
    font-size: 38px;
  }
}
.hero .hero__wrapper__desc {
  max-width: 650px;
  margin-top: 50px;
}
.hero .hero__wrapper__desc p {
  font-size: 18px;
  line-height: 32px;
  font-weight: 500;
  color: white;
}
.hero .hero__wrapper .button {
  margin-top: 50px;
  padding: 20px 40px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  z-index: 99;
}
@media only screen and (max-width: 600px) {
  .hero .hero__wrapper .button {
    margin: 0 auto;
    margin-top: 50px;
  }
}
@media only screen and (max-width: 468px) {
  .hero .hero__wrapper .button {
    padding: 20px 15px;
  }
}
.hero .hero__wrapper .button svg {
  margin-left: 30px;
}
@media only screen and (max-width: 468px) {
  .hero .hero__wrapper .button svg {
    margin-left: 15px;
  }
}
.hero .hero__wrapper__count-work {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 60px;
  margin-bottom: 50px;
}
.hero .hero__wrapper__count-work .count-work {
  width: calc(
    (((100% - 20px - (20px * (12 - 1))) / 12) * 3) + (20px * (3 - 1))
  );
  margin-left: 10px;
  margin-right: 10px;
}
@media only screen and (max-width: 1060px) {
  .hero .hero__wrapper__count-work .count-work {
    width: calc(
      (((100% - 30px - (30px * (12 - 1))) / 12) * 4) + (30px * (4 - 1))
    );
    margin-left: 15px;
    margin-right: 15px;
  }
}
@media only screen and (max-width: 1060px) and (max-width: 768px) {
  .hero .hero__wrapper__count-work .count-work {
    display: none;
  }
}
.hero .hero__wrapper__count-work .count-work .number {
  color: white;
  font-size: 46px;
  line-height: 52px;
  font-weight: 600;
  margin-bottom: 10px;
}
.hero .hero__wrapper__count-work .count-work span {
  color: white;
  font-size: 18px;
  line-height: 32px;
  font-weight: 500;
  line-height: 26px;
}

.hero {
  width: 100%;
  position: relative;
}
.hero .hero__bg__wrap {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: -1;
}
.hero .hero__bg__wrap .slick-list.draggable {
  height: 100%;
  z-index: -1;
}
.hero .slick-track {
  height: 100%;
}
.hero .slick-initialized .slick-slide {
  height: 100%;
}
.hero .slick-initialized .slick-slide div {
  height: 100%;
}
.hero .slick-dots {
  width: 100px;
  right: 100px;
  bottom: 15px;
}
@media only screen and (max-width: 600px) {
  .hero .slick-dots {
    bottom: -30px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}
.hero .slick-dots li {
  background-color: rgba(236, 238, 238, 0.2);
  border-radius: 50%;
  overflow: hidden;
  width: 10px;
  height: 10px;
}
.hero .slick-dots li button {
  background-color: #eceeee;
  opacity: 0.3;
}
.hero .slick-dots li button:before {
  background: transparent;
  color: transparent;
}
.hero .slick-dots li.slick-active button {
  background-color: #eceeee;
  opacity: 1;
}
.hero .slick-dots li.slick-active button:before {
  background: green;
  color: transparent;
  opacity: 1;
}
.hero .slider__bg__top {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  height: 100%;
  z-index: -1;
}

.request-call {
  background-color: #519025;
  border-radius: 10px 10px 0px 0px;
  padding: 20px 30px;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  position: fixed;
  right: -59px;
  bottom: 25%;
  cursor: pointer;
  color: #242e33;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  z-index: 200;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  opacity: 1;
}
@media only screen and (max-width: 768px) {
  .request-call {
    display: none;
  }
}
@media only screen and (max-height: 768px) {
  .request-call {
    bottom: 10%;
  }
}
@media only screen and (max-height: 768px) and (max-height: 568px) {
  .request-call {
    bottom: 30%;
  }
}
.request-call:hover {
  background-color: #87ce55;
  color: white;
  opacity: 1;
}

.vocabulary__mini {
  position: fixed;
  top: 20%;
  width: 345px;
  min-width: 345px;
  max-width: 345px;
  right: -345px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  z-index: 201;
}
@media only screen and (max-width: 1100px) {
  .vocabulary__mini {
    top: 15%;
  }
}
@media only screen and (max-width: 1100px) and (max-width: 768px) {
  .vocabulary__mini {
    display: none;
  }
}
@media only screen and (max-height: 768px) {
  .vocabulary__mini {
    top: 0;
  }
}
@media only screen and (max-height: 768px) and (max-height: 568px) {
  .vocabulary__mini {
    display: none;
  }
}
.vocabulary__mini.open {
  right: 0px;
}
.vocabulary__mini.open:after {
  opacity: 1;
  background-color: #87ce55;
  color: white;
}
.vocabulary__mini.open:hover:after {
  background-color: #87ce55;
}
.vocabulary__mini:after {
  color: #242e33;
  opacity: 1;
  content: "Страховой словарь";
  padding: 20px 30px;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  left: -135px;
  top: 149px;
  position: absolute;
  background-color: #519025;
  border-radius: 10px 10px 0px 0px;
  cursor: pointer;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
}
@media only screen and (max-height: 768px) {
  .vocabulary__mini:after {
    top: 190px;
  }
}
.vocabulary__mini:hover:after {
  background-color: #87ce55;
  color: white;
  opacity: 1;
}
.vocabulary__mini__head {
  background-color: #bff1bb;
  padding: 25px 30px;
}
.vocabulary__mini__head .caption {
  margin: 0px;
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
}
.vocabulary__mini__head .close__modal {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 20px;
  color: #3d5157;
  cursor: pointer;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.vocabulary__mini__head .close__modal:hover {
  -webkit-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}
.vocabulary__mini__body {
  width: 100%;
  background-color: white;
  position: relative;
}
.vocabulary__mini__body .search__wrap {
  margin-bottom: 20px;
  width: 100%;
}
.vocabulary__mini__body .search__wrap .input__wrap {
  width: 100%;
  margin: 0;
  margin-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.vocabulary__mini__body .search__wrap .input__wrap .search {
  border-radius: 3px;
  margin: 0 20px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
.vocabulary__mini__body .search__wrap .input__wrap .search:focus,
.vocabulary__mini__body .search__wrap .input__wrap .search :active {
  margin: 0 0px;
}
.vocabulary__mini__body:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 10vh;
  background-color: #ffffff9e;
  -webkit-box-shadow: 14px -20px 20px 10px #ffffff9e;
  box-shadow: 14px -20px 20px 10px #ffffff9e;
  bottom: 0px;
  left: 0px;
}
.vocabulary__mini__body__wrap {
  height: 450px;
  overflow: auto;
  overflow-x: hidden;
  padding-left: 20px;
}
.vocabulary__mini__body__wrap__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 20px;
}
.vocabulary__mini__body__wrap__item .caption {
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  color: #242e33;
}
.vocabulary__mini__body__wrap__item .item {
  font-size: 18px;
  line-height: 32px;
  font-weight: 500;
  color: #3d5157;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.vocabulary__mini__body__wrap__item .item:hover {
  text-decoration: underline;
  -webkit-transform: scale(1.05) translateX(17px);
  -ms-transform: scale(1.05) translateX(17px);
  transform: scale(1.05) translateX(17px);
}

.slider__wrap__hero__item {
  margin: 0 auto;
  display: block !important;
}

@media only screen and (max-width: 768px) {
  .container-m__hero__mobile {
    display: none;
  }
}

.services .caption {
  font-size: 32px;
  line-height: 36px;
  font-weight: 600;
  font-weight: 600;
}

.services__box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: calc(
    (((100% - -20px - (-20px * (12 - 1))) / 12) * 12) + (-20px * (12 - 1))
  );
  margin-left: -10px;
  margin-right: -10px;
}
.services__box__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  background-repeat: no-repeat;
  height: calc(45vh - 50px);
  min-height: 375px;
  width: calc(
    (((100% - 20px - (20px * (12 - 1))) / 12) * 6) + (20px * (6 - 1))
  );
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 10px;
  overflow: hidden;
  margin: 10px 10px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  position: relative;
  cursor: pointer;
  background-size: cover;
}
.services__box__item img {
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  width: 100%;
  height: 100%;
}
@media only screen and (max-width: 768px) {
  .services__box__item {
    width: calc(
      (((100% - 20px - (20px * (12 - 1))) / 12) * 12) + (20px * (12 - 1))
    );
    margin-left: 10px;
    margin-right: 10px;
    background-size: cover;
    min-height: 275px;
  }
}
@media only screen and (max-width: 768px) and (max-width: 600px) {
  .services__box__item {
    min-height: 160px;
    height: 160px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}
.services__box__item:after {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  background: rgba(36, 46, 51, 0.3);
  z-index: 0;
}
.services__box__item:hover {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.services__box__item:hover > img {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: scale(1.02);
  -ms-transform: scale(1.02);
  transform: scale(1.02);
}
.services__box__item:hover:after {
  background: rgba(36, 46, 51, 0.4);
}
.services__box__item .caption {
  color: white;
  margin-bottom: 50px;
  margin-left: 40px;
  z-index: 1;
}
@media only screen and (max-width: 600px) {
  .services__box__item .caption {
    margin: 0 auto;
    margin-bottom: 0px;
    text-align: center;
    font-size: 24px;
  }
}
.services__box__item__info {
  display: none;
  width: 100%;
  height: 100%;
  background-color: #f6f6f6;
  position: absolute;
  z-index: 50;
  padding: 30px;
  overflow: hidden;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
@media only screen and (max-width: 768px) {
  .services__box__item__info {
    display: none;
  }
}
.services__box__item__info__wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-flex: 2;
  -ms-flex-positive: 2;
  flex-grow: 2;
}
.services__box__item__info__wrap .check__info {
  width: calc(50% - 20px);
  margin-left: 10px;
  margin-right: 10px;
  font-size: 18px;
  line-height: 32px;
  font-weight: 500;
  margin-bottom: 30px;
  position: relative;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  color: #3d5157;
}
.services__box__item__info__wrap .check__info:hover {
  text-decoration: underline;
}
@media only screen and (max-width: 1200px) {
  .services__box__item__info__wrap .check__info {
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
  }
}
.services__box__item__info__wrap .check__info:before {
  content: "\f00c";
  position: absolute;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 14px;
  font-weight: 100;
  top: 10px;
  left: -20px;
  color: #87ce55;
}
.services__box__item__info .button {
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  width: calc((((100% - 0px - (0px * (12 - 1))) / 12) * 6) + (0px * (6 - 1)));
  margin-left: 0px;
  margin-right: 0px;
  min-width: 200px;
  margin: 0 0 0 auto;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.company_services .services__box {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
@media only screen and (max-width: 1100px) {
  .company_services .services__box {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
.company_services .services__box .services__box__item {
  min-height: 120px;
  height: calc(35vh - 50px);
  background-size: cover;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}
@media only screen and (max-width: 475px) {
  .company_services .services__box .services__box__item {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}
.company_services .services__box .services__box__item:hover {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}
.company_services .services__box .services__box__item.active {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}
@media only screen and (max-width: 1100px) {
  .company_services .services__box .services__box__item {
    height: calc(25vh - 50px);
  }
}
.company_services .services__box .services__box__item__info {
  display: none;
}
.company_services .services__box .services__box__item .caption {
  margin: 0 auto;
  margin-bottom: 30px;
  padding-left: 5%;
  width: 100%;
  text-align: left;
  font-size: 24px;
}
@media only screen and (max-width: 475px) {
  .company_services .services__box .services__box__item .caption {
    width: 100%;
    padding: 0px;
    text-align: center;
    margin-bottom: 0px;
    font-size: 20px;
  }
}

@media only screen and (max-width: 1100px) {
  .services__page .notification__bar {
    display: none;
  }
}

.head-page {
  background: -webkit-gradient(
      linear,
      left bottom,
      left top,
      from(rgba(36, 46, 51, 0.5)),
      to(rgba(36, 46, 51, 0.5))
    ),
    url("../img/bg_questions.png");
  background: -o-linear-gradient(
      bottom,
      rgba(36, 46, 51, 0.5),
      rgba(36, 46, 51, 0.5)
    ),
    url("../img/bg_questions.png");
  background: linear-gradient(
      0deg,
      rgba(36, 46, 51, 0.5),
      rgba(36, 46, 51, 0.5)
    ),
    url("../img/bg_questions.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 125px 0px;
  position: relative;
}
@media only screen and (max-width: 600px) {
  .head-page {
    padding: 86px 0px;
  }
}
.head-page .container-m {
  width: 100%;
}
.head-page .container-m .caption {
  font-size: 46px;
  line-height: 52px;
  font-weight: 600;
  width: 100%;
  color: white;
}
@media only screen and (max-width: 768px) {
  .head-page .container-m .caption {
    font-size: 32px;
    line-height: 36px;
    font-weight: 600;
  }
}

.head-page.property-insurance {
  background: -webkit-gradient(
      linear,
      left bottom,
      left top,
      from(rgba(36, 46, 51, 0.5)),
      to(rgba(36, 46, 51, 0.5))
    ),
    url("../img/bg__head-2.png");
  background: -o-linear-gradient(
      bottom,
      rgba(36, 46, 51, 0.5),
      rgba(36, 46, 51, 0.5)
    ),
    url("../img/bg__head-2.png");
  background: linear-gradient(
      0deg,
      rgba(36, 46, 51, 0.5),
      rgba(36, 46, 51, 0.5)
    ),
    url("../img/bg__head-2.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.head-page.press-center {
  background: -webkit-gradient(
      linear,
      left bottom,
      left top,
      from(rgba(36, 46, 51, 0.5)),
      to(rgba(36, 46, 51, 0.5))
    ),
    url("../img/press-center.png");
  background: -o-linear-gradient(
      bottom,
      rgba(36, 46, 51, 0.5),
      rgba(36, 46, 51, 0.5)
    ),
    url("../img/press-center.png");
  background: linear-gradient(
      0deg,
      rgba(36, 46, 51, 0.5),
      rgba(36, 46, 51, 0.5)
    ),
    url("../img/press-center.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.breadcrums {
  margin: 20px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.breadcrums a {
  font-size: 14px;
  line-height: 21px;
  margin-right: 20px;
  position: relative;
  color: #282828;
  cursor: pointer;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  white-space: nowrap;
}
@media only screen and (max-width: 600px) {
  .breadcrums a {
    white-space: pre-line;
  }
}
.breadcrums a:after {
  content: "·";
  position: absolute;
  right: -13px;
  font-size: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.breadcrums a:last-child {
  opacity: 0.4;
}
.breadcrums a:last-child:after {
  display: none;
}
.breadcrums a:hover {
  opacity: 1;
}

.insurance .insurance__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.insurance .insurance__top .caption {
  font-size: 32px;
  line-height: 36px;
  font-weight: 600;
  font-weight: 600;
}
.insurance .insurance__top .slick_button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.insurance .insurance__top .slick_button i {
  font-size: 26px;
  cursor: pointer;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  color: #9ea8ab;
}
.insurance .insurance__top .slick_button i:hover {
  color: black;
}

.insurance .insurance-products {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.insurance .insurance-products .opacity {
  opacity: 0.4;
}
.insurance .insurance-products__item {
  background-color: #f5f6f7;
  border-radius: 10px;
  padding: 40px;
  width: calc(400px - 55px) !important;
  margin-right: 20px !important;
  min-height: calc(495px - 30px);
}
@media only screen and (max-width: 475px) {
  .insurance .insurance-products__item {
    width: 100% !important;
  }
}
.insurance .insurance-products__item__icon {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin-bottom: 15px;
}
.insurance .insurance-products__item__icon .icon {
  width: 48px;
  height: 48px;
}
.insurance .insurance-products__item a.caption {
  margin-bottom: 60px;
  height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #282828;
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
}
.insurance .insurance-products__item a.caption:hover {
  text-decoration: underline;
}
.insurance .insurance-products__item__desc {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-bottom: 15px;
}
.insurance .insurance-products__item__desc svg {
  margin-top: 5px;
  margin-right: 25px;
  min-width: 15px;
}
.insurance .insurance-products__item__desc a {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: black;
  cursor: pointer;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.insurance .insurance-products__item__desc a:hover {
  text-decoration: underline;
}

.why-we {
  margin-top: 50px;
  padding: 50px 112px;
  background-color: #bff1bb;
}
@media only screen and (max-width: 1316px) {
  .why-we {
    padding: 30px;
  }
}
@media only screen and (max-width: 1316px) and (max-width: 860px) {
  .why-we {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
@media only screen and (max-width: 1316px) and (max-width: 860px) and (max-width: 768px) {
  .why-we {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}
.why-we__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}
@media only screen and (max-width: 1210px) {
  .why-we__top {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
}
@media only screen and (max-width: 1210px) and (max-width: 860px) {
  .why-we__top {
    width: calc((((100% - 0px - (0px * (12 - 1))) / 12) * 6) + (0px * (6 - 1)));
    margin-left: 0px;
    margin-right: 0px;
  }
}
@media only screen and (max-width: 1210px) and (max-width: 860px) and (max-width: 768px) {
  .why-we__top {
    width: calc((((100% - 0px - (0px * (12 - 1))) / 12) * 8) + (0px * (8 - 1)));
    margin-left: 0px;
    margin-right: 0px;
  }
}
@media only screen and (max-width: 1210px) and (max-width: 860px) and (max-width: 768px) and (max-width: 568px) {
  .why-we__top {
    width: calc((((100% - 0px - (0px * (12 - 1))) / 12) * 9) + (0px * (9 - 1)));
    margin-left: 0px;
    margin-right: 0px;
  }
}
@media only screen and (max-width: 1210px) and (max-width: 860px) and (max-width: 768px) and (max-width: 568px) and (max-width: 468px) {
  .why-we__top {
    width: calc(
      (((100% - 0px - (0px * (12 - 1))) / 12) * 12) + (0px * (12 - 1))
    );
    margin-left: 0px;
    margin-right: 0px;
  }
}
.why-we__top__item {
  width: calc((((100% - 0px - (0px * (12 - 1))) / 12) * 3) + (0px * (3 - 1)));
  margin-left: 0px;
  margin-right: 0px;
  margin-right: 20px;
  font-weight: 600;
}
@media only screen and (max-width: 1210px) {
  .why-we__top__item {
    width: calc((((100% - 0px - (0px * (12 - 1))) / 12) * 4) + (0px * (4 - 1)));
    margin-left: 0px;
    margin-right: 0px;
  }
}
@media only screen and (max-width: 1210px) and (max-width: 860px) {
  .why-we__top__item {
    width: calc(
      (((100% - 0px - (0px * (12 - 1))) / 12) * 12) + (0px * (12 - 1))
    );
    margin-left: 0px;
    margin-right: 0px;
    margin-right: 10px;
  }
}
@media only screen and (max-width: 1210px) and (max-width: 860px) and (max-width: 768px) {
  .why-we__top__item {
    margin-right: 0px;
  }
}
.why-we__top__item h3.caption {
  margin-top: 0px;
  margin-bottom: 80px;
  font-size: 32px;
  line-height: 36px;
  font-weight: 600;
}
@media only screen and (max-width: 860px) {
  .why-we__top__item h3.caption {
    margin-bottom: 33px;
  }
}
.why-we__top__item .item {
  margin-bottom: 75px;
}
@media only screen and (max-width: 860px) {
  .why-we__top__item .item {
    margin-bottom: 50px;
  }
}
.why-we__top__item .item .caption {
  margin-top: 0px;
}
.why-we__top__item .item:last-child {
  margin-bottom: 0px;
  margin-top: 75px;
}
@media only screen and (max-width: 860px) {
  .why-we__top__item .item:last-child {
    margin-top: 0px;
    margin-bottom: 50px;
  }
}
@media only screen and (max-width: 860px) and (max-width: 768px) {
  .why-we__top__item .item:last-child {
    margin-bottom: 0px;
  }
}
.why-we__top__item .item__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media only screen and (max-width: 860px) {
  .why-we__top__item .item__head {
    min-height: 60px;
  }
}
.why-we__top__item .item__head .number {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  min-width: 40px;
  background-color: #519025;
  border-radius: 50%;
  color: white;
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  font-weight: 600;
}
.why-we__top__item .item__head .caption {
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  font-weight: 600;
  color: black;
  margin-left: 20px;
}
.why-we__top__item .item .desc {
  margin-top: 25px;
  font-size: 18px;
  line-height: 32px;
  font-weight: 500;
  font-weight: 500;
  line-height: 26px;
  color: #242e33;
}
.why-we__top__img {
  width: calc(
    (((100% - 20px - (20px * (12 - 1))) / 12) * 9) + (20px * (9 - 1))
  );
  margin-left: 10px;
  margin-right: 10px;
  margin-right: 0px;
  -o-object-fit: cover;
  object-fit: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
@media only screen and (max-width: 1210px) {
  .why-we__top__img {
    width: calc(
      (((100% - 20px - (20px * (12 - 1))) / 12) * 8) + (20px * (8 - 1))
    );
    margin-left: 10px;
    margin-right: 10px;
  }
}
@media only screen and (max-width: 1210px) and (max-width: 860px) {
  .why-we__top__img {
    display: none;
  }
}
.why-we__top__img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
@media only screen and (max-width: 1210px) {
  .why-we__top__img img {
    width: 100%;
  }
}
.why-we__down {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  font-weight: 600;
}
@media only screen and (max-width: 860px) {
  .why-we__down {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: calc((((100% - 0px - (0px * (12 - 1))) / 12) * 6) + (0px * (6 - 1)));
    margin-left: 0px;
    margin-right: 0px;
  }
}
@media only screen and (max-width: 860px) and (max-width: 860px) {
  .why-we__down {
    width: calc((((100% - 0px - (0px * (12 - 1))) / 12) * 6) + (0px * (6 - 1)));
    margin-left: 0px;
    margin-right: 0px;
  }
}
@media only screen and (max-width: 860px) and (max-width: 860px) and (max-width: 768px) {
  .why-we__down {
    width: calc((((100% - 0px - (0px * (12 - 1))) / 12) * 8) + (0px * (8 - 1)));
    margin-left: 0px;
    margin-right: 0px;
  }
}
@media only screen and (max-width: 860px) and (max-width: 860px) and (max-width: 768px) and (max-width: 568px) {
  .why-we__down {
    width: calc((((100% - 0px - (0px * (12 - 1))) / 12) * 9) + (0px * (9 - 1)));
    margin-left: 0px;
    margin-right: 0px;
  }
}
@media only screen and (max-width: 860px) and (max-width: 860px) and (max-width: 768px) and (max-width: 568px) and (max-width: 468px) {
  .why-we__down {
    width: calc(
      (((100% - 0px - (0px * (12 - 1))) / 12) * 12) + (0px * (12 - 1))
    );
    margin-left: 0px;
    margin-right: 0px;
  }
}
.why-we__down h3.caption {
  margin-bottom: 80px;
  font-size: 32px;
  line-height: 36px;
  font-weight: 600;
}
.why-we__down .item {
  width: calc(
    (((100% - 10px - (10px * (12 - 1))) / 12) * 4) + (10px * (4 - 1))
  );
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 50px;
}
.why-we__down .item:first-child {
  margin-left: 0px;
}
@media only screen and (max-width: 1210px) {
  .why-we__down .item {
    width: calc(
      (((100% - 10px - (10px * (12 - 1))) / 12) * 4) + (10px * (4 - 1))
    );
    margin-left: 5px;
    margin-right: 5px;
  }
}
@media only screen and (max-width: 1210px) and (max-width: 860px) {
  .why-we__down .item {
    width: calc(
      (((100% - 0px - (0px * (12 - 1))) / 12) * 12) + (0px * (12 - 1))
    );
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    margin-bottom: 50px;
  }
  .why-we__down .item:first-child {
    margin-top: 60px;
  }
}
.why-we__down .item__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.why-we__down .item__head .number {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  min-width: 40px;
  background-color: #519025;
  border-radius: 50%;
  color: white;
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  font-weight: 600;
}
.why-we__down .item__head .caption {
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  font-weight: 600;
  color: black;
  margin-left: 20px;
}
.why-we__down .item .desc {
  margin-top: 25px;
  font-size: 18px;
  line-height: 32px;
  font-weight: 500;
  font-weight: 500;
  line-height: 26px;
  color: #242e33;
}

.clients__slide {
  margin-top: 50px;
  margin-bottom: 50px;
}
.clients__slide .caption {
  font-size: 32px;
  line-height: 36px;
  font-weight: 600;
}
.clients__slide .clients {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.clients__slide .clients__item {
  margin: 0 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
  max-width: 129px;
  max-height: 90px;
  height: 93px;
}
.clients__slide .clients__item img {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}
.clients__slide .clients__item img:hover {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
  opacity: 1;
}

.reviews__top {
  margin-bottom: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.reviews__top .caption {
  font-size: 32px;
  line-height: 36px;
  font-weight: 600;
}
.reviews__top .slick_button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.reviews__top .slick_button i {
  font-size: 26px;
  cursor: pointer;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  color: #9ea8ab;
}
.reviews__top .slick_button i:hover {
  color: black;
}

.reviews__slide {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
@media only screen and (max-width: 768px) {
  .reviews__slide {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
.reviews__slide__avatar img {
  height: 120px;
  width: 120px;
  min-width: 120px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%;
  overflow: hidden;
}
@media only screen and (max-width: 768px) {
  .reviews__slide__avatar img {
    height: 80px;
    width: 80px;
    min-width: 80px;
  }
}
@media only screen and (max-width: 768px) {
  .reviews__slide__avatar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}
.reviews__slide__avatar .job {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
@media only screen and (max-width: 768px) {
  .reviews__slide__avatar .job {
    display: none;
  }
}
.reviews__slide__avatar .job .name {
  font-weight: 600;
  line-height: 30px;
  font-size: 24px;
}
.reviews__slide__avatar .job .work {
  font-weight: 600;
  line-height: 30px;
  font-size: 18px;
}
.reviews__slide__avatar .job.job__mobile {
  display: none;
}
@media only screen and (max-width: 768px) {
  .reviews__slide__avatar .job.job__mobile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-left: 20px;
  }
}
.reviews__slide__desc {
  margin-left: 30px;
}
@media only screen and (max-width: 768px) {
  .reviews__slide__desc {
    margin-left: 0px;
    margin-top: 23px;
  }
}
.reviews__slide__desc .desc {
  margin-bottom: 20px;
}
.reviews__slide__desc .desc p {
  font-size: 18px;
  line-height: 32px;
  font-weight: 500;
  font-style: normal;
  font-weight: normal;
  line-height: 26px;
}
.reviews__slide__desc .job {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
@media only screen and (max-width: 768px) {
  .reviews__slide__desc .job {
    display: none;
  }
}
.reviews__slide__desc .job .name {
  font-weight: 600;
  line-height: 30px;
  font-size: 24px;
}
.reviews__slide__desc .job .work {
  font-weight: 600;
  line-height: 30px;
  font-size: 18px;
}

.reviews__slide__wrap .slick-dots {
  background-color: transparent;
  width: 150px;
  right: 0;
  bottom: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media only screen and (max-width: 768px) {
  .reviews__slide__wrap .slick-dots {
    width: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    bottom: -20px;
  }
}
.reviews__slide__wrap .slick-dots li {
  background-color: #eceeee;
  border-radius: 50%;
  overflow: hidden;
  width: 10px;
  height: 10px;
}
.reviews__slide__wrap .slick-dots li button {
  background-color: #eceeee;
}
.reviews__slide__wrap .slick-dots li button:before {
  background: transparent;
  color: transparent;
}
.reviews__slide__wrap .slick-dots li.slick-active button {
  background-color: #eceeee;
}
.reviews__slide__wrap .slick-dots li.slick-active button:before {
  background: green;
  color: transparent;
}

.clients-caption__top {
  margin-bottom: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.clients-caption__top .caption {
  font-size: 32px;
  line-height: 36px;
  font-weight: 600;
}
.clients-caption__top .slick_button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.clients-caption__top .slick_button i {
  font-size: 26px;
  cursor: pointer;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  color: #9ea8ab;
}
.clients-caption__top .slick_button i:hover {
  color: black;
}

.about__text {
  margin-top: 80px;
  padding-bottom: 50px;
}
.about__text .text__block {
  margin-bottom: 40px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.about__text .text__block p {
  font-style: normal;
  font-weight: normal;
  line-height: 26px;
  font-size: 16px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  color: #919191;
}
.about__text .text__block.active p.other {
  opacity: 1;
  max-height: 1000px;
  overflow: visible;
}
.about__text a.button {
  margin: 0 auto;
  color: #3d5157;
}
.about__text a.button:hover {
  color: white;
}

.questions {
  background: -webkit-gradient(
      linear,
      left bottom,
      left top,
      from(rgba(36, 46, 51, 0.8)),
      to(rgba(36, 46, 51, 0.8))
    ),
    url("../img/bg_questions.png");
  background: -o-linear-gradient(
      bottom,
      rgba(36, 46, 51, 0.8),
      rgba(36, 46, 51, 0.8)
    ),
    url("../img/bg_questions.png");
  background: linear-gradient(
      0deg,
      rgba(36, 46, 51, 0.8),
      rgba(36, 46, 51, 0.8)
    ),
    url("../img/bg_questions.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 50px;
  margin-top: 100px;
}
@media only screen and (max-width: 568px) {
  .questions {
    padding: 50px 20px;
  }
}
.questions .caption {
  max-width: 450px;
  text-align: center;
  font-size: 32px;
  line-height: 36px;
  font-weight: 600;
  color: white;
  margin-bottom: 30px;
}
.questions .desc {
  font-size: 18px;
  line-height: 32px;
  font-weight: 500;
  margin-bottom: 30px;
  max-width: 500px;
  text-align: center;
  color: white;
}
.questions .button {
  padding: 15px 30px;
}
@media only screen and (max-width: 568px) {
  .questions .button {
    width: 100%;
  }
}
.questions .subscription {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media only screen and (max-width: 568px) {
  .questions .subscription {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}
.questions .subscription input {
  padding: 15px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-weight: 400;
  color: #3d5157;
  background: white;
  border: 1px solid #eceeee;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 3px;
  min-width: 320px;
  margin-right: 10px;
}
@media only screen and (max-width: 568px) {
  .questions .subscription input {
    margin-bottom: 20px;
    margin-right: 0px;
    min-width: 280px;
  }
}

/* .check_in {
  padding-top: 50px;
  min-height: calc(100vh - 30px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start; }
  @media only screen and (max-width: 475px) {
    .check_in {
      padding: 15px; } }
  .check_in__logo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-bottom: 50px; }
    @media only screen and (max-width: 475px) {
      .check_in__logo {
        height: 50px;
        margin-bottom: 5px; } }
    .check_in__logo img {
      height: 100%; }
  .check_in .caption {
    font-size: 46px;
    line-height: 52px;
    font-weight: 600;
    font-weight: bold;
    color: #3D5157;
    text-align: center;
    margin-bottom: 50px; }
    @media only screen and (max-width: 600px) {
      .check_in .caption {
        font-size: 36px; } }
  @media only screen and (max-width: 600px) and (max-width: 475px) {
    .check_in .caption {
      margin-bottom: 20px; } }
  .check_in .desc {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    font-weight: 400;
    color: #3D5157;
    text-align: center;
    max-width: 500px;
    margin: 0 auto; }
  .check_in__form {
    position: relative;
    width: 100%;
    max-width: 430px;
    margin: 0 auto;
    margin-top: 50px; }
    @media only screen and (max-width: 475px) {
      .check_in__form {
        margin-top: 20px; } }
    .check_in__form__row {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      margin-bottom: 32px; }
      .check_in__form__row label {
        color: #3D5157;
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        font-weight: 400; }
      .check_in__form__row input {
        padding: 15px;
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        font-weight: 400;
        color: #3D5157;
        background: #F5F6F7;
        border: 1px solid #C8C8C8;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        border-radius: 3px; }
    .check_in__form .button {
      width: 100%;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      margin-top: 40px;
      font-weight: 500;
      line-height: 18px;
      font-size: 14px;
      padding: 15px 0px; }
      .check_in__form .button__success {
        color: white; }
      .check_in__form .button__silver {
        margin-bottom: 20px; }
    .check_in__form .help_desc {
      color: #3D5157 !important; }
    .check_in__form .help__error {
      color: #DF3737;
      line-height: 18px;
      font-size: 14px;
      height: 0px;
      opacity: 0;
      visibility: hidden;
      -webkit-transition: 0.3s;
      -o-transition: 0.3s;
      transition: 0.3s;
      -webkit-transform: translateX(-50px);
          -ms-transform: translateX(-50px);
              transform: translateX(-50px); }
  .check_in .error .help__error {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateX(0px);
        -ms-transform: translateX(0px);
            transform: translateX(0px); }
  .check_in .error .help__block__error {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateX(0px);
        -ms-transform: translateX(0px);
            transform: translateX(0px); } */

.help__block__error {
  margin-top: 30px;
  border: 1px solid rgba(223, 55, 55, 0.5);
  border-radius: 3px;
  padding: 20px 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  width: 100%;
}
.help__block__error span {
  color: #df3737;
  width: 100%;
}
.help__block__error a {
  color: #df3737;
}

/* .link__other {
  margin-top: 20px; }
  .link__other__row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    .link__other__row span {
      font-size: 16px;
      line-height: 26px;
      color: black; }
      @media only screen and (max-width: 768px) {
        .link__other__row span {
          font-size: 12px; } }
    .link__other__row a {
      font-size: 16px;
      line-height: 26px;
      text-decoration: underline;
      color: #18A4E0;
      margin-left: 5px; }
      @media only screen and (max-width: 768px) {
        .link__other__row a {
          font-size: 12px; } } */

.content__text {
  width: calc((((100% - 0px - (0px * (12 - 1))) / 12) * 10) + (0px * (10 - 1)));
  margin-left: 0px;
  margin-right: 0px;
  margin: 0 auto;
}
@media only screen and (max-width: 768px) {
  .content__text {
    width: calc(
      (((100% - 0px - (0px * (12 - 1))) / 12) * 12) + (0px * (12 - 1))
    );
    margin-left: 0px;
    margin-right: 0px;
  }
}
.content__text .caption {
  color: #242e33;
  font-size: 32px;
  line-height: 36px;
  font-weight: 600;
  margin: 50px 0px;
}
@media only screen and (max-width: 600px) {
  .content__text .caption {
    margin-top: 0px;
    margin-bottom: 20px;
  }
}
.content__text h4 {
  font-size: 18px;
  line-height: 32px;
  font-weight: 500;
  color: #3d5157;
  margin-bottom: 24px;
  font-weight: 600;
}
.content__text ol {
  list-style-type: decimal;
  list-style-position: inside;
}
.content__text ol li {
  color: #242e33;
  margin-bottom: 25px;
}
.content__text ul {
  list-style-type: inherit;
  list-style-position: inside;
}
.content__text ul li {
  margin-bottom: 10px;
  list-style-position: outside;
  margin-left: 20px;
}
.content__text ul li.warn {
  list-style-type: none;
  margin-left: 20px;
  position: relative;
}
.content__text ul li.warn:after {
  content: "*";
  position: absolute;
  left: -18px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.content__text .block__text {
  margin-bottom: 30px;
}

.content__wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: calc((((100% - 0px - (0px * (12 - 1))) / 12) * 10) + (0px * (10 - 1)));
  margin-left: 0px;
  margin-right: 0px;
  margin: 0 auto;
}
@media only screen and (max-width: 768px) {
  .content__wrap {
    width: calc(
      (((100% - 0px - (0px * (12 - 1))) / 12) * 11) + (0px * (11 - 1))
    );
    margin-left: 0px;
    margin-right: 0px;
  }
}
@media only screen and (max-width: 768px) and (max-width: 510px) {
  .content__wrap {
    width: calc(
      (((100% - 0px - (0px * (12 - 1))) / 12) * 12) + (0px * (12 - 1))
    );
    margin-left: 0px;
    margin-right: 0px;
  }
}
.content__wrap__right {
  margin: 0px 0px 0px auto !important;
}
@media only screen and (max-width: 768px) {
  .content__wrap__right {
    margin: 0 auto !important;
  }
}
.content__wrap__right .content__text {
  margin-right: 0px;
  margin-left: 0px;
}

.notification__bar {
  background-color: #bff1bb;
  padding: 50px 30px;
  max-width: 400px;
  min-width: 330px;
  height: auto;
  width: calc((((100% - 0px - (0px * (12 - 1))) / 12) * 5) + (0px * (5 - 1)));
  margin-left: 0px;
  margin-right: 0px;
  float: right;
  margin-right: -20%;
  margin-left: 10px;
}
.notification__bar .notification__bar__caption {
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 30px;
}
.notification__bar__item {
  background-color: #f5f6f7;
  border-radius: 10px;
  padding: 20px 15px;
  margin: 20px 0px;
}
.notification__bar__item__caption {
  font-size: 18px;
  line-height: 32px;
  font-weight: 500;
}
.notification__bar__item__footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 30px;
}
.notification__bar__item__footer .date {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 16px;
}
@media only screen and (max-width: 480px) {
  .notification__bar__item__footer .date {
    font-size: 14px;
    line-height: normal;
  }
}
.notification__bar__item__footer .date svg {
  margin-right: 10px;
}
.notification__bar__item__footer .view {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 16px;
  line-height: normal;
}
@media only screen and (max-width: 480px) {
  .notification__bar__item__footer .view {
    font-size: 14px;
  }
}
.notification__bar__item__footer .view svg {
  margin-right: 10px;
}

.notification__nav__tabs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: calc((((100% - 0px - (0px * (12 - 1))) / 12) * 10) + (0px * (10 - 1)));
  margin-left: 0px;
  margin-right: 0px;
  margin: 0 auto;
}
@media only screen and (max-width: 960px) {
  .notification__nav__tabs {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
@media only screen and (max-width: 960px) and (max-width: 768px) {
  .notification__nav__tabs {
    width: calc(
      (((100% - 0px - (0px * (12 - 1))) / 12) * 12) + (0px * (12 - 1))
    );
    margin-left: 0px;
    margin-right: 0px;
  }
}
@media only screen and (max-width: 960px) and (max-width: 768px) and (max-width: 600px) {
  .notification__nav__tabs {
    display: none;
  }
}
.notification__nav__tabs .tabs {
  background: #f5f6f7;
  border-radius: 10px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  color: #242e33;
  cursor: pointer;
  padding: 20px;
  width: calc(
    (((100% - 20px - (20px * (12 - 1))) / 12) * 3) + (20px * (3 - 1))
  );
  margin-left: 10px;
  margin-right: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
@media only screen and (max-width: 960px) {
  .notification__nav__tabs .tabs {
    width: calc(
      (((100% - 20px - (20px * (12 - 1))) / 12) * 6) + (20px * (6 - 1))
    );
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 960px) and (max-width: 768px) {
  .notification__nav__tabs .tabs {
    position: relative;
    width: calc(
      (((100% - 20px - (20px * (12 - 1))) / 12) * 12) + (20px * (12 - 1))
    );
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 50px;
    min-height: 92px;
    max-height: 92px;
    -webkit-box-shadow: 0px 1px 4px rgba(115, 131, 30, 0.34);
    box-shadow: 0px 1px 4px rgba(115, 131, 30, 0.34);
  }
}
.notification__nav__tabs .tabs:hover {
  background-color: #519025;
  color: white;
}
.notification__nav__tabs .tabs.active {
  background-color: #519025;
  color: white;
}
.notification__nav__tabs .tabs.active::before {
  background: url("../img/arrow-right__white.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.property__content h3 {
  font-weight: 600;
  line-height: 30px;
  font-size: 24px;
  color: #3d5157;
  margin-bottom: 24px;
}

.property__content p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #242e33;
}

.property__content ul {
  margin-top: 25px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 1100px) {
  .property__content .notification__bar {
    display: none;
  }
}

.notification__nav__tabs__mobile {
  display: none;
}
@media only screen and (max-width: 600px) {
  .notification__nav__tabs__mobile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.subspecies-insurance h1.caption {
  font-style: normal;
  font-weight: bold;
  line-height: 52px;
  font-size: 46px;
}

.subspecies-insurance .content__text .block__text p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #242e33;
}

.currency {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.currency__converter {
  width: calc((((100% - 0px - (0px * (12 - 1))) / 12) * 2) + (0px * (2 - 1)));
  margin-left: 0px;
  margin-right: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
@media only screen and (max-width: 1100px) {
  .currency__converter {
    width: calc((((100% - 0px - (0px * (12 - 1))) / 12) * 3) + (0px * (3 - 1)));
    margin-left: 0px;
    margin-right: 0px;
  }
}
@media only screen and (max-width: 1100px) and (max-width: 810px) {
  .currency__converter {
    display: none;
  }
}
.currency__converter .course__caption {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: black;
}
.currency__converter .course__price span {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #242e33;
  font-weight: 600;
  line-height: 26px;
}

.custom__select {
  display: none;
}

.currency__converter__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.currency__converter__item .select-box {
  position: relative;
}
.currency__converter__item .trigger {
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  color: #242e33;
  cursor: pointer;
}
.currency__converter__item .trigger:after {
  content: "\e829";
  position: absolute;
  font-family: "unicons";
  font-style: normal;
  font-weight: normal;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.currency__converter__item .trigger.open:after {
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.currency__converter__item .choices {
  display: none;
  z-index: 10;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: absolute;
  padding: 20px 0px;
  background-color: #519025;
  border-radius: 10px;
  cursor: pointer;
  top: 40px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.currency__converter__item .choices:after {
  content: "";
  width: 10px;
  position: absolute;
  height: 10px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  top: -20px;
  border: 10px solid transparent;
  border-bottom: 10px solid #519025;
  z-index: 10;
}
.currency__converter__item .choices a {
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  width: 100%;
  color: white;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  padding: 0 20px;
  cursor: pointer;
}
.currency__converter__item .choices a:hover {
  background-color: #6ea945;
}

.tabs-bar__wrap {
  margin-top: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.tabs-bar__wrap__button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.tabs-bar__wrap__button button.arrow {
  font-size: 24px;
  width: 40px;
  min-width: 40px;
  height: 40px;
  min-height: 40px;
  overflow: hidden;
  border-radius: 50%;
  background-color: #f5f6f7;
  color: #919191;
  cursor: pointer;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.tabs-bar__wrap__button button.arrow:hover {
  color: #519025;
}
.tabs-bar__wrap__button .item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 40px;
  min-width: 40px;
  height: 40px;
  min-height: 40px;
  overflow: hidden;
  border-radius: 50%;
  background-color: #f5f6f7;
  color: #919191;
  cursor: pointer;
  margin: 0px 5px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  font-weight: 600;
  line-height: 26px;
  font-size: 18px;
  text-align: center;
}
.tabs-bar__wrap__button .item.active {
  color: white;
  background-color: #519025;
}
.tabs-bar__wrap__button .item:hover {
  color: white;
  background-color: #519025;
}
.tabs-bar__wrap__button button.arrow__right {
  margin-left: 50px;
}
@media only screen and (max-width: 510px) {
  .tabs-bar__wrap__button button.arrow__right {
    margin-left: 30px;
  }
}
.tabs-bar__wrap__button button.arrow__left {
  margin-right: 50px;
}
@media only screen and (max-width: 510px) {
  .tabs-bar__wrap__button button.arrow__left {
    margin-right: 30px;
  }
}

.filter {
  margin-top: 20px;
  margin-bottom: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
@media only screen and (max-width: 768px) {
  .filter {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
@media only screen and (max-width: 768px) and (max-width: 600px) {
  .filter {
    margin-bottom: 0px;
  }
}
.filter__left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media only screen and (max-width: 1110px) {
  .filter__left {
    display: none;
  }
}
.filter__left .filter__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.filter__left .filter__item .item {
  border: 1px solid #c8c8c8;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 100px;
  padding: 10px 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  margin-left: 15px;
  font-size: 14px;
  line-height: 21px;
  color: #3d5157;
}
.filter__left .filter__item .item.active {
  background-color: #9ea8ab;
  color: white;
}
.filter__left .filter__item .item:hover {
  background-color: #9ea8ab;
  color: white;
}
.filter__right {
  width: calc((((100% - 0px - (0px * (12 - 1))) / 12) * 5) + (0px * (5 - 1)));
  margin-left: 0px;
  margin-right: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
@media only screen and (max-width: 1110px) {
  .filter__right {
    width: calc((((100% - 0px - (0px * (12 - 1))) / 12) * 6) + (0px * (6 - 1)));
    margin-left: 0px;
    margin-right: 0px;
  }
}
@media only screen and (max-width: 1110px) and (max-width: 810px) {
  .filter__right {
    width: calc((((100% - 0px - (0px * (12 - 1))) / 12) * 5) + (0px * (5 - 1)));
    margin-left: 0px;
    margin-right: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
}
@media only screen and (max-width: 1110px) and (max-width: 810px) and (max-width: 768px) {
  .filter__right {
    width: calc(
      (((100% - 0px - (0px * (12 - 1))) / 12) * 12) + (0px * (12 - 1))
    );
    margin-left: 0px;
    margin-right: 0px;
  }
}
@media only screen and (max-width: 1110px) and (max-width: 810px) and (max-width: 768px) and (max-width: 600px) {
  .filter__right {
    display: none;
  }
}
.filter__right .filter__item {
  width: 250px;
  position: relative;
  margin-left: 10px;
}
@media only screen and (max-width: 810px) {
  .filter__right .filter__item {
    margin-left: 0px;
    margin-top: 10px;
  }
}
@media only screen and (max-width: 810px) and (max-width: 768px) {
  .filter__right .filter__item {
    width: 100%;
  }
}
.filter__right .filter__item__select {
  min-width: 250px;
  border: 1px solid #c8c8c8;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 100px;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 14px;
  line-height: 21px;
  color: #3d5157;
  padding: 10px 30px;
}
.filter__right .filter__item__select i {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.filter__right .filter__item__select.open i {
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.filter__right .filter__item__list {
  max-height: 0px;
  visibility: hidden;
  opacity: 0;
  width: 100%;
  overflow: hidden;
  position: absolute;
  top: 50px;
  padding: 0px 0px;
  background: #ffffff;
  -webkit-box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: translateY(-50px);
  -ms-transform: translateY(-50px);
  transform: translateY(-50px);
}
.filter__right .filter__item__list.open {
  padding: 10px 0px;
  max-height: 300px;
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
  z-index: 10;
}
.filter__right .filter__item__list .item {
  font-size: 14px;
  line-height: 21px;
  padding-left: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #3d5157;
  cursor: pointer;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.filter__right .filter__item__list .item:hover {
  background-color: #9ea8ab;
  color: white;
}
.filter .filter__left__mobile {
  display: none;
}
@media only screen and (max-width: 1110px) {
  .filter .filter__left__mobile {
    width: calc((((100% - 0px - (0px * (12 - 1))) / 12) * 6) + (0px * (6 - 1)));
    margin-left: 0px;
    margin-right: 0px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
}
@media only screen and (max-width: 1110px) and (max-width: 810px) {
  .filter .filter__left__mobile {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
}
@media only screen and (max-width: 1110px) and (max-width: 810px) and (max-width: 768px) {
  .filter .filter__left__mobile {
    width: calc(
      (((100% - 0px - (0px * (12 - 1))) / 12) * 12) + (0px * (12 - 1))
    );
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 1110px) {
  .filter .filter__left__mobile .filter__item {
    width: 250px;
    position: relative;
    margin-left: 10px;
  }
}
@media only screen and (max-width: 1110px) and (max-width: 810px) {
  .filter .filter__left__mobile .filter__item {
    margin-left: 0px;
    margin-top: 10px;
    width: 100%;
  }
}
@media only screen and (max-width: 1110px) {
  .filter .filter__left__mobile .filter__item__select {
    min-width: 250px;
    border: 1px solid #c8c8c8;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 100px;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    font-size: 14px;
    line-height: 21px;
    color: #3d5157;
    padding: 10px 30px;
  }
  .filter .filter__left__mobile .filter__item__select i {
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
  }
  .filter .filter__left__mobile .filter__item__select.open i {
    -webkit-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  .filter .filter__left__mobile .filter__item__list {
    max-height: 0px;
    visibility: hidden;
    opacity: 0;
    width: 100%;
    overflow: hidden;
    position: absolute;
    top: 50px;
    padding: 0px 0px;
    background: #ffffff;
    -webkit-box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    -webkit-transform: translateY(-50px);
    -ms-transform: translateY(-50px);
    transform: translateY(-50px);
  }
  .filter .filter__left__mobile .filter__item__list.open {
    padding: 10px 0px;
    max-height: 300px;
    visibility: visible;
    opacity: 1;
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
    z-index: 10;
  }
  .filter .filter__left__mobile .filter__item__list .item {
    font-size: 14px;
    line-height: 21px;
    padding-left: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #3d5157;
    cursor: pointer;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .filter .filter__left__mobile .filter__item__list .item:hover {
    background-color: #9ea8ab;
    color: white;
  }
}

.article__preview {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
}
@media only screen and (max-width: 810px) {
  .article__preview {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
.article__preview__img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: calc((((100% - 0px - (0px * (12 - 1))) / 12) * 5) + (0px * (5 - 1)));
  margin-left: 0px;
  margin-right: 0px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 10px 0px 0px 10px;
  overflow: hidden;
}
@media only screen and (max-width: 1110px) {
  .article__preview__img {
    width: calc((((100% - 0px - (0px * (12 - 1))) / 12) * 4) + (0px * (4 - 1)));
    margin-left: 0px;
    margin-right: 0px;
  }
}
@media only screen and (max-width: 1110px) and (max-width: 810px) {
  .article__preview__img {
    width: calc(
      (((100% - 0px - (0px * (12 - 1))) / 12) * 12) + (0px * (12 - 1))
    );
    margin-left: 0px;
    margin-right: 0px;
    height: 300px;
    max-height: 300px;
    border-radius: 10px 10px 0px 0px;
  }
}
@media only screen and (max-width: 1110px) and (max-width: 810px) and (max-width: 510px) {
  .article__preview__img {
    max-height: 200px;
    min-height: 200px;
    height: 200px;
  }
}
.article__preview__img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.article__preview__wrap {
  width: calc((((100% - 0px - (0px * (12 - 1))) / 12) * 7) + (0px * (7 - 1)));
  margin-left: 0px;
  margin-right: 0px;
  padding: 20px;
  background-color: #f5f6f7;
  border-radius: 0px 10px 10px 0px;
}
@media only screen and (max-width: 1110px) {
  .article__preview__wrap {
    width: calc((((100% - 0px - (0px * (12 - 1))) / 12) * 8) + (0px * (8 - 1)));
    margin-left: 0px;
    margin-right: 0px;
  }
}
@media only screen and (max-width: 1110px) and (max-width: 910px) {
  .article__preview__wrap {
    width: calc(
      (((100% - 0px - (0px * (12 - 1))) / 12) * 10) + (0px * (10 - 1))
    );
    margin-left: 0px;
    margin-right: 0px;
  }
}
@media only screen and (max-width: 1110px) and (max-width: 810px) {
  .article__preview__wrap {
    width: calc(
      (((100% - 0px - (0px * (12 - 1))) / 12) * 12) + (0px * (12 - 1))
    );
    margin-left: 0px;
    margin-right: 0px;
  }
}
@media only screen and (max-width: 1110px) and (max-width: 810px) and (max-width: 680px) {
  .article__preview__wrap {
    padding: 10px;
  }
}
.article__preview__wrap__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 20px;
}
@media only screen and (max-width: 966px) {
  .article__preview__wrap__head {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
@media only screen and (max-width: 966px) and (max-width: 680px) {
  .article__preview__wrap__head {
    margin-bottom: 10px;
  }
}
.article__preview__wrap__head__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media only screen and (max-width: 475px) {
  .article__preview__wrap__head__item {
    font-size: 14px;
  }
}
@media only screen and (max-width: 960px) {
  .article__preview__wrap__head__item.date {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
}
@media only screen and (max-width: 960px) and (max-width: 810px) {
  .article__preview__wrap__head__item.date {
    -webkit-box-ordinal-group: inherit;
    -ms-flex-order: inherit;
    order: inherit;
    margin-top: 0px;
    width: auto;
  }
}
@media only screen and (max-width: 960px) and (max-width: 810px) and (max-width: 680px) {
  .article__preview__wrap__head__item.date {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
    width: 30%;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
}
@media only screen and (max-width: 960px) and (max-width: 810px) and (max-width: 680px) and (max-width: 475px) {
  .article__preview__wrap__head__item.date {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
    width: auto;
    margin-top: 10px;
  }
}
@media only screen and (max-width: 960px) {
  .article__preview__wrap__head__item.view {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
}
@media only screen and (max-width: 960px) and (max-width: 810px) {
  .article__preview__wrap__head__item.view {
    -webkit-box-ordinal-group: inherit;
    -ms-flex-order: inherit;
    order: inherit;
    margin-top: 0px;
    width: auto;
  }
}
@media only screen and (max-width: 960px) and (max-width: 810px) and (max-width: 680px) {
  .article__preview__wrap__head__item.view {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
}
@media only screen and (max-width: 960px) and (max-width: 810px) and (max-width: 680px) and (max-width: 475px) {
  .article__preview__wrap__head__item.view {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
    width: auto;
    margin-top: 10px;
  }
}
@media only screen and (max-width: 960px) {
  .article__preview__wrap__head__item.share {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
    width: 50%;
    padding: 0 30px;
    margin-top: 10px;
  }
}
@media only screen and (max-width: 960px) and (max-width: 810px) {
  .article__preview__wrap__head__item.share {
    -webkit-box-ordinal-group: inherit;
    -ms-flex-order: inherit;
    order: inherit;
    margin-top: 0px;
    width: auto;
    padding: 0px;
  }
}
@media only screen and (max-width: 960px) and (max-width: 810px) and (max-width: 680px) {
  .article__preview__wrap__head__item.share {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
    width: 50%;
    padding: 0px;
    margin-top: 10px;
  }
}
@media only screen and (max-width: 960px) and (max-width: 810px) and (max-width: 680px) and (max-width: 475px) {
  .article__preview__wrap__head__item.share {
    width: auto;
  }
}
.article__preview__wrap__head__item svg {
  margin-right: 5px;
  max-width: 18px;
  max-height: 18px;
  min-width: 18px;
}
.article__preview__wrap__head .stars {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media only screen and (max-width: 960px) {
  .article__preview__wrap__head .stars {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
    width: 50%;
    margin-top: 10px;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
}
@media only screen and (max-width: 960px) and (max-width: 810px) {
  .article__preview__wrap__head .stars {
    -webkit-box-ordinal-group: inherit;
    -ms-flex-order: inherit;
    order: inherit;
    margin-top: 0px;
    width: auto;
  }
}
@media only screen and (max-width: 960px) and (max-width: 810px) and (max-width: 680px) {
  .article__preview__wrap__head .stars {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
    width: 50%;
    margin-top: 10px;
  }
}
@media only screen and (max-width: 960px) and (max-width: 810px) and (max-width: 680px) and (max-width: 475px) {
  .article__preview__wrap__head .stars {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    margin-top: 0px;
    width: 60%;
  }
}
.article__preview__wrap__head .stars__out {
  position: relative;
  margin-right: 5px;
  color: orange;
  z-index: 1;
}
.article__preview__wrap__head .stars__in {
  position: absolute;
  z-index: 2;
  color: orange;
  font-size: inherit;
  display: block;
  top: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  width: 0;
}
@media only screen and (max-width: 960px) {
  .article__preview__wrap__head .button__gray {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
}
@media only screen and (max-width: 960px) and (max-width: 810px) {
  .article__preview__wrap__head .button__gray {
    -webkit-box-ordinal-group: inherit;
    -ms-flex-order: inherit;
    order: inherit;
    margin-top: 0px;
    width: auto;
  }
}
@media only screen and (max-width: 960px) and (max-width: 810px) and (max-width: 680px) {
  .article__preview__wrap__head .button__gray {
    width: 30%;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
}
@media only screen and (max-width: 960px) and (max-width: 810px) and (max-width: 680px) and (max-width: 680px) {
  .article__preview__wrap__head .button__gray {
    padding: 0px;
    border: 0px;
    border-radius: 0px;
    color: #0f7595;
  }
  .article__preview__wrap__head .button__gray:hover {
    background-color: transparent;
    color: #1c7430;
  }
}
@media only screen and (max-width: 960px) and (max-width: 810px) and (max-width: 680px) and (max-width: 680px) and (max-width: 475px) {
  .article__preview__wrap__head .button__gray {
    width: 40%;
    text-align: left;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
}
@media only screen and (max-width: 960px) and (max-width: 810px) and (max-width: 680px) and (max-width: 680px) and (max-width: 475px) and (max-width: 375px) {
  .article__preview__wrap__head .button__gray {
    width: auto;
  }
}
.article__preview__wrap h3.caption {
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  color: #242e33;
  margin-bottom: 20px;
}
.article__preview__wrap h3.caption a {
  color: #242e33;
}
@media only screen and (max-width: 680px) {
  .article__preview__wrap h3.caption {
    margin: 10px 0px;
    font-size: 18px;
    line-height: 27px;
  }
}
.article__preview__wrap p.desc {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #242e33;
  margin-bottom: 30px;
}
@media only screen and (max-width: 680px) {
  .article__preview__wrap p.desc {
    display: none;
  }
}
.article__preview__wrap .button-l {
  margin: 0px 0px 0px auto;
}
@media only screen and (max-width: 680px) {
  .article__preview__wrap .button-l {
    margin: 0 auto;
  }
}

.press-center__wrap .date {
  margin-top: 0px;
}

.article {
  position: relative;
  margin: 0 auto;
}
.article .social__fix {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: absolute;
  left: -20%;
}
.article .social__fix .social__link {
  margin-right: 15px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
}
.article .social__fix .social__link svg {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.article .social__fix .social__link:hover > svg {
  -webkit-transform: rotate(360deg) scale(1.1);
  -ms-transform: rotate(360deg) scale(1.1);
  transform: rotate(360deg) scale(1.1);
}
.article .social__fix.fixed {
  position: fixed;
  left: 10px;
  top: 20%;
}
@media only screen and (max-width: 768px) {
  .article .social__fix.fixed {
    display: none;
  }
}
.article h1.caption {
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  color: #242e33;
  margin-bottom: 50px;
}
.article .block__text p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #242e33;
  margin-bottom: 30px;
}
/*.article__img {*/
/*  margin: 0 -100px;*/
/*  margin-bottom: 30px; }*/
.article__img img {
  /*width: 100%;*/
  height: auto;
  -o-object-fit: cover;
  object-fit: cover;
}
.article .content__text {
  width: calc((((100% - 0px - (0px * (12 - 1))) / 12) * 12) + (0px * (12 - 1)));
  margin-left: 0px;
  margin-right: 0px;
}
.article .content__text .notification__bar {
  margin-left: 20px;
}
@media only screen and (max-width: 768px) {
  .article .content__text .notification__bar {
    background-color: #bff1bb;
    float: none;
    margin: 0 auto;
    margin-bottom: 20px;
    max-width: 100%;
    width: 100%;
    min-width: 290px;
  }
}
.article .quote {
  border-left: 6px solid #c4c4c4;
  padding: 0px 30px;
  position: relative;
  margin-bottom: 50px;
}
@media only screen and (max-width: 768px) {
  .article .quote {
    padding: 0 10px;
  }
}
.article .quote svg.quote_icon {
  position: absolute;
  left: 30px;
  top: 0px;
}
.article .quote blockquote {
  padding-top: 60px;
  font-size: 18px;
  line-height: 32px;
  font-weight: 500;
  color: #242e33;
}
.article .quote .job {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.article .quote .job .name {
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  color: black;
}
.article .quote .job .work {
  font-size: 18px;
  line-height: 32px;
  font-weight: 500;
  color: black;
}
.article .quote .quote__footer {
  margin-top: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media only screen and (max-width: 768px) {
  .article .quote .quote__footer {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
}
.article .quote .quote__footer .social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media only screen and (max-width: 768px) {
  .article .quote .quote__footer .social {
    width: 100%;
    margin-top: 20px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}
.article .quote .quote__footer .social .social__link {
  margin-right: 15px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
}
.article .quote .quote__footer .social .social__link svg {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.article .quote .quote__footer .social .social__link:hover > svg {
  -webkit-transform: rotate(360deg) scale(1.1);
  -ms-transform: rotate(360deg) scale(1.1);
  transform: rotate(360deg) scale(1.1);
}
.article .source__wrap .sourse__name {
  margin-bottom: 25px;
  font-size: 18px;
  line-height: 32px;
  font-weight: 500;
  color: #242e33;
  font-weight: 600;
}
.article .source__wrap .assessment {
  font-size: 18px;
  line-height: 32px;
  font-weight: 500;
  color: #242e33;
  font-weight: 600;
  margin-bottom: 18px;
}
.article .source__wrap .stars {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.article .source__wrap .stars__out {
  position: relative;
  margin-right: 5px;
  color: orange;
  z-index: 1;
  font-size: 27px;
}
.article .source__wrap .stars__in {
  position: absolute;
  z-index: 2;
  font-size: 27px;
  color: orange;
  display: block;
  top: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  width: 0;
}

.press-center__wrap {
  margin: 0 -100px;
}
@media only screen and (max-width: 1100px) {
  .press-center__wrap {
    margin: 0 -150px;
  }
}
@media only screen and (max-width: 1100px) and (max-width: 960px) {
  .press-center__wrap {
    margin: 0 -100px;
  }
}
@media only screen and (max-width: 768px) {
  .press-center__wrap {
    margin: 0 auto;
  }
}
.press-center__wrap .caption {
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  color: black;
}
.press-center__wrap__box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
@media only screen and (max-width: 960px) {
  .press-center__wrap__box {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
@media only screen and (max-width: 768px) {
  .press-center__wrap__box .date {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
.press-center__wrap__box__item {
  width: calc(
    (((100% - 23px - (23px * (12 - 1))) / 12) * 4) + (23px * (4 - 1))
  );
  margin-left: 11.5px;
  margin-right: 11.5px;
}
@media only screen and (max-width: 960px) {
  .press-center__wrap__box__item {
    width: calc(
      (((100% - 23px - (23px * (12 - 1))) / 12) * 6) + (23px * (6 - 1))
    );
    margin-left: 11.5px;
    margin-right: 11.5px;
    margin-left: 0px;
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 960px) and (max-width: 640px) {
  .press-center__wrap__box__item {
    width: calc(
      (((100% - 0px - (0px * (12 - 1))) / 12) * 12) + (0px * (12 - 1))
    );
    margin-left: 0px;
    margin-right: 0px;
  }
}
.press-center__wrap__box__item:first-child {
  margin-left: 0px;
}
.press-center__wrap__box__item:last-child {
  margin-right: 0px;
}
.press-center__wrap__box__item__img {
  border-radius: 10px 10px 0px 0px;
  overflow: hidden;
}
.press-center__wrap__box__item__img img {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -o-object-fit: cover;
  object-fit: cover;
}
.press-center__wrap__box__item__down {
  background-color: #f5f6f7;
  padding: 20px 15px;
}
.press-center__wrap__box__item__desc {
  margin-bottom: 30px;
}
.press-center__wrap__box__item__desc p {
  font-size: 18px;
  line-height: 32px;
  font-weight: 500;
  color: #242e33;
  line-height: 26px;
  min-height: 130px;
  max-height: 130px;
  height: 130px;
  overflow: hidden;
}
@media only screen and (max-width: 760px) {
  .press-center__wrap__box__item__desc p {
    font-size: 18px;
    line-height: 20px;
    min-height: 80px;
    max-height: 80px;
    height: 80px;
    overflow: hidden;
  }
}
.press-center__wrap__box__item .button-l {
  width: 100%;
}
.press-center__wrap .all__article {
  width: calc(
    (((100% - 0px - (0px * (12 - 1))) / 12) * 3.7) + (0px * (3.7 - 1))
  );
  margin-left: 0px;
  margin-right: 0px;
  margin: 0 auto;
  min-width: 252px;
}

@media only screen and (max-width: 768px) {
  .article__page__head {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .article__page__head .article__preview__wrap__head__item {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .article__page__head .stars,
  .article__page__head .share,
  .article__page__head .date {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

@media only screen and (max-width: 768px) {
  .article__page__head .stars {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    width: calc((((100% - 0px - (0px * (12 - 1))) / 12) * 6) + (0px * (6 - 1)));
    margin-left: 0px;
    margin-right: 0px;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
}

@media only screen and (max-width: 768px) {
  .article__page__head .share {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    width: calc((((100% - 0px - (0px * (12 - 1))) / 12) * 4) + (0px * (4 - 1)));
    margin-left: 0px;
    margin-right: 0px;
  }
}

@media only screen and (max-width: 768px) {
  .article__page__head .date {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
    width: calc((((100% - 0px - (0px * (12 - 1))) / 12) * 4) + (0px * (4 - 1)));
    margin-left: 0px;
    margin-right: 0px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 768px) {
  .article__page__head .view {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
    width: calc((((100% - 0px - (0px * (12 - 1))) / 12) * 4) + (0px * (4 - 1)));
    margin-left: 0px;
    margin-right: 0px;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
}

.article__page__head {
  width: calc((((100% - 0px - (0px * (12 - 1))) / 12) * 10) + (0px * (10 - 1)));
  margin-left: 0px;
  margin-right: 0px;
}
@media only screen and (max-width: 960px) {
  .article__page__head {
    width: calc(
      (((100% - 0px - (0px * (12 - 1))) / 12) * 12) + (0px * (12 - 1))
    );
    margin-left: 0px;
    margin-right: 0px;
  }
}
.article__page__head__box {
  width: calc(
    (((100% - 0px - (0px * (12 - 1))) / 12) * 9.6) + (0px * (9.6 - 1))
  );
  margin-left: 0px;
  margin-right: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media only screen and (max-width: 800px) {
  .article__page__head__box {
    width: calc(
      (((100% - 0px - (0px * (12 - 1))) / 12) * 12) + (0px * (12 - 1))
    );
    margin-left: 0px;
    margin-right: 0px;
  }
}
@media only screen and (max-width: 800px) and (max-width: 760px) {
  .article__page__head__box {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
@media only screen and (max-width: 800px) {
  .article__page__head .btn__article__page__head {
    display: none;
  }
}
.article__page__head .btn__article__page__head-mobile {
  display: none;
}
@media only screen and (max-width: 800px) {
  .article__page__head .btn__article__page__head-mobile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
.article__page__head .article__preview__wrap__head__item.share {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.news {
  padding: 30px;
  background-color: #bff1bb;
  margin: 0 -100px;
  margin-bottom: 50px;
}
@media only screen and (max-width: 768px) {
  .news {
    margin: 0 -20px;
    margin-bottom: 20px;
  }
}
.news .caption-m {
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  color: black;
}
.news__article {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 30px;
}
@media only screen and (max-width: 768px) {
  .news__article {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
.news__article__img {
  width: calc((((100% - 0px - (0px * (12 - 1))) / 12) * 3) + (0px * (3 - 1)));
  margin-left: 0px;
  margin-right: 0px;
  overflow: hidden;
  border-radius: 10px;
}
@media only screen and (max-width: 768px) {
  .news__article__img {
    width: calc(
      (((100% - 0px - (0px * (12 - 1))) / 12) * 12) + (0px * (12 - 1))
    );
    margin-left: 0px;
    margin-right: 0px;
    height: 200px;
    max-height: 200px;
    min-height: 200px;
    margin-bottom: 10px;
  }
}
.news__article__img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.news__article__text {
  width: calc((((100% - 0px - (0px * (12 - 1))) / 12) * 9) + (0px * (9 - 1)));
  margin-left: 0px;
  margin-right: 0px;
  margin-left: 20px;
}
@media only screen and (max-width: 768px) {
  .news__article__text {
    width: calc(
      (((100% - 0px - (0px * (12 - 1))) / 12) * 12) + (0px * (12 - 1))
    );
    margin-left: 0px;
    margin-right: 0px;
  }
}
.news__article__text .caption-ml {
  font-weight: 600;
  line-height: 26px;
  font-size: 18px;
  margin-bottom: 15px;
  color: #242e33;
}
.news__article__text .desc {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #242e33;
}

.table {
  margin: 0 -100px;
  margin-bottom: 50px;
}
@media only screen and (max-width: 768px) {
  .table {
    margin: 0 -0px;
    margin-bottom: 20px;
  }
}
.table .caption {
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  color: black;
}
@media only screen and (max-width: 680px) {
  .table .caption {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 10px;
  }
}
.table__wrap {
  overflow: auto;
}
.table__wrap__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 5px;
}
@media only screen and (max-width: 915px) {
  .table__wrap__head {
    min-width: 915px;
  }
}
.table__wrap__head .table__col {
  background-color: #bff1bb;
}
.table__wrap__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 5px;
}
@media only screen and (max-width: 915px) {
  .table__wrap__row {
    min-width: 915px;
  }
}
.table__wrap__row:nth-child(2n) .table__col {
  background-color: rgba(236, 238, 238, 0.7);
}
.table__wrap__row:nth-child(2n + 1) .table__col {
  background-color: rgba(245, 246, 247, 0.5);
}
.table__col {
  border-radius: 5px;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 18px;
  line-height: 32px;
  font-weight: 500;
  line-height: 18px;
}
@media only screen and (max-width: 915px) {
  .table__col {
    padding-left: 10px;
  }
}
.table__col:first-child {
  width: calc(
    (((100% - 10px - (10px * (12 - 1))) / 12) * 1) + (10px * (1 - 1))
  );
  margin-left: 5px;
  margin-right: 5px;
  margin-left: 0px;
}
.table__col:nth-child(2) {
  width: calc(
    (((100% - 10px - (10px * (12 - 1))) / 12) * 5) + (10px * (5 - 1))
  );
  margin-left: 5px;
  margin-right: 5px;
}
.table__col:nth-child(3) {
  width: calc(
    (((100% - 10px - (10px * (12 - 1))) / 12) * 4) + (10px * (4 - 1))
  );
  margin-left: 5px;
  margin-right: 5px;
}
.table__col:nth-child(4) {
  width: calc(
    (((100% - 10px - (10px * (12 - 1))) / 12) * 2) + (10px * (2 - 1))
  );
  margin-left: 5px;
  margin-right: 5px;
  margin-right: 0px;
}

.partners__wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 0 -10px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.partners__wrap__item {
  width: calc(
    (((100% - 20px - (20px * (12 - 1))) / 12) * 3) + (20px * (3 - 1))
  );
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 30px;
}
@media only screen and (max-width: 1260px) {
  .partners__wrap__item {
    width: calc(
      (((100% - 20px - (20px * (12 - 1))) / 12) * 4) + (20px * (4 - 1))
    );
    margin-left: 10px;
    margin-right: 10px;
  }
}
@media only screen and (max-width: 1260px) and (max-width: 960px) {
  .partners__wrap__item {
    width: calc(
      (((100% - 20px - (20px * (12 - 1))) / 12) * 6) + (20px * (6 - 1))
    );
    margin-left: 10px;
    margin-right: 10px;
  }
}
@media only screen and (max-width: 1260px) and (max-width: 960px) and (max-width: 660px) {
  .partners__wrap__item {
    width: calc(
      (((100% - 20px - (20px * (12 - 1))) / 12) * 10) + (20px * (10 - 1))
    );
    margin-left: 10px;
    margin-right: 10px;
    margin: 0 auto;
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 1260px) and (max-width: 960px) and (max-width: 660px) and (max-width: 500px) {
  .partners__wrap__item {
    width: calc(
      (((100% - 20px - (20px * (12 - 1))) / 12) * 12) + (20px * (12 - 1))
    );
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 30px;
  }
}
.partners__wrap__item__img {
  border-radius: 10px;
  overflow: hidden;
  background-color: #f5f6f7;
  margin-bottom: 20px;
  height: calc(25vh - 24px);
}
.partners__wrap__item__img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.partners__wrap__item__down__desc {
  max-height: 182px;
  overflow-y: auto;
  margin-bottom: 30px;
}
.partners__wrap__item__down__desc p {
  font-size: 18px;
  line-height: 32px;
  font-weight: 500;
  line-height: 26px;
  color: black;
}
.partners__wrap__item__down .button {
  width: 100%;
  margin: 0 auto;
}

.search__wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media only screen and (max-width: 768px) {
  .search__wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
}
.search__wrap span {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #242e33;
  line-height: 24px;
}
.search__wrap .search {
  border: 1px solid #eceeee;
  background: #f5f6f7;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 50px;
  padding: 20px 30px;
  padding-right: 45px;
  width: 100%;
}
.search__wrap .search::-webkit-input-placeholder {
  line-height: 18px;
  font-size: 14px;
  color: #c8c8c8;
}
.search__wrap .search::-moz-placeholder {
  line-height: 18px;
  font-size: 14px;
  color: #c8c8c8;
}
.search__wrap .search:-moz-placeholder {
  line-height: 18px;
  font-size: 14px;
  color: #c8c8c8;
}
.search__wrap .search:-ms-input-placeholder {
  line-height: 18px;
  font-size: 14px;
  color: #c8c8c8;
}
.search__wrap .input__wrap {
  position: relative;
  width: calc(
    (((100% - 40px - (40px * (12 - 1))) / 12) * 4) + (40px * (4 - 1))
  );
  margin-left: 20px;
  margin-right: 20px;
}
@media only screen and (max-width: 768px) {
  .search__wrap .input__wrap {
    width: calc(
      (((100% - 0px - (0px * (12 - 1))) / 12) * 12) + (0px * (12 - 1))
    );
    margin-left: 0px;
    margin-right: 0px;
  }
}
.search__wrap .icon__search {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 24px;
  color: #c4c4c4;
}

.insurance__broker {
  margin-top: 50px;
}
.insurance__broker h1.caption {
  font-size: 32px;
  line-height: 36px;
  font-weight: 600;
  margin-bottom: 30px;
}
.insurance__broker .button.plus {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  margin: 0 auto;
  margin-top: 50px;
}
.insurance__broker .button.plus i {
  margin-right: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.insurance__broker .button.plus:hover > i {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.vocabulary {
  margin-top: 50px;
}
.vocabulary__wrap__item__word {
  font-size: 32px;
  line-height: 36px;
  font-weight: 600;
  color: #242e33;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  background-color: #eceeee;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 20px;
}
@media only screen and (max-width: 768px) {
  .vocabulary__wrap__item__word {
    width: 100%;
    border-radius: 0;
    margin: 0 auto;
    margin-bottom: 20px;
  }
}
.vocabulary__wrap__item__words {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
@media only screen and (max-width: 768px) {
  .vocabulary__wrap__item__words {
    text-align: center;
  }
}
.vocabulary__wrap__item__words a {
  color: #3d5157;
  font-size: 18px;
  line-height: 32px;
  font-weight: 500;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
.vocabulary__wrap__item__words a:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  text-decoration: underline;
}
.vocabulary .button__load {
  width: calc((((100% - 0px - (0px * (12 - 1))) / 12) * 3) + (0px * (3 - 1)));
  margin-left: 0px;
  margin-right: 0px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  font-size: 14px;
  line-height: 21px;
  color: #242e33;
  margin: 0 auto;
  border-color: #519025;
}
@media only screen and (max-width: 768px) {
  .vocabulary .button__load {
    width: calc(
      (((100% - 0px - (0px * (12 - 1))) / 12) * 12) + (0px * (12 - 1))
    );
    margin-left: 0px;
    margin-right: 0px;
  }
}
.vocabulary .button__load:hover {
  background-color: #519025;
  color: white;
}
.vocabulary .button__load i {
  color: #242e33;
  margin-right: 8px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.vocabulary .button__load:hover > i {
  color: white;
  -webkit-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}

.grid {
  display: -ms-grid;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(calc(25% - 10px), 1fr));
  grid-auto-rows: 20px;
}
@media only screen and (max-width: 1100px) {
  .grid {
    grid-template-columns: repeat(auto-fill, minmax(calc(30% - 10px), 1fr));
  }
}
@media only screen and (max-width: 1100px) and (max-width: 768px) {
  .grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.content__grid {
  padding-bottom: 50px;
}

@media only screen and (max-width: 768px) {
  .vocabulary__wrap__item {
    width: calc(
      (((100% - 0px - (0px * (12 - 1))) / 12) * 12) + (0px * (12 - 1))
    );
    margin-left: 0px;
    margin-right: 0px;
  }
}

.team__page .block__text {
  margin-top: 30px;
}
.team__page .block__text p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #3d5157;
}

.team__page__wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -10px;
  margin-top: 30px;
}
.team__page__wrap__item {
  width: calc(
    (((100% - 20px - (20px * (12 - 1))) / 12) * 3) + (20px * (3 - 1))
  );
  margin-left: 10px;
  margin-right: 10px;
  background-color: #eceeee;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 30px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
@media only screen and (max-width: 1100px) {
  .team__page__wrap__item {
    width: calc(
      (((100% - 20px - (20px * (12 - 1))) / 12) * 4) + (20px * (4 - 1))
    );
    margin-left: 10px;
    margin-right: 10px;
  }
}
@media only screen and (max-width: 1100px) and (max-width: 860px) {
  .team__page__wrap__item {
    width: calc(
      (((100% - 20px - (20px * (12 - 1))) / 12) * 6) + (20px * (6 - 1))
    );
    margin-left: 10px;
    margin-right: 10px;
  }
}
@media only screen and (max-width: 1100px) and (max-width: 860px) and (max-width: 560px) {
  .team__page__wrap__item {
    width: calc(
      (((100% - 20px - (20px * (12 - 1))) / 12) * 12) + (20px * (12 - 1))
    );
    margin-left: 10px;
    margin-right: 10px;
  }
}
.team__page__wrap__item:hover {
  -webkit-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  transform: translateY(-10px);
  background-color: #e3e3e3;
}
.team__page__wrap__item__avatar {
  height: calc(50vh - 48px);
}
.team__page__wrap__item__avatar img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.team__page__wrap__item__down {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 30px;
}
.team__page__wrap__item__down .name {
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  color: #242e33;
  min-height: 30px;
}
.team__page__wrap__item__down .job {
  color: #3d5157;
  font-size: 18px;
  line-height: 32px;
  font-weight: 500;
  margin-top: 20px;
  line-height: 24px;
  min-height: 24px;
}

.notification__bar__no-float {
  width: calc((((100% - 0px - (0px * (12 - 1))) / 12) * 4) + (0px * (4 - 1)));
  margin-left: 0px;
  margin-right: 0px;
  margin-left: 20px;
}
@media only screen and (max-width: 1100px) {
  .notification__bar__no-float {
    display: none;
  }
}
.notification__bar__no-float .notification__bar {
  float: none;
  width: 100%;
  max-width: 700px;
}

.about__page {
  margin-top: 30px;
}
.about__page__wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.about__page__wrap .content-m {
  margin: 0 auto 0 0;
}
@media only screen and (max-width: 1100px) {
  .about__page__wrap .content-m {
    margin: 0 auto;
  }
}
@media only screen and (max-width: 1100px) and (max-width: 960px) {
  .about__page__wrap .content-m {
    width: calc(
      (((100% - 0px - (0px * (12 - 1))) / 12) * 10) + (0px * (10 - 1))
    );
    margin-left: 0px;
    margin-right: 0px;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 1100px) and (max-width: 960px) and (max-width: 768px) {
  .about__page__wrap .content-m {
    width: calc(
      (((100% - 0px - (0px * (12 - 1))) / 12) * 12) + (0px * (12 - 1))
    );
    margin-left: 0px;
    margin-right: 0px;
    margin: 0 auto;
  }
}
.about__page__wrap__content .block__text {
  margin-bottom: 50px;
}
.about__page__wrap__content .block__text p {
  color: #3d5157;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}
.about__page__wrap__content__block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media only screen and (max-width: 668px) {
  .about__page__wrap__content__block {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
.about__page__wrap__content__block .block__wrap {
  width: calc((((100% - 0px - (0px * (12 - 1))) / 12) * 6) + (0px * (6 - 1)));
  margin-left: 0px;
  margin-right: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}
@media only screen and (max-width: 668px) {
  .about__page__wrap__content__block .block__wrap {
    width: calc(
      (((100% - 0px - (0px * (12 - 1))) / 12) * 12) + (0px * (12 - 1))
    );
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 20px;
  }
}
.about__page__wrap__content__block .block__wrap h2.caption {
  margin-bottom: 20px;
}
.about__page__wrap__content__block .block__wrap .desc {
  color: #3d5157;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}
.about__page__wrap__content__acordion {
  margin-top: 50px;
}
.about__page__wrap__content__acordion__block {
  padding-bottom: 50px;
  margin-top: 50px;
  border-bottom: 1px solid #9ea8ab;
}
.about__page__wrap__content__acordion__block:last-child {
  border-bottom: 1px solid transparent;
}
.about__page__wrap__content__acordion__block .block__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #242e33;
}
.about__page__wrap__content__acordion__block .block__head .button {
  padding: 10px 30px;
}
.about__page__wrap__content__acordion__block .block__head .button i {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  color: #242e33;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.about__page__wrap__content__acordion__block .block__head .button.active i {
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.about__page__wrap__content__acordion__block .block__head .button:hover > i {
  color: white;
}
.about__page__wrap__content__acordion__block .block__acordion {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  max-height: 0px;
  overflow: hidden;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  margin: 0 -10px;
  padding-left: 5px;
}
@media only screen and (max-width: 668px) {
  .about__page__wrap__content__acordion__block .block__acordion {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 auto;
  }
}
.about__page__wrap__content__acordion__block .block__acordion.open {
  max-height: 1000px;
  height: 100%;
}
.about__page__wrap__content__acordion__block .block__acordion.open__select {
  max-height: 1000px;
  height: 100%;
}
.about__page__wrap__content__acordion__block .block__acordion .caption-m {
  color: #519025;
}
@media only screen and (max-width: 768px) {
  .about__page__wrap__content__acordion__block .block__acordion .caption-m {
    font-size: 24px;
  }
}
.about__page__wrap__content__acordion__block .block__acordion .block__wrap {
  width: calc(
    (((100% - 10px - (10px * (12 - 1))) / 12) * 6) + (10px * (6 - 1))
  );
  margin-left: 5px;
  margin-right: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  margin-right: 5px;
}
@media only screen and (max-width: 668px) {
  .about__page__wrap__content__acordion__block .block__acordion .block__wrap {
    width: calc(
      (((100% - 0px - (0px * (12 - 1))) / 12) * 12) + (0px * (12 - 1))
    );
    margin-left: 0px;
    margin-right: 0px;
  }
}
.about__page__wrap__content__acordion__block
  .block__acordion
  .block__wrap
  h3.caption {
  color: #519025;
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 20px;
}
.about__page__wrap__content__acordion__block
  .block__acordion
  .block__wrap
  .desc {
  color: #3d5157;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

.feedback__page__wrap__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
@media only screen and (max-width: 768px) {
  .feedback__page__wrap__head {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
}
.feedback__page__wrap__head .name {
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  color: black;
  margin-right: 10px;
}
@media only screen and (max-width: 768px) {
  .feedback__page__wrap__head .name {
    margin-bottom: 10px;
  }
}
.feedback__page__wrap__head .work {
  font-size: 18px;
  line-height: 32px;
  font-weight: 500;
  color: black;
}

.feedback__page__wrap__box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 30px;
}
@media only screen and (max-width: 960px) {
  .feedback__page__wrap__box {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
  }
}
.feedback__page__wrap__box__video {
  width: calc((((100% - 0px - (0px * (12 - 1))) / 12) * 6) + (0px * (6 - 1)));
  margin-left: 0px;
  margin-right: 0px;
  margin-right: 20px;
}
@media only screen and (max-width: 960px) {
  .feedback__page__wrap__box__video {
    margin-right: 0px;
    width: calc(
      (((100% - 0px - (0px * (12 - 1))) / 12) * 12) + (0px * (12 - 1))
    );
    margin-left: 0px;
    margin-right: 0px;
    height: 300px;
  }
}
.feedback__page__wrap__box__video iframe {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.feedback__page__wrap__box .company__wrap {
  width: calc((((100% - 0px - (0px * (12 - 1))) / 12) * 6) + (0px * (6 - 1)));
  margin-left: 0px;
  margin-right: 0px;
}
@media only screen and (max-width: 960px) {
  .feedback__page__wrap__box .company__wrap {
    width: calc(
      (((100% - 0px - (0px * (12 - 1))) / 12) * 12) + (0px * (12 - 1))
    );
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 10px;
  }
}
.feedback__page__wrap__box .company__wrap__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}
.feedback__page__wrap__box .company__wrap__top__logo {
  padding: 20px;
}
.feedback__page__wrap__box .company__wrap__top__logo img {
  -o-object-fit: cover;
  object-fit: cover;
  height: 72px;
  width: 72px;
}
.feedback__page__wrap__box .company__wrap__top__box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.feedback__page__wrap__box .company__wrap__top__box .company__name {
  font-size: 18px;
  line-height: 32px;
  font-weight: 500;
  color: black;
}
.feedback__page__wrap__box .company__wrap__top__box .company__desc {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: black;
}
.feedback__page__wrap__box .feedback__text p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: black;
  margin-bottom: 30px;
}

@media only screen and (max-width: 960px) {
  .feedback__page__wrap.feedback__template-2 .feedback__page__wrap__box {
    display: block;
  }
}

.feedback__page__wrap.feedback__template-2 .feedback__page__wrap__box__img {
  width: calc((((100% - 0px - (0px * (12 - 1))) / 12) * 3) + (0px * (3 - 1)));
  margin-left: 0px;
  margin-right: 0px;
  margin-right: 20px;
  border-radius: 10px;
  overflow: hidden;
}
@media only screen and (max-width: 960px) {
  .feedback__page__wrap.feedback__template-2 .feedback__page__wrap__box__img {
    float: left;
  }
}
.feedback__page__wrap.feedback__template-2 .feedback__page__wrap__box__img img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.feedback__page__wrap.feedback__template-2 .company__wrap {
  width: calc((((100% - 0px - (0px * (12 - 1))) / 12) * 9) + (0px * (9 - 1)));
  margin-left: 0px;
  margin-right: 0px;
}
@media only screen and (max-width: 960px) {
  .feedback__page__wrap.feedback__template-2 .company__wrap {
    width: calc(
      (((100% - 0px - (0px * (12 - 1))) / 12) * 12) + (0px * (12 - 1))
    );
    margin-left: 0px;
    margin-right: 0px;
  }
}
.feedback__page__wrap.feedback__template-2 .company__wrap__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}
.feedback__page__wrap.feedback__template-2 .company__wrap__top__logo {
  padding: 20px;
}
.feedback__page__wrap.feedback__template-2 .company__wrap__top__logo img {
  -o-object-fit: cover;
  object-fit: cover;
  height: 72px;
  width: 72px;
}

.feedback__page__wrap.feedback__template-3 .feedback__page__wrap__box {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.feedback__page__wrap.feedback__template-3
  .feedback__page__wrap__box
  .company__wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.feedback__page__wrap.feedback__template-3
  .feedback__page__wrap__box
  .company__wrap__top {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.feedback__page__wrap.feedback__template-3 .feedback__page__wrap__box__img {
  width: 92px;
  min-width: 92px;
  height: 92px;
  min-height: 92px;
  margin-right: 20px;
  border-radius: 50%;
  overflow: hidden;
}
@media only screen and (max-width: 568px) {
  .feedback__page__wrap.feedback__template-3 .feedback__page__wrap__box__img {
    margin: 0 auto;
    margin-bottom: 20px;
  }
}
.feedback__page__wrap.feedback__template-3 .feedback__page__wrap__box__img img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.feedback__page__wrap.feedback__template-3 .company__wrap {
  width: calc((((100% - 0px - (0px * (12 - 1))) / 12) * 9) + (0px * (9 - 1)));
  margin-left: 0px;
  margin-right: 0px;
}
@media only screen and (max-width: 768px) {
  .feedback__page__wrap.feedback__template-3 .company__wrap {
    width: calc(
      (((100% - 0px - (0px * (12 - 1))) / 12) * 12) + (0px * (12 - 1))
    );
    margin-left: 0px;
    margin-right: 0px;
  }
}
@media only screen and (max-width: 768px) and (max-width: 568px) {
  .feedback__page__wrap.feedback__template-3 .company__wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
.feedback__page__wrap.feedback__template-3 .company__wrap__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}
@media only screen and (max-width: 568px) {
  .feedback__page__wrap.feedback__template-3 .company__wrap__top {
    margin-bottom: 20px;
  }
}
.feedback__page__wrap.feedback__template-3 .company__wrap__top__logo {
  padding: 20px;
}
.feedback__page__wrap.feedback__template-3 .company__wrap__top__logo img {
  -o-object-fit: cover;
  object-fit: cover;
  height: 72px;
  width: 72px;
}

.feedback__template-1 .feedback__page__wrap__box .company__wrap__top__box {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.feedback__template-1
  .feedback__page__wrap__box
  .company__wrap__top__box
  .company__name {
  line-height: 10px;
  margin-bottom: 10px;
}

.more__feedback {
  margin-bottom: 60px;
}
.more__feedback .caption {
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
}
.more__feedback__wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  margin: 0 -10px;
}
@media only screen and (max-width: 1100px) {
  .more__feedback__wrap {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
.more__feedback__wrap__item {
  width: calc(
    (((100% - 20px - (20px * (12 - 1))) / 12) * 3) + (20px * (3 - 1))
  );
  margin-left: 10px;
  margin-right: 10px;
}
@media only screen and (max-width: 1100px) {
  .more__feedback__wrap__item {
    width: calc(
      (((100% - 20px - (20px * (12 - 1))) / 12) * 6) + (20px * (6 - 1))
    );
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 1100px) and (max-width: 614px) {
  .more__feedback__wrap__item {
    width: calc(
      (((100% - 20px - (20px * (12 - 1))) / 12) * 12) + (20px * (12 - 1))
    );
    margin-left: 10px;
    margin-right: 10px;
  }
}
.more__feedback__wrap__item__img {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: calc(25vh - 24px);
  padding: 40px;
}
.more__feedback__wrap__item__img img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}
.more__feedback__wrap__item__desc {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: black;
}
.more__feedback__wrap__item__desc .job {
  font-size: 18px;
  line-height: 32px;
  font-weight: 500;
  color: black;
}
.more__feedback__wrap__item .button {
  width: 100%;
}

.personal-area {
  margin-top: 50px;
}
@media only screen and (max-width: 960px) {
  .personal-area {
    margin-top: 20px;
  }
}
.personal-area__wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 -10px;
}
@media only screen and (max-width: 960px) {
  .personal-area__wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
.personal-area__wrap__left {
  width: calc(
    (((100% - 10px - (10px * (12 - 1))) / 12) * 4) + (10px * (4 - 1))
  );
  margin-left: 5px;
  margin-right: 15px;
}
@media only screen and (max-width: 960px) {
  .personal-area__wrap__left {
    width: calc(
      (((100% - 0px - (0px * (12 - 1))) / 12) * 12) + (0px * (12 - 1))
    );
    margin-left: 0px;
    margin-right: 0px;
  }
}
@media only screen and (max-width: 960px) and (max-width: 768px) {
  .personal-area__wrap__left {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
@media only screen and (max-width: 960px) {
  .personal-area__wrap__left .persolan-area__conteiner {
    width: 100%;
    margin-left: 5px;
    margin-right: 5px;
  }
}
@media only screen and (max-width: 960px) and (max-width: 768px) {
  .personal-area__wrap__left .persolan-area__conteiner {
    width: calc(
      (((100% - 10px - (10px * (12 - 1))) / 12) * 12) + (10px * (12 - 1))
    );
    margin-left: 5px;
    margin-right: 5px;
  }
}
@media only screen and (max-width: 960px) and (max-width: 768px) and (max-width: 681px) {
  .personal-area__wrap__left .persolan-area__conteiner {
    min-height: 250px;
  }
}
.personal-area__wrap__left .persolan-area__conteiner__btn-tabs {
  display: none;
}
@media only screen and (max-width: 680px) {
  .personal-area__wrap__left .persolan-area__conteiner__btn-tabs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 10px;
  }
  .personal-area__wrap__left .persolan-area__conteiner__btn-tabs .slick-list {
    width: 100%;
  }
  .personal-area__wrap__left
    .persolan-area__conteiner__btn-tabs
    .slick-list
    .slick-track {
    width: 100% !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
.personal-area__wrap__left .profile-info__tabs .slick-slide {
  width: calc(50% - 10px);
  padding: 15px;
  margin: 0px 7px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: white;
  white-space: pre-line;
  border: 1px solid #c8c8c8;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 100px;
  height: 40px;
  max-height: 50px;
  color: #3d5157;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  cursor: pointer;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.personal-area__wrap__left .profile-info__tabs .slick-slide:first-child {
  margin-left: 0px;
}
.personal-area__wrap__left .profile-info__tabs .slick-slide:last-child {
  margin-right: 0px;
}
.personal-area__wrap__left .profile-info__tabs .slick-slide:hover {
  background-color: #9ea8ab;
  color: white;
}
.personal-area__wrap__left .profile-info__tabs .slick-slide.slick-current {
  background-color: #9ea8ab;
  color: white;
}
.personal-area__wrap__left__tabs {
  width: 100%;
}
@media only screen and (max-width: 960px) {
  .personal-area__wrap__left__tabs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
@media only screen and (max-width: 960px) and (max-width: 681px) {
  .personal-area__wrap__left__tabs {
    position: relative;
  }
  .personal-area__wrap__left__tabs .slick-track {
    height: 100%;
  }
  .personal-area__wrap__left__tabs .slick-track .slick-slide {
    height: 100%;
  }
  .personal-area__wrap__left__tabs .slick-track .slick-slide div[class*=""] {
    height: 100%;
  }
}
.personal-area__wrap__right {
  width: calc(
    (((100% - 10px - (10px * (12 - 1))) / 12) * 8) + (10px * (8 - 1))
  );
  margin-left: 15px;
  margin-right: 5px;
}
@media only screen and (max-width: 960px) {
  .personal-area__wrap__right {
    width: calc(
      (((100% - 10px - (10px * (12 - 1))) / 12) * 12) + (10px * (12 - 1))
    );
    margin-left: 5px;
    margin-right: 5px;
  }
}
.personal-area__wrap__right .personal-area__tabs__container {
  position: relative;
}
.personal-area__wrap__right .personal-area__wrap__left {
  display: none;
}
@media only screen and (max-width: 768px) {
  .personal-area__wrap__right .personal-area__wrap__left {
    display: block;
  }
}
.personal-area__wrap__right .persolan-area__conteiner .caption {
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  margin-top: 0px;
}
.personal-area__wrap__right .persolan-area__conteiner__btn-tabs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
  overflow-x: auto;
  padding-bottom: 10px;
}
@media only screen and (max-width: 681px) {
  .personal-area__wrap__right .persolan-area__conteiner__btn-tabs {
    overflow-x: auto;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
}
.personal-area__wrap__right .persolan-area__conteiner__btn-tabs .item {
  padding: 15px;
  margin: 0px 7px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: white;
  white-space: pre-line;
  border: 1px solid #c8c8c8;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 100px;
  height: 50px;
  max-height: 50px;
  color: #3d5157;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  cursor: pointer;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
@media only screen and (max-width: 681px) {
  .personal-area__wrap__right .persolan-area__conteiner__btn-tabs .item {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    height: 40px;
  }
}
.personal-area__wrap__right
  .persolan-area__conteiner__btn-tabs
  .item:first-child {
  margin-left: 0px;
}
.personal-area__wrap__right
  .persolan-area__conteiner__btn-tabs
  .item:last-child {
  margin-right: 0px;
}
.personal-area__wrap__right .persolan-area__conteiner__btn-tabs .item:hover {
  background-color: #9ea8ab;
  color: white;
}
.personal-area__wrap__right .persolan-area__conteiner__btn-tabs .item.active {
  background-color: #9ea8ab;
  color: white;
}
.personal-area__wrap__right .persolan-area__conteiner__wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.personal-area__container__devider {
  height: 1px;
  width: 100%;
  background: #eceeee;
  margin: 20px 0;
}
.personal-area__container__file__container__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  margin: -5px -10px;
}

.personal-area__container__file__container__wrapper .file {
  width: calc(25% - 20px);
  background: #eceeee;
  border: 1px solid #e1e1e1;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 5px;
  height: 60px;
  margin: 5px 10px;
  padding: 10px;
  position: relative;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}
@media only screen and(max-width: 768px) {
  .personal-area__container__file__container__wrapper .file {
    width: calc(33.3% - 20px);
  }
}
.personal-area__container__file__container__wrapper .file a {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 5;
}
.personal-area__container__file__container__wrapper .file .file__onhover {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

.personal-area__container__file__container__wrapper
  .file:hover
  .blured__container {
  -webkit-filter: blur(2px);
  filter: blur(2px);
}
.personal-area__container__file__container__wrapper .file:hover .file__onhover {
  opacity: 1;
}
.personal-area__container__file__container__wrapper .file .file__onhover .text {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  margin-left: 10px;
}
.personal-area__container__file__container__wrapper .file .name {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: #242e33;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  overflow-x: hidden;
}
@media only screen and (max-width: 560px) {
  .personal-area__wrap__right .persolan-area__conteiner__wrap {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
}
.personal-area__wrap__right .persolan-area__conteiner__wrap .personal__info {
  width: calc((((100% - 0px - (0px * (12 - 1))) / 12) * 6) + (0px * (6 - 1)));
  margin-left: 0px;
  margin-right: 0px;
}
@media only screen and (max-width: 560px) {
  .personal-area__wrap__right .persolan-area__conteiner__wrap .personal__info {
    width: calc(
      (((100% - 0px - (0px * (12 - 1))) / 12) * 12) + (0px * (12 - 1))
    );
    margin-left: 0px;
    margin-right: 0px;
  }
}
.personal-area .personal-area__wrap__left.persolan-area__conteiner__tabs {
  display: none !important;
}
.personal-area
  .personal-area__wrap__left.persolan-area__conteiner__tabs.active {
  display: block !important;
}
.personal-area .persolan-area__conteiner {
  padding: 20px;
  padding-bottom: 30px;
  background-color: #f5f6f7;
  border-radius: 10px;
  margin-bottom: 30px;
  -webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
}
.personal-area .persolan-area__conteiner.broker__area {
  position: relative;
  padding-top: 68px;
}
.personal-area .persolan-area__conteiner.broker__area .top__header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: #eceeee;
  border-radius: 10px 10px 0px 0px;
  height: 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 20px;
}
.personal-area .persolan-area__conteiner.broker__area .top__header .caption {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 0;
}
.personal-area .persolan-area__conteiner.broker__area .personal__info__avatar {
  width: 60px;
  height: 60px;
  min-width: 60px;
  min-height: 60px;
}
.personal-area .persolan-area__conteiner__tabs {
  display: none;
}
.personal-area .persolan-area__conteiner__tabs.active {
  display: block;
}
.personal-area .persolan-area__conteiner .personal__info .caption {
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  margin-top: 0px;
  margin-bottom: 30px;
}
@media only screen and (max-width: 681px) {
  .personal-area .persolan-area__conteiner .personal__info .caption {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}
.personal-area .persolan-area__conteiner .personal__info__avatar {
  border-radius: 50%;
  overflow: hidden;
  width: 80px;
  height: 80px;
  min-width: 80px;
  min-height: 80px;
  position: relative;
}
.personal-area .persolan-area__conteiner label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  cursor: pointer;
}
@media only screen and (max-width: 681px) {
  .personal-area .persolan-area__conteiner .personal__info__avatar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
}
.personal-area .persolan-area__conteiner .personal__info__avatar img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
@media only screen and (max-width: 681px) {
  .personal-area .persolan-area__conteiner .personal__info__avatar img {
    border-radius: 50%;
    overflow: hidden;
    -o-object-fit: cover;
    object-fit: cover;
  }
}
.personal-area .persolan-area__conteiner .personal__info__desc__mobile__row {
  display: block;
}
@media only screen and (max-width: 681px) {
  .personal-area
    .persolan-area__conteiner
    .personal__info__desc__mobile__row
    .col__mobile {
    width: 50%;
  }
}
@media only screen and (max-width: 681px) {
  .personal-area .persolan-area__conteiner .personal__info__desc__mobile__row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
.personal-area .persolan-area__conteiner .personal__info__desc {
  margin-top: 20px;
}
@media only screen and (max-width: 681px) {
  .personal-area .persolan-area__conteiner .personal__info__desc {
    margin-top: 10px;
  }
}
.personal-area .persolan-area__conteiner .personal__info__desc .label {
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  font-weight: 400;
  color: #434343;
}
.personal-area .persolan-area__conteiner .personal__info__desc .desc {
  font-size: 14px;
  line-height: 26px;
  font-weight: 500;
  color: black;
}
.personal-area .persolan-area__conteiner .personal__info__desc .desc a {
  color: black;
}
.personal-area .persolan-area__conteiner .personal__info .button {
  margin-top: 30px;
}

.personal__chat {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 524px;
  max-width: 460px;
  z-index: 100;
  -webkit-transform: translateX(524px);
  -ms-transform: translateX(524px);
  transform: translateX(524px);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.personal__chat.active {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
  visibility: visible;
}

.personal__chat__file--container {
  position: absolute;
  bottom: 120px;
  left: 50%;
  -webkit-transform: translate(-50%, -35px);
  -ms-transform: translate(-50%, -35px);
  transform: translate(-50%, -35px);
  width: 320px;
  background: #e7e7e7;
  border-radius: 10px;
  padding: 15px;
  padding-bottom: 66px;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.personal__chat__file__overflow {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  border-radius: 10px 0 0 10px;
}

.personal__chat__file__overflow.active {
  opacity: 1;
  visibility: visible;
}

.personal__chat__file__overflow.active .personal__chat__file--container {
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

.personal__chat__file--container .file__container__text__area {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 46px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 15px;
  border-top: 1px solid rgba(109, 109, 109, 0.2);
  width: 100%;
}

.personal__chat__file--container .file__container__text__area input {
  background: transparent;
  border: none;
  outline: none;
  width: 80%;
  font-size: 16px;
}

.personal__chat__file--container
  .file__container__text__area
  input::-webkit-input-placeholder {
  font-weight: normal;
  font-size: 14px;
  line-height: 26px;
  color: #6d6d6d;
}

.personal__chat__file--container
  .file__container__text__area
  input::-moz-placeholder {
  font-weight: normal;
  font-size: 14px;
  line-height: 26px;
  color: #6d6d6d;
}

.personal__chat__file--container
  .file__container__text__area
  input:-ms-input-placeholder {
  font-weight: normal;
  font-size: 14px;
  line-height: 26px;
  color: #6d6d6d;
}

.personal__chat__file--container
  .file__container__text__area
  input::-ms-input-placeholder {
  font-weight: normal;
  font-size: 14px;
  line-height: 26px;
  color: #6d6d6d;
}

.personal__chat__file--container
  .file__container__text__area
  input::placeholder {
  font-weight: normal;
  font-size: 14px;
  line-height: 26px;
  color: #6d6d6d;
}

.personal__chat__file--container .file__container__text__area img {
  cursor: pointer;
}

.personal__chat__file--container .file__container__name {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #242e33;
}

.personal__chat__file--container .close__btn {
  font-size: 12px;
  cursor: pointer;
  color: #6d6d6d;
}

.personal__chat__file--container .file__info {
  margin-left: 12px;
}

.company__arrow {
  min-width: 40px;
  min-height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.company__file__link {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  color: #519025;
  cursor: pointer;
}

.company__file__link:after {
  content: "";
  height: 1px;
  background-color: #519025;
  width: 100%;
  display: block;
  -webkit-transform: translateY(-6px);
  -ms-transform: translateY(-6px);
  transform: translateY(-6px);
  opacity: 0;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.company__file__link--parent:hover .company__file__link:after {
  -webkit-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  transform: translateY(-2px);
  opacity: 1;
}

.personal__chat__file--container .file__icon {
  width: 48px;
  height: 48px;
  min-width: 48px;
  min-height: 48px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: #6d6d6d;
}

.personal__chat__file--container .file__icon svg {
  font-size: 20px;
}

.personal__chat__file--container .file__info .name {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #242e33;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  width: 190px;
}

.personal__chat__file--container .file__info .size {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #434343;
  margin-top: 2px;
}

.personal__chat.persolan-area__conteiner {
  padding-top: 80px;
  background: #ffffff;
  -webkit-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
}

.personal__chat .personal__chat__header {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background: #bff1bb;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 10px 0 0 0;
}

.personal__chat .personal__chat__header .text {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: #242e33;
}
.personal__chat .personal__chat__header .close-btn {
  position: absolute;
  top: 29px;
  left: 20px;
  cursor: pointer;
  font-size: 24px;
}
.personal__chat h3.caption {
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
}

.personal__chat__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  height: 90px;
}
.personal__chat__head:after {
  content: "";
  position: absolute;
  width: calc(100% + 40px);
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  left: -20px;
  bottom: 0px;
}
.personal__chat__head__avatar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.personal__chat__head__avatar .avatar {
  width: 46px;
  height: 46px;
  position: relative;
  border-radius: 50%;
  margin-right: 15px;
}
.personal__chat__head__avatar .avatar .status {
  min-width: 20px;
  min-height: 20px;
  border-radius: 50%;
  border: 4px solid #ffffff;
  position: absolute;
  left: -5px;
  top: -5px;
  background: #ff8a00;
}
.personal__chat__head__avatar .avatar .status.online {
  background: #519025;
}
.personal__chat__head__avatar .avatar img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.personal__chat__head__avatar .avatar__desc {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.personal__chat__head__avatar .avatar__desc .name {
  font-size: 18px;
  line-height: 32px;
  font-weight: 500;
  font-weight: 600;
}
.personal__chat__head__avatar .avatar__desc .last__online {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: #8a8a8a;
  line-height: 21px;
  mix-blend-mode: normal;
}
.personal__chat__head__option .options__btn {
  cursor: pointer;
  position: relative;
}
.personal__chat__head__option .options__btn svg {
  font-size: 8px;
  color: rgba(0, 0, 0, 0.54);
  margin: 0 1px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.personal__chat__head__option .options__btn:hover > svg {
  color: #519025;
  position: relative;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.personal__chat__head__option .options__btn:hover > svg:last-child {
  -webkit-transform: translateX(3px);
  -ms-transform: translateX(3px);
  transform: translateX(3px);
}
.personal__chat__head__option .options__btn:hover > svg:first-child {
  -webkit-transform: translateX(-3px);
  -ms-transform: translateX(-3px);
  transform: translateX(-3px);
}

.chat__container {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 250px;
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
}

.chat__container::-webkit-scrollbar {
  width: 5px;
}

.chat__container::-webkit-scrollbar-track {
  background-color: transparent;
}

.chat__container::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: #519025;
}

.chat__container .no-content {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 18px;
}

.personal__chat__body {
  padding-bottom: 50px;
  margin-left: -20px;
  margin-right: -15px;
}
.personal__chat__body .messege__wrap__left .messege.messege {
  max-width: 100%;
}

.personal__chat__body .messege__wrap__left .messege .messege__content__file {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}
.personal__chat__body
  .messege__wrap__left
  .messege
  .messege__content__file
  .icon {
  min-width: 38px;
  min-height: 38px;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background: #eaeaea;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.personal__chat__body
  .messege__wrap__left
  .messege
  .messege__content__file
  .icon
  svg {
  font-size: 20px;
  position: absolute;
  color: #202020;
}

.personal__chat__body
  .messege__wrap__left
  .messege
  .messege__content__file
  .icon
  svg.file {
  opacity: 1;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.personal__chat__body
  .messege__wrap__left
  .messege
  .messege__content__file
  .icon
  svg.download {
  opacity: 0;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -160%);
  -ms-transform: translate(-50%, -160%);
  transform: translate(-50%, -160%);
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.personal__chat__body
  .messege__wrap__left
  .messege
  .messege__content__file:hover
  .icon
  svg.file {
  opacity: 0;
  -webkit-transform: translate(-50%, 20%);
  -ms-transform: translate(-50%, 20%);
  transform: translate(-50%, 20%);
}

.personal__chat__body
  .messege__wrap__left
  .messege
  .messege__content__file:hover
  .icon
  svg.download {
  opacity: 1;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.personal__chat__body
  .messege__wrap__left
  .messege
  .messege__content__file
  .name {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #202020;
  max-width: 100%;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}
.personal__chat__body
  .messege__wrap__left
  .messege
  .messege__content__file
  .size {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #202020;
}
.personal__chat__body .messege__wrap__left {
  padding: 20px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.personal__chat__body .messege__wrap__left .messege .messege__content {
  background: #dadada;
  border-radius: 25px;
  position: relative;
}
.personal__chat__body .messege__wrap__left .messege .messege__content:after {
  content: "";
  position: absolute;
  width: 0px;
  height: 0px;
  bottom: 0px;
  left: -5px;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #dadada;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  z-index: 0;
}
.personal__chat__body .messege__wrap__left .messege .messege__content p {
  color: #202020;
  z-index: 10;
}

.personal__chat__body .messege__wrap__right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 20px 0px;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.personal__chat__body .messege__wrap__right .messege.messege {
  max-width: 100%;
}
.personal__chat__body .messege__wrap__right .messege .messege__content {
  background: #519025;
  border-radius: 25px;
  position: relative;
}
.personal__chat__body .messege__wrap__right .messege .messege__content__file {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}
.personal__chat__body
  .messege__wrap__right
  .messege
  .messege__content__file
  .icon {
  min-width: 38px;
  min-height: 38px;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background: #eaeaea;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.personal__chat__body
  .messege__wrap__right
  .messege
  .messege__content__file
  .icon
  svg {
  font-size: 20px;
  position: absolute;
  color: #387210;
}

.personal__chat__body
  .messege__wrap__right
  .messege
  .messege__content__file
  .icon
  svg.file {
  opacity: 1;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.personal__chat__body
  .messege__wrap__right
  .messege
  .messege__content__file
  .icon
  svg.download {
  opacity: 0;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -160%);
  -ms-transform: translate(-50%, -160%);
  transform: translate(-50%, -160%);
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.personal__chat__body
  .messege__wrap__right
  .messege
  .messege__content__file:hover
  .icon
  svg.file {
  opacity: 0;
  -webkit-transform: translate(-50%, 20%);
  -ms-transform: translate(-50%, 20%);
  transform: translate(-50%, 20%);
}

.personal__chat__body
  .messege__wrap__right
  .messege
  .messege__content__file:hover
  .icon
  svg.download {
  opacity: 1;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.personal__chat__body
  .messege__wrap__right
  .messege
  .messege__content__file
  .name {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #ffffff;
  max-width: 100%;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}
.personal__chat__body
  .messege__wrap__right
  .messege
  .messege__content__file
  .size {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #eceeee;
}
.personal__chat__body .messege__wrap__right .messege .messege__content:after {
  content: "";
  position: absolute;
  width: 0px;
  height: 0px;
  bottom: 0px;
  right: -5px;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #519025;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  z-index: 0;
}
.personal__chat__body .messege__wrap__right .messege .messege__content p {
  color: white;
  z-index: 10;
}
.personal__chat__body .messege__wrap__right .messege .messege__date {
  text-align: right;
}

.personal__chat__body .messege .messege__content {
  padding: 12px 20px;
}
.personal__chat__body .messege .messege__content p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

.personal__chat__body .messege__date {
  margin-top: 5px;
  padding: 0px 20px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 300;
  color: rgba(26, 32, 40, 0.3);
}

.type__messege__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 72px;
  flex: 1 0 72px;
  min-height: 72px;
  background: #f7f7f7;
  padding-right: 10px;
  border-radius: 0 0 0 10px;
}
.type__messege__container .type__messege__wrap {
  width: calc((((100% - 0px - (0px * (12 - 1))) / 12) * 11) + (0px * (11 - 1)));
  margin-left: 0px;
  margin-right: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  mix-blend-mode: normal;
  border-radius: 24px;
  padding: 15px 30px;
}
@media only screen and (max-width: 475px) {
  .type__messege__container .type__messege__wrap {
    padding: 15px 7px;
  }
}
.type__messege__container .upload {
  margin-right: 12px;
  cursor: pointer;
}
.type__messege__container .upload svg {
  font-size: 22px;
  color: #9ea8ab;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.type__messege__container .upload:hover > svg {
  color: #0f7595;
}
.type__messege__container .type__messege__wrap .type__text {
  background-color: transparent;
  width: calc(
    (((100% - 10px - (10px * (12 - 1))) / 12) * 11) + (10px * (11 - 1))
  );
  width: 100%;
  line-height: 22px;
  height: 100% !important;
  border: none;
  resize: none;
  font-size: 16px;
  font-family: IBM Plex Sans, sans-serif;
}

.type__messege__container .type__messege__wrap .type__text::-webkit-scrollbar {
  width: 5px;
}

.type__messege__container
  .type__messege__wrap
  .type__text::-webkit-scrollbar-track {
  background-color: transparent;
}

.type__messege__container
  .type__messege__wrap
  .type__text::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: #9ea8ab;
}
.type__messege__container
  .type__messege__wrap
  .type__text::-webkit-input-placeholder {
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  color: #8d8d8d;
}
.type__messege__container .type__messege__wrap .type__text::-moz-placeholder {
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #8d8d8d;
}
.type__messege__container
  .type__messege__wrap
  .type__text:-ms-input-placeholder {
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  color: #8d8d8d;
}
.type__messege__container
  .type__messege__wrap
  .type__text::-ms-input-placeholder {
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  color: #8d8d8d;
}
.type__messege__container .type__messege__wrap .type__text::placeholder {
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #8d8d8d;
}
.type__messege__container .type__messege__wrap .microphone {
  cursor: pointer;
}
.type__messege__container .type__messege__wrap .microphone i {
  font-size: 22px;
  color: #9ea8ab;
}
.type__messege__container .type__messege__wrap .microphone:hover > i {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  color: #0f7595;
}
.type__messege__container .send__messege {
  background-color: #519025;
  width: 52px;
  min-width: 52px;
  height: 52px;
  border-radius: 50%;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 30px;
}
@media only screen and (max-width: 475px) {
  .type__messege__container .send__messege {
    margin-left: 15px;
    max-width: 40px;
    max-height: 40px;
    min-width: 40px;
  }
}
.type__messege__container .emoji-picker-icon {
  position: relative;
  font-size: 22px;
  color: #9ea8ab;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.type__messege__container .emoji-picker-icon:hover > i {
  color: #ff9800;
}

.persolan-area__conteiner__select {
  display: none;
}
@media only screen and (max-width: 768px) {
  .personal__chat .personal__chat__header .text {
    font-size: 18px;
  }

  .personal__chat .personal__chat__header .close-btn {
    font-size: 20px;
  }
  .personal-area .persolan-area__conteiner.personal__chat {
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    z-index: 100;
    -webkit-transform: translateX(100vw);
    -ms-transform: translateX(100vw);
    transform: translateX(100vw);
    width: 100%;
    max-width: 100%;
    margin-bottom: 0;
  }

  .personal-area .persolan-area__conteiner.personal__chat.active {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    padding-bottom: 95px;
  }

  /*.chat__container {*/
  /*  min-height: calc(100vh - 80px - 90px - 72px - 20px);*/
  /*  max-height: calc(100vh - 80px - 90px - 72px - 20px);*/
  /*  height: calc(100vh - 80px - 90px - 72px - 20px);*/
  /*}*/

  .personal__chat__body {
    min-height: calc(100vh - 80px - 90px - 72px);
    max-height: calc(100vh - 80px - 90px - 72px);
    height: calc(100vh - 80px - 90px - 72px);
    padding-bottom: 0px;
    margin-left: -20px;
    margin-right: -15px;
  }

  .toggle__chat__btn {
    opacity: 1;
    visibility: visible;
  }

  .toggle__chat__btn.chat__opened {
    -webkit-transform: translateX(0) !important;
    -ms-transform: translateX(0) !important;
    transform: translateX(0) !important;
    opacity: 0;
    visibility: hidden;
  }
  .personal-area__container__file__container__wrapper .file {
    width: calc(33.3% - 20px);
  }
  .persolan-area__conteiner__select {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 20px;
    min-width: 250px;
    border: 1px solid #c8c8c8;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 100px;
    cursor: pointer;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    font-size: 14px;
    line-height: 21px;
    color: #3d5157;
    padding: 10px 30px;
  }
  .persolan-area__conteiner__select i {
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
  }
  .persolan-area__conteiner__select.open i {
    -webkit-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
}

@media only screen and (max-width: 768px) {
  .greeting {
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    padding: 0 10px;
    font-size: 1.5rem !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .greeting .greeting--mask {
    -webkit-animation: mask-move-768 2s linear infinite alternate !important;
    animation: mask-move-768 2s linear infinite alternate !important;
  }

  .greeting::after {
    -webkit-animation: mask-move-768 2s linear infinite alternate !important;
    animation: mask-move-768 2s linear infinite alternate !important;
  }

  .greeting .greeting--mask .greeting--mask-inner {
    -webkit-animation: mask-inner-move-768 2s linear infinite alternate !important;
    animation: mask-inner-move-768 2s linear infinite alternate !important;
  }
  .button__red {
    width: 100%;
  }

  .breadcrums {
    display: none;
  }
  .will-w100 {
    width: 100%;
  }
  .will-wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .will-mt20 {
    margin-top: 20px;
  }

  .will-wrap > *:last-child {
    margin-top: 20px;
  }
  .personal-area__tabs__container {
    position: relative;
  }
}

@media only screen and (max-width: 681px) {
  h2.caption__personal-area {
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
  }
}

@media only screen and (max-width: 681px) {
  .personal__info__mobile .button {
    margin-top: 0px !important;
    padding: 5px;
    font-size: 14px;
    line-height: 18px;
    white-space: nowrap;
  }
}

@media only screen and (max-width: 681px) {
  .personal-area
    .personal-area__wrap__left__tabs
    .persolan-area__conteiner
    .label {
    font-size: 14px;
    font-weight: 300;
  }
  .personal-area
    .personal-area__wrap__left__tabs
    .persolan-area__conteiner
    .desc {
    font-size: 14px;
    line-height: 16px;
  }
}

.no__scroll {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100vh;
}
@media screen and (max-width: 768px) {
  .no__scroll-mobile {
    overflow: hidden;
    position: fixed;
    width: 100%;
    height: 100vh;
  }
}

.mdl__btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.modal__wrap {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: -2;
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-transition-delay: 0.8s;
  -o-transition-delay: 0.8s;
  transition-delay: 0.8s;
}
.modal__wrap.active {
  z-index: 999;
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
}
.modal__wrap.active .modal__call {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  -webkit-transition-delay: 0.3s;
  -o-transition-delay: 0.3s;
  transition-delay: 0.3s;
  -webkit-transform: translateY(100px);
  -ms-transform: translateY(100px);
  transform: translateY(100px);
}
@media only screen and (max-width: 768px) {
  .modal__wrap.active .modal__call {
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    padding: 30px;
  }
}
@media only screen and (max-height: 768px) {
  .modal__wrap.active .modal__call {
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
  }
}
.modal__wrap.active .modal__content {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  -webkit-transition-delay: 0.3s;
  -o-transition-delay: 0.3s;
  transition-delay: 0.3s;
  -webkit-transform: translateY(100px);
  -ms-transform: translateY(100px);
  transform: translateY(100px);
}
@media only screen and (max-width: 768px) {
  .modal__wrap.active .modal__content {
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
  }
}
@media only screen and (max-width: 768px) and (max-width: 610px) {
  .modal__wrap.active .modal__content {
    height: 100vh;
  }
}
@media only screen and (max-height: 768px) {
  .modal__wrap.active .modal__content {
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
  }
}

.request-call__mobile__wrap {
  width: 100%;
  position: absolute;
  bottom: 0px;
  -webkit-transform: translateY(450px);
  -ms-transform: translateY(450px);
  transform: translateY(450px);
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  display: none;
}
@media only screen and (max-width: 768px) {
  .request-call__mobile__wrap {
    display: block;
  }
}
.request-call__mobile__wrap .request-call__mobile {
  opacity: 0;
  visibility: hidden;
  z-index: -1;
}
.request-call__mobile__wrap.open {
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
  z-index: 99;
}
.request-call__mobile__wrap.open .request-call__mobile {
  opacity: 1;
  visibility: visible;
  z-index: 99;
}
.request-call__mobile__wrap__button {
  background-color: #519025;
  border-radius: 10px 10px 0px 0px;
  padding: 20px 30px;
  white-space: nowrap;
  position: absolute;
  top: -66px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  cursor: pointer;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  z-index: 200;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
}
.request-call__mobile__wrap .request-call__mobile {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  padding: 50px;
  background: #f5f6f7;
  border-radius: 10px 10px 0px 0px;
  max-height: 450px;
  min-height: 450px;
}
@media only screen and (max-width: 560px) {
  .request-call__mobile__wrap .request-call__mobile {
    padding: 30px;
  }
  .personal-area__container__file__container__wrapper .file {
    width: 100%;
  }
}
@media only screen and (max-width: 560px) and (max-width: 360px) {
  .request-call__mobile__wrap .request-call__mobile {
    padding: 10px;
  }
}
.request-call__mobile__wrap .request-call__mobile__head {
  margin-bottom: 50px;
}
.request-call__mobile__wrap .request-call__mobile__head .caption {
  font-size: 32px;
  line-height: 36px;
  font-weight: 600;
  color: #3d5157;
}
.request-call__mobile__wrap .request-call__mobile__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.request-call__mobile__wrap .request-call__mobile__body__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 30px;
}
.request-call__mobile__wrap .request-call__mobile__body__row label {
  color: #3d5157;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}
.request-call__mobile__wrap .request-call__mobile__body__row input {
  background: #f0f0f0;
  border: 1px solid #d9d9d9;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 15px;
  line-height: 18px;
  width: 100%;
}
.request-call__mobile__wrap .request-call__mobile__body .button {
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

.modal__content {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  -webkit-transform: translateY(-1000px);
  -ms-transform: translateY(-1000px);
  transform: translateY(-1000px);
  padding: 50px;
  background: #f5f6f7;
  border-radius: 10px;
  position: relative;
  margin: 0 auto;
  width: 75%;
  max-width: 900px;
}
@media only screen and (max-width: 760px) {
  .modal__content {
    width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .modal__content {
    padding: 25px 10px;
    padding-top: 50px;
  }
}
.modal__content__head {
  margin-bottom: 30px;
}
.modal__content__head .caption {
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  color: #3d5157;
  text-align: center;
}
.modal__content__head .close__modal {
  position: absolute;
  right: 25px;
  top: 25px;
  color: #242e33;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
}
.modal__content__head .close__modal:hover {
  -webkit-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
  font-size: 20px;
}
.modal__content__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media only screen and (max-width: 668px) {
  .modal__content__body {
    overflow-y: hidden;
  }
}
@media only screen and (max-height: 512px) {
  .modal__content__body {
    overflow-y: hidden;
  }
}
.modal__content__body__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 30px;
  height: 80px;
  min-height: 80px;
  width: 100%;
}
@media only screen and (max-width: 610px) {
  .modal__content__body__row {
    margin-bottom: 18px;
  }
}
.modal__content__body__row .help__error {
  opacity: 0;
}
.modal__content__body__row label {
  color: #3d5157;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}
.modal__content__body__row input {
  background: #f0f0f0;
  border: 1px solid #d9d9d9;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 15px;
  line-height: 18px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}
.modal__content__body__row .help__error {
  height: 0px;
  font-size: 14px;
  color: red;
}
.modal__content .hint {
  margin-bottom: 30px;
  padding-top: 10px;
  width: 100%;
}
@media only screen and (max-width: 610px) {
  .modal__content .hint {
    padding: 0 12px;
    padding-top: 10px;
  }
}
.modal__content .hint span {
  font-size: 16px;
  color: black;
}
.modal__content .hint a {
  font-size: 16px;
  text-decoration: underline;
  color: #18a4e0;
  margin-left: 5px;
}
.modal__content .button {
  width: 230px;
  height: 54px;
  min-height: 54px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

.modal__insurance {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  position: relative;
}
@media only screen and (max-width: 668px) {
  .modal__insurance {
    height: calc(
      100vh - 60px - 50px - 25px - 50px - 10px - 30px - 54px - 138px
    );
    max-height: 420px;
    overflow-y: scroll;
  }
}
@media only screen and (max-width: 668px) and (max-width: 610px) {
  .modal__insurance {
    max-height: inherit;
  }
}
@media only screen and (max-height: 512px) {
  .modal__insurance {
    height: 65px;
    max-height: 65px;
    overflow: auto;
  }
}
@media only screen and (max-width: 668px) {
  .modal__insurance__wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    height: 420px;
    min-height: 420px;
    position: relative;
  }
}
@media only screen and (max-width: 668px) and (max-width: 610px) {
  .modal__insurance__wrap {
    height: 700px;
    min-height: 700px;
  }
}
.modal__insurance__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0px -10px;
}
@media only screen and (max-width: 610px) {
  .modal__insurance__row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0px;
  }
}
.modal__insurance__row .modal__content__body__row {
  width: calc(
    (((100% - 24px - (24px * (12 - 1))) / 12) * 6) + (24px * (6 - 1))
  );
  margin-left: 12px;
  margin-right: 12px;
}
@media only screen and (max-width: 610px) {
  .modal__insurance__row .modal__content__body__row {
    width: calc(
      (((100% - 24px - (24px * (12 - 1))) / 12) * 12) + (24px * (12 - 1))
    );
    margin-left: 12px;
    margin-right: 12px;
  }
}

.modal__termin__wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media only screen and (max-width: 668px) {
  .modal__termin__wrap {
    overflow-y: auto;
  }
}
@media only screen and (max-height: 512px) {
  .modal__termin__wrap {
    overflow-y: auto;
  }
}
.modal__termin__wrap__down {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.modal__termin__wrap__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0px -5px;
  width: calc(100% + 10px);
}
.modal__termin__wrap__row .modal__content__body__row {
  width: calc(
    (((100% - 10px - (10px * (12 - 1))) / 12) * 6) + (10px * (6 - 1))
  );
  margin-left: 5px;
  margin-right: 5px;
}
@media only screen and (max-width: 768px) {
  .modal__termin__wrap__row {
    margin: 0px 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
  }
  .modal__termin__wrap__row .modal__content__body__row {
    width: calc(
      (((100% - 0px - (0px * (12 - 1))) / 12) * 12) + (0px * (12 - 1))
    );
    margin-left: 0px;
    margin-right: 0px;
  }
}

.modal__call {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  -webkit-transform: translateY(-500px);
  -ms-transform: translateY(-500px);
  transform: translateY(-500px);
  max-width: 500px;
  padding: 50px;
  background: #f5f6f7;
  border-radius: 10px;
  position: relative;
}
.modal__call__head {
  margin-bottom: 30px;
}
.modal__call__head .caption {
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  color: #3d5157;
}
.modal__call__head .close__modal {
  position: absolute;
  right: 25px;
  top: 25px;
  color: #242e33;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
}
.modal__call__head .close__modal:hover {
  -webkit-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
  font-size: 20px;
}
.modal__call__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.modal__call__body .help__error {
  color: #df3737;
  line-height: 18px;
  font-size: 14px;
  height: 0px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: translateX(-50px);
  -ms-transform: translateX(-50px);
  transform: translateX(-50px);
}
.modal__call__body.error .help__error {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px);
}
@media only screen and (max-height: 450px) {
  .modal__call__body {
    max-height: 220px;
    overflow-y: auto;
  }
}
.modal__call__body__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 30px;
}
.modal__call__body__row .help__error {
  opacity: 0;
}
.modal__call__body__row label {
  color: #3d5157;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}
.modal__call__body__row input {
  background: #f0f0f0;
  border: 1px solid #d9d9d9;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 15px;
  line-height: 18px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}
.modal__call__body__row .help__error {
  height: 0px;
  font-size: 14px;
  color: red;
}
.modal__call .button {
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

.form-group_checkbox label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #1a1625;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 6px;
  border-radius: 2px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}

.form-group_checkbox input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 4px;
  left: 5px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.form-group_checkbox input:checked + label:before {
  background-color: #1a1625;
}

.form-group_checkbox input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-group_checkbox label {
  position: relative;
  cursor: pointer;
}

.footer__wrap__bg {
  padding-bottom: 50px;
}

.footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 30px;
  margin-bottom: 10px;
}
@media only screen and (max-width: 1100px) {
  .footer {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
.footer__about {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  width: calc((((100% - 0px - (0px * (12 - 1))) / 12) * 3) + (0px * (3 - 1)));
  margin-left: 0px;
  margin-right: 0px;
  margin-right: 20px;
}
@media only screen and (max-width: 1100px) {
  .footer__about {
    margin-bottom: 30px;
    width: calc(
      (((100% - 0px - (0px * (12 - 1))) / 12) * 12) + (0px * (12 - 1))
    );
    margin-left: 0px;
    margin-right: 0px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
}
@media only screen and (max-width: 1100px) and (max-width: 810px) {
  .footer__about {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
@media only screen and (max-width: 1100px) {
  .footer__about div:first-child {
    width: calc((((100% - 0px - (0px * (12 - 1))) / 12) * 4) + (0px * (4 - 1)));
    margin-left: 0px;
    margin-right: 0px;
  }
}
@media only screen and (max-width: 1100px) and (max-width: 810px) {
  .footer__about div:first-child {
    width: 50%;
  }
}
@media only screen and (max-width: 1100px) and (max-width: 510px) {
  .footer__about div:first-child {
    width: calc(
      (((100% - 0px - (0px * (12 - 1))) / 12) * 12) + (0px * (12 - 1))
    );
    margin-left: 0px;
    margin-right: 0px;
    text-align: center;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 1100px) {
  .footer__about div:nth-child(2) {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    margin-top: 50px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .footer__about div:nth-child(2) svg:hover {
    background-color: #519025 !important;
  }
  .footer__about div:nth-child(3) {
    width: calc((((100% - 0px - (0px * (12 - 1))) / 12) * 3) + (0px * (3 - 1)));
    margin-left: 0px;
    margin-right: 0px;
  }
}
@media only screen and (max-width: 1100px) and (max-width: 810px) {
  .footer__about div:nth-child(3) {
    width: calc((((100% - 0px - (0px * (12 - 1))) / 12) * 6) + (0px * (6 - 1)));
    margin-left: 0px;
    margin-right: 0px;
    text-align: right;
  }
}
@media only screen and (max-width: 1100px) and (max-width: 810px) and (max-width: 510px) {
  .footer__about div:nth-child(3) {
    margin-top: 0px;
    width: calc(
      (((100% - 0px - (0px * (12 - 1))) / 12) * 12) + (0px * (12 - 1))
    );
    margin-left: 0px;
    margin-right: 0px;
    text-align: center;
  }
  .footer__about div:nth-child(3) a {
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 1100px) {
  .footer__about div:last-child {
    width: calc((((100% - 0px - (0px * (12 - 1))) / 12) * 5) + (0px * (5 - 1)));
    margin-left: 0px;
    margin-right: 0px;
  }
}
@media only screen and (max-width: 1100px) and (max-width: 810px) {
  .footer__about div:last-child {
    width: calc(
      (((100% - 0px - (0px * (12 - 1))) / 12) * 12) + (0px * (12 - 1))
    );
    margin-left: 0px;
    margin-right: 0px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}
@media only screen and (max-width: 1100px) and (max-width: 810px) and (max-width: 510px) {
  .footer__about div:last-child {
    width: calc(
      (((100% - 0px - (0px * (12 - 1))) / 12) * 12) + (0px * (12 - 1))
    );
    margin-left: 0px;
    margin-right: 0px;
    text-align: center;
  }
}
.footer__about__tel {
  margin-bottom: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.footer__about__tel a {
  font-size: 18px;
  line-height: 32px;
  font-weight: 500;
  font-weight: 600;
  color: #242e33;
}
.footer__about__location {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.footer__about__location span {
  font-size: 18px;
  line-height: 32px;
  font-weight: 500;
  font-weight: 600;
  color: #242e33;
}
@media only screen and (max-width: 475px) {
  .footer__about__location span {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
.footer__logo {
  margin-bottom: 20px;
}
.footer__logo img {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 288px;
  -o-object-fit: contain;
  object-fit: contain;
}
.footer__wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: calc((((100% - 0px - (0px * (12 - 1))) / 12) * 9) + (0px * (9 - 1)));
  margin-left: 0px;
  margin-right: 0px;
}
@media only screen and (max-width: 1100px) {
  .footer__wrap {
    width: calc(
      (((100% - 0px - (0px * (12 - 1))) / 12) * 12) + (0px * (12 - 1))
    );
    margin-left: 0px;
    margin-right: 0px;
  }
}
@media only screen and (max-width: 1100px) and (max-width: 810px) {
  .footer__wrap {
    width: calc((((100% - 0px - (0px * (12 - 1))) / 12) * 7) + (0px * (7 - 1)));
    margin-left: 0px;
    margin-right: 0px;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 1100px) and (max-width: 810px) and (max-width: 710px) {
  .footer__wrap {
    width: calc((((100% - 0px - (0px * (12 - 1))) / 12) * 9) + (0px * (9 - 1)));
    margin-left: 0px;
    margin-right: 0px;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 1100px) and (max-width: 810px) and (max-width: 710px) and (max-width: 610px) {
  .footer__wrap {
    width: calc(
      (((100% - 0px - (0px * (12 - 1))) / 12) * 10) + (0px * (10 - 1))
    );
    margin-left: 0px;
    margin-right: 0px;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 1100px) and (max-width: 810px) and (max-width: 710px) and (max-width: 610px) and (max-width: 510px) {
  .footer__wrap {
    width: calc(
      (((100% - 0px - (0px * (12 - 1))) / 12) * 12) + (0px * (12 - 1))
    );
    margin-left: 0px;
    margin-right: 0px;
    margin: 0 auto;
  }
}
.footer__wrap__naw {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
@media only screen and (max-width: 810px) {
  .footer__wrap__naw {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}
@media only screen and (max-width: 810px) {
  .footer__wrap__naw__item {
    width: calc((((100% - 0px - (0px * (12 - 1))) / 12) * 6) + (0px * (6 - 1)));
    margin-left: 0px;
    margin-right: 0px;
  }
}
@media only screen and (max-width: 810px) and (max-width: 510px) {
  .footer__wrap__naw__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
  }
}
@media only screen and (max-width: 810px) and (max-width: 510px) {
  .footer__wrap__naw__item {
    width: calc((((100% - 0px - (0px * (12 - 1))) / 12) * 7) + (0px * (7 - 1)));
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 50px;
  }
}
.footer__wrap__naw__item .caption {
  margin-bottom: 32px;
  color: #242e33;
  font-size: 18px;
  line-height: 32px;
  font-weight: 500;
  font-weight: 600;
}
.footer__wrap__naw__item a.item {
  line-height: 26px;
  font-size: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #242e33;
  margin-bottom: 12px;
  position: relative;
}
.footer__wrap__naw__item a.link__item__footer {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
.footer__wrap__naw__item a.link__item__footer:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #3d5157;
  bottom: 0px;
  opacity: 1;
  max-width: 0px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
@media only screen and (max-width: 810px) {
  .footer__wrap__naw__item a.link__item__footer {
    width: auto;
  }
}
.footer__wrap__naw__item a.link__item__footer:hover:after {
  opacity: 1;
  max-width: 300px;
}
.footer__wrap__naw__item__social a.item {
  margin-bottom: 25px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  border-radius: 60px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.footer__wrap__naw__item__social a.item:after {
  background: #d0d0d0;
  content: "";
  max-width: 0px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  width: 100%;
  z-index: -1;
}
@media only screen and (max-width: 960px) {
  .footer__wrap__naw__item__social a.item:after {
    display: none;
  }
}
@media only screen and (max-width: 1100px) {
  .footer__wrap__naw__item__social a.item {
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 510px) {
  .footer__wrap__naw__item__social a.item:last-child svg {
    margin-right: 0px;
  }
}
.footer__wrap__naw__item__social a.item:hover:after {
  max-width: 200px;
  z-index: -1;
}
@media only screen and (max-width: 960px) {
  .footer__wrap__naw__item__social a.item:hover:after {
    display: none;
  }
}
.footer__wrap__naw__item__social a.item:hover {
  color: white;
  color: #242e33;
}
.footer__wrap__naw__item__social a.item:hover > svg {
  background: white;
}
.footer__wrap__naw__item__social a.item svg {
  margin-right: 20px;
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.footer__wrap__naw__item__company a.item {
  line-height: 26px;
  font-size: 16px;
}

.footer__wrap__company .caption {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  text-align: left;
}
@media only screen and (max-width: 1100px) {
  .footer__wrap__company .caption {
    text-align: center;
  }
}

.footer__wrap__company__img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
@media only screen and (max-width: 1100px) {
  .footer__wrap__company__img {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}
.footer__wrap__company__img img {
  max-width: 95px;
  max-height: 50px;
  margin-right: 10px;
}

.copy__text {
  margin-top: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: left;
}
@media only screen and (max-width: 1100px) {
  .copy__text {
    text-align: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

@media only screen and (max-width: 510px) {
  .footer__wrap__naw__item__social {
    display: none;
  }
}

.footer__wrap__naw__item__mobile {
  display: none;
}
@media only screen and (max-width: 510px) {
  .footer__wrap__naw__item__mobile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.footer__about__location span b {
  font-size: 18px;
  line-height: 32px;
  font-weight: 600;
  color: #242e33;
}

html {
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 400;
  height: 100%;
}

body > svg {
  width: 0;
  height: 0;
}

.l-page {
  overflow-x: hidden;
}

.l-title {
  font-size: 46px;
  line-height: 52px;
  font-weight: 600;
  margin-top: 64px;
  margin-bottom: 32px;
  text-align: center;
}

.personal__info__user__avatar {
  position: relative;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  cursor: pointer;
}

.personal__info__user__avatar .personal__info__avatar {
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.personal__info__user__avatar:hover .personal__info__avatar img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.personal__info__user__avatar:hover .personal__info__avatar {
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(rgba(33, 33, 33, 0.5)),
    to(rgba(33, 33, 33, 0.5))
  );
  background: -o-linear-gradient(
    bottom,
    rgba(33, 33, 33, 0.5),
    rgba(33, 33, 33, 0.5)
  );
  background: linear-gradient(
    0deg,
    rgba(33, 33, 33, 0.5),
    rgba(33, 33, 33, 0.5)
  );
}

.personal__info__user__avatar svg {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0;
  -webkit-transition: 0.1s;
  -o-transition: 0.1s;
  transition: 0.1s;
  cursor: pointer;
}

.personal__info__user__avatar:hover svg {
  opacity: 1;
}

[class^="uil-"]:before,
[class*=" uil-"] {
  font-family: unicons;
  font-style: normal;
  font-weight: 400;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.6em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: 0.6em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fade-enter {
  opacity: 0;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  -webkit-transition: opacity 600ms ease-in;
  -o-transition: opacity 600ms ease-in;
  transition: opacity 600ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  -webkit-transition: opacity 600ms ease-in;
  -o-transition: opacity 600ms ease-in;
  transition: opacity 600ms ease-in;
}

.fade-element {
  -webkit-animation: fadeOut 0.4s;
  animation: fadeOut 0.4s;
}

div.transition-group {
  position: relative;
}

section.route-section {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

#medical-map {
  height: 490px;
  margin-top: 20px;
}

.toast {
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 0 20px;
  border-radius: 5px;
  min-height: 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 99999;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(-30px);
  -ms-transform: translateY(-30px);
  transform: translateY(-30px);
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.toast .text {
  color: #ffffff;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.toast.success {
  background-color: #519025;
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.toast.error {
  background-color: #e50b0b;
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.08);
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.loader {
  background-color: #519025;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  -webkit-animation: shadow-pulse 1s infinite;
  animation: shadow-pulse 1s infinite;
  z-index: 1000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.pointer {
  cursor: pointer;
}

.parent {
  position: relative;
}

.greeting-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  background-color: #89cb65;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.greeting-wrapper.active {
  opacity: 1;
  visibility: visible;
}

.greeting-wrapper img {
  position: absolute;
  top: 80px;
}

.greeting {
  font-size: 3rem;
  text-transform: uppercase;
  color: white;
  letter-spacing: 0.2rem;
  line-height: 1;
  position: relative;
  width: 580px;
}

.greeting::before {
  content: "Ласкаво просимо :)";
  -webkit-filter: blur(3px);
  filter: blur(3px);
  white-space: pre;
}

.greeting::after {
  content: "";
  position: absolute;
  width: 80px;
  height: calc(100% + 8px);
  top: -2px;
  left: 0;
  border-top: 2px solid;
  border-bottom: 2px solid;
  -webkit-animation: mask-move 3s linear infinite alternate;
  animation: mask-move 3s linear infinite alternate;
}

.greeting .greeting--mask {
  overflow: hidden;
  position: absolute;
  width: 80px;
  height: 100%;
  top: 0;
  left: 0;
  -webkit-animation: mask-move 3s linear infinite alternate;
  animation: mask-move 3s linear infinite alternate;
  background: #89cb66;
}

.greeting .greeting--mask .greeting--mask-inner {
  -webkit-animation: mask-inner-move 3s linear infinite alternate;
  animation: mask-inner-move 3s linear infinite alternate;
}

.number__phone__change svg {
  opacity: 0;
  margin-left: 10px;
}

.number__phone__change:hover svg {
  opacity: 1;
}

.wave_dots {
  position: relative;
  margin-top: 50vh;
  text-align: center;
  width: 100px;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
}

.wave__dots .dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 3px;
  background: rgba(0, 0, 0, 0.54);
  -webkit-animation: wave 1.3s linear infinite;
  animation: wave 1.3s linear infinite;
}

.wave__dots .dot:nth-child(2) {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.wave__dots .dot:nth-child(3) {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.row.align-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.row.space-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.row.centered-x {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.row.centered-y {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.row.centered {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.mt2 {
  margin-top: 2px;
}

.mt5 {
  margin-top: 5px;
}

.mt8 {
  margin-top: 8px;
}

.mt10 {
  margin-top: 10px;
}

.mt12 {
  margin-top: 12px;
}

.mt15 {
  margin-top: 15px;
}

.mt20 {
  margin-top: 20px;
}

.mt24 {
  margin-top: 24px;
}

.mt25 {
  margin-top: 25px;
}

.mt28 {
  margin-top: 28px;
}

.mt30 {
  margin-top: 30px;
}

.mt31 {
  margin-top: 31px;
}

.mt35 {
  margin-top: 35px;
}

.mt40 {
  margin-top: 40px;
}

.mt50 {
  margin-top: 50px;
}

.mt60 {
  margin-top: 60px;
}

.mt70 {
  margin-top: 70px;
}

.mb2 {
  margin-bottom: 2px;
}

.mb5 {
  margin-bottom: 5px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb15 {
  margin-bottom: 15px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb25 {
  margin-bottom: 25px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb35 {
  margin-bottom: 35px;
}

.mb40 {
  margin-bottom: 40px;
}

.mb80 {
  margin-bottom: 80px;
}

.mr5 {
  margin-right: 5px;
}

.mr10 {
  margin-right: 10px;
}

.mr12 {
  margin-right: 12px;
}

.mr15 {
  margin-right: 15px;
}

.mr20 {
  margin-right: 20px;
}

.mr24 {
  margin-right: 24px;
}

.mr30 {
  margin-right: 30px;
}

.mr40 {
  margin-right: 40px;
}

.mr60 {
  margin-right: 60px;
}

.ml5 {
  margin-left: 5px;
}

.ml10 {
  margin-left: 10px;
}

.ml15 {
  margin-left: 15px;
}

.ml20 {
  margin-left: 20px;
}

.ml25 {
  margin-left: 25px;
}

.ml30 {
  margin-left: 30px;
}

.ml40 {
  margin-left: 40px;
}

.file__info__container__w {
  width: calc(100% - 50px);
}

.large__18.text {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  width: 50%;
  display: inline-block;
  line-height: 26px;
  color: #000000;
}

.large__18.text.title_device {
  text-align: right;
}

.toggle__chat__btn {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #519025;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: fixed;
  top: 70%;
  right: 30px;
  cursor: pointer;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  z-index: 100;
}

.toggle__chat__btn:hover {
  background: #87ce55;
}

.toggle__chat__btn.chat__opened {
  -webkit-transform: translateX(-524px);
  -ms-transform: translateX(-524px);
  transform: translateX(-524px);
}

.toggle__chat__btn.active {
  -webkit-animation: jump 1.2s infinite;
  animation: jump 1.2s infinite;
}

.toggle__chat__btn.active:before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  top: -2px;
  right: 5px;
  background-color: #ff1414;
  border: 3px solid #ffffff;
}

/* .jumping-img:hover {
  animation: jump 1.2s infinite;
} */

.scale-icon {
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

.scale-icon:hover {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}

@-webkit-keyframes jump {
  0% {
    -webkit-transform: translateY(0) scale(1.25, 0.75);
    transform: translateY(0) scale(1.25, 0.75);
  }
  50% {
    -webkit-transform: translateY(-25px) scale(1, 1);
    transform: translateY(-25px) scale(1, 1);
  }
  55% {
    -webkit-transform: translateY(-25px) rotate(15deg);
    transform: translateY(-25px) rotate(15deg);
  }
  60% {
    -webkit-transform: translateY(-25px) rotate(-15deg);
    transform: translateY(-25px) rotate(-15deg);
  }
  65% {
    -webkit-transform: translateY(-25px) rotate(15deg);
    transform: translateY(-25px) rotate(15deg);
  }
  70% {
    -webkit-transform: translateY(-25px) rotate(-15deg);
    transform: translateY(-25px) rotate(-15deg);
  }
  100% {
    -webkit-transform: translateY(0) scale(1.25, 0.75);
    transform: translateY(0) scale(1.25, 0.75);
  }
}

@keyframes jump {
  0% {
    -webkit-transform: translateY(0) scale(1.25, 0.75);
    transform: translateY(0) scale(1.25, 0.75);
  }
  50% {
    -webkit-transform: translateY(-25px) scale(1, 1);
    transform: translateY(-25px) scale(1, 1);
  }
  55% {
    -webkit-transform: translateY(-25px) rotate(15deg);
    transform: translateY(-25px) rotate(15deg);
  }
  60% {
    -webkit-transform: translateY(-25px) rotate(-15deg);
    transform: translateY(-25px) rotate(-15deg);
  }
  65% {
    -webkit-transform: translateY(-25px) rotate(15deg);
    transform: translateY(-25px) rotate(15deg);
  }
  70% {
    -webkit-transform: translateY(-25px) rotate(-15deg);
    transform: translateY(-25px) rotate(-15deg);
  }
  100% {
    -webkit-transform: translateY(0) scale(1.25, 0.75);
    transform: translateY(0) scale(1.25, 0.75);
  }
}

@-webkit-keyframes wave {
  0%,
  60%,
  100% {
    -webkit-transform: initial;
    transform: initial;
  }

  30% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}

@keyframes wave {
  0%,
  60%,
  100% {
    -webkit-transform: initial;
    transform: initial;
  }

  30% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes shadow-pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-box-shadow: 0 0 0 35px rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 35px rgba(0, 0, 0, 0);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes shadow-pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-box-shadow: 0 0 0 35px rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 35px rgba(0, 0, 0, 0);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes mask-move {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(500px);
    transform: translateX(500px);
  }
}

@keyframes mask-move {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(500px);
    transform: translateX(500px);
  }
}

@-webkit-keyframes mask-inner-move {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-500px);
    transform: translateX(-500px);
  }
}

@keyframes mask-inner-move {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-500px);
    transform: translateX(-500px);
  }
}

@-webkit-keyframes mask-move-768 {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(310px);
    transform: translateX(310px);
  }
}

@keyframes mask-move-768 {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(310px);
    transform: translateX(310px);
  }
}

@-webkit-keyframes mask-inner-move-768 {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-310px);
    transform: translateX(-310px);
  }
}

@keyframes mask-inner-move-768 {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-310px);
    transform: translateX(-310px);
  }
}
