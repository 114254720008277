.refund-form {
  background: #f5f6f7;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.11);
  border-radius: 15px;
  margin-bottom: 10px;
  padding: 25px;

  &--mobile {
    height: 100%;
  }
}

.refund-form__title {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 29px;
  color: #2d2d2e;
  margin-bottom: 30px;
}

.field-block__wraper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  @media (max-width: 1350px) {
    display: block;
  }
}

.field-block {
  position: relative;

  margin-bottom: 30px;

  &--mobile {
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.field-block__item-description {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
  margin-bottom: 17px;
  min-width: 390px;

  &--mobile {
    margin-bottom: 15px;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;

    color: #020202;
  }

  @media (max-width: 1396px) {
    min-width: 0;
    max-width: 300px;
  }
}

.field-block__item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 5px;

  &--mobile {
    display: flex;
    flex-direction: column;
    justify-content: start;
  }
}

.field-block__item-format-type {
  margin-right: 30px;
  width: 370px;
  margin-bottom: 20px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #434343;
  &--mobile {
    margin-bottom: 50px;
    max-width: 310px;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;

    color: #020202;
  }
}

.file-input {
  opacity: 0;
  z-index: -1;
  position: absolute;
}

.field-block__input--mobile {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.field-block__input label {
  position: relative;
  padding: 15px 15px 17px 15px;
  background: rgba(81, 144, 37, 0.05);
  border: 1px solid #519025;
  box-sizing: border-box;
  border-radius: 29px;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;

  color: #519025;

  // &::before {
  //   position: absolute;
  //   content: "";
  //   width: 20px;
  //   height: 20px;
  //   background-image: url("../../../../assets/img/file-atach.svg");
  //   background-repeat: no-repeat;
  //   left: 15px;
  //   margin-bottom: auto;
  //   margin-top: auto;
  // }

  display: flex;
  align-items: center;

  .field-block__item-image {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}

.field-block__input .refund-form__mobile-label {
  width: 100%;
  align-self: center;
  display: flex;
  justify-content: center;
  background: #f2f2f2;
  border-radius: 10px;
}

.field-block__input--inactive label {
  display: flex;
  align-items: center;
  position: relative;
  padding: 15px 15px 17px 15px;
  background: #f0f0f0;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 29px;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;

  color: #9ea8ab;
}

.field-block__input--inactive .refund-form__mobile-label {
  width: 100%;
  align-self: center;
  display: flex;
  justify-content: center;
  background: #f0f0f0;
  border: 1px solid #d9d9d9;
  color: #9ea8ab;
  border-radius: 10px;
}

.file-block {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  &--mobile {
    display: grid;
    grid-template-rows: 50px 50px 50px 1fr;
    gap: 15px;
  }
}

.file-block__item {
  display: flex;
  justify-content: start;
  align-items: center;

  & img {
    margin-right: 5px;
  }

  & span {
    margin-right: 5px;
    word-break: break-all;
    font-size: 13px;

    @media (max-width: 1380px) {
      max-width: 110px;
    }
  }

  &--mobile {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 50px;
    background: #f9f9f9;
    border-radius: 10px;
    padding-left: 15px;
    padding-right: 15px;

    & img {
      margin-right: 15px;
    }

    & span {
      margin-right: auto;
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;

      color: #787e8a;
    }
  }
}

.refund-form__next-button {
  padding-top: 20px;
  padding-bottom: 22px;
  margin-top: 30px;
  background: #519025;
  border-radius: 10px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;

  color: #ffffff;

  &:disabled {
    background: #f2f2f2;
    color: #cccccc;
  }

  &:active {
    background-color: #87ce55;
  }
}

.file-block__item-delate-button {
  padding-top: 7px;
  background: none;
}

.field-block__item-iban {
  display: flex;
  flex-direction: column;
}

.field-block__input-iban {
  background: #f0f0f0;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 3px;
  width: 90%;
  height: 50px;
  padding-left: 10px;
  padding-right: 10px;

  &--mobile {
    height: 0;
    width: 100%;
    background: #f9f9f9;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    padding: 16px;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #0a0a0a;
  }
}

.refund-form__atantion {
  padding-top: 16px;
  padding-bottom: 16px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e7e7e7;
  border-radius: 15px;

  @media (min-width: 800px) {
    padding-left: 131px;
    padding-right: 107px;
  }

  @media (min-width: 1400px) {
    padding-left: 231px;
    padding-right: 207px;
  }

  &--mobile {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffebb8;
    border-radius: 15px;
    padding: 30px 15px 30px 25px;
  }
}

.refund-form__atantion-image {
  margin-right: 20px;
}

.refund-form__submit-button {
  padding: 20px 40px;
  background: #519025;
  border: 1px solid #519025;
  box-sizing: border-box;
  border-radius: 29px;
  color: #ffffff;

  &:active {
    background-color: #87ce55;
  }

  &:hover {
    background-color: #87ce55;
  }
}

.refund-form__submit-button-wraper {
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 30px;
}

.refund-form__visit-table-container {
  padding-left: 0;
  padding-right: 0;
}

.error-validation-screen {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-validation-screen__wrapper {
  padding: 10px;
  position: relative;
  width: 540px;
  height: 478px;
  background: #f5f6f7;
  opacity: 1 !important;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.11);
  border-radius: 15px;
  z-index: 3;
  margin-left: 50%;
  margin-right: 50%;
  // display: grid;
  // grid-template-columns: 35px 1fr 35px;
  // grid-template-rows: 28px 1fr 87px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 500px) {
    width: 340px;
    height: 378px;
  }
}

.error-validation-screen__close-button {
  grid-column: 3/4;
  grid-row: 1/2;
  align-self: flex-end;
  margin-bottom: 30px;
  margin-right: 20px;
  margin-top: 20px;
}

.error-validation-screen__image {
  width: 460px;
  height: 312px;
  grid-row: 2/3;
  grid-column: 2/3;

  @media (max-width: 500px) {
    width: 260px;
    height: 200px;
  }
}

.error-validation-screen__image {
  grid-row: 3/4;
  grid-column: 2/3;
  margin-bottom: 20px;
}

.error-validation-screen__description {
  padding-left: 10px;
  padding-right: 10px;
  width: 355px;
  text-align: center;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  text-align: center;

  color: #787878;

  @media (max-width: 500px) {
    width: 345px;
  }
}

.title-wrapper__mobile {
  display: flex;
  align-items: center;
  height: 86px;
  width: 100%;
  position: relative;
  padding-right: 20px;
  padding-left: 20px;
  margin-bottom: 60px;
}

.back-button-mobile {
  width: 25px;
  height: 15px;
  margin-right: 30px;
  background: none;
  border: none;
  outline: none;
}

.refund-form__process-indicator-container {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 6px;
  background: #f2f2f2;
}

.refund-form__process-indicator {
  background: #519025;
  border-radius: 0px 3px 3px 0px;
  height: 8px;
}

.refund-form__process-indicator-doctor {
  background: #519025;
  height: 8px;
}

.success-screen-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #12c06a;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.error-screen-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #fff;
  padding-left: 20px;
  padding-right: 20px;
}

.success-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-top: 110px;
  position: relative;
}

.success-screen__title {
  margin-bottom: 20px;
  width: 255px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 34px;
  text-align: center;

  color: #ffffff;
}

.error-screen__title {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 34px;
  text-align: center;

  color: #2d2d2e;
}

.success-screen__text {
  width: 246px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  text-align: center;

  color: #ffffff;
}

.error-screen__text {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  /* identical to box height */

  text-align: center;

  color: #2d2d2e;
}

.success-screen_img {
  width: 548px;
}

.success-screen__button {
  margin-bottom: 20px;
  color: #12c06a;
  height: 50px;
  background: #ffffff;
  border-radius: 10px;
  width: 100%;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
}

.error-screen__button {
  margin-bottom: 20px;
  width: 100%;
  height: 50px;
  background: #519025;
  border-radius: 10px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;

  color: #ffffff;
}

.pay-form-mobile {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: #ffffff;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
}

.pay-form-title-wrapper__mobile {
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px -1px 30px rgba(0, 0, 0, 0.1);
  padding-left: 18px;
  padding-right: 18px;
  margin-bottom: 50px;
}

.pay-form-back-button-mobile {
  background: #fff;
  border: none;
  outline: none;
  width: 26px;
  height: 15px;
  margin-right: 18px;
}

.pay-form__title-mobile {
  height: min-content;

  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;

  color: #2d2d2e;
}

.pay-form__field-wrapper-mobile {
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 20px;
}

.pay-form__calendar-button-mobile {
  background: #f9f9f9;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  height: 50px;
  width: 100%;
  padding-left: 18px;
  padding-right: 18px;
  display: flex;
  align-items: center;

  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  color: #0a0a0a;
}

.pay-form__soum-input-mobile {
  height: 50px;
  width: 100%;
  background: #f9f9f9;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  padding: 18px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  color: #0a0a0a;
  padding-left: 50px;
}

.pay-form__field-wrapper-mobile {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  color: #434343;
}

.pay-form-field-description-mobile {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  color: #434343;
  margin-bottom: 5px;
}

.pay-form-field-description-mobile-money {
  position: relative;

  &::before {
    width: 14px;
    height: 14px;
    position: absolute;
    content: "";
    background-image: url(../../../../assets/img/svg/maney-label.svg);
    bottom: -36px;
    left: 23px;
  }
}

.pay-form__submit-button-mobile-wrapper {
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  justify-self: flex-end;
  margin-top: auto;
  margin-bottom: 21px;
}

.pay-form__submit-button-mobile {
  background: #519025;
  border-radius: 10px;
  height: 50px;
  width: 100%;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;

  color: #ffffff;
}

.refund-form__label {
  & svg {
    margin-right: 10px;
  }
}

.success-screen__button-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.error-screen__button-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding-left: 20px;
  padding-right: 20px;
}

.broker-open__button {
  font-family: Arial !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 18px !important;
}

.control-buttons {
  display: flex;
}

.refund-form__open-button {
  background: rgba(81, 144, 37, 0.05);
  outline: 1px solid #519025;
  box-sizing: border-box;
  border-radius: 29px;
  margin-right: 20px;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;

  color: #519025;
  width: 228px;
  outline: none;

  &:active {
    outline: none;
  }
}

.refund-form__open-button:hover {
  background: rgba(81, 144, 37, 0.05);
  outline: 1px solid #519025;
  box-sizing: border-box;
  border-radius: 29px;
  margin-right: 20px;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;

  color: #519025;
  outline: none;
}

.broker-open__button {
  font-family: Arial !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 18px !important;
  outline: none;
}

.refund-form__open-button:disabled {
  background: #f0f0f0;
  color: #9ea8ab;
  outline: none;
}

.main-title_breadcrums {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.main-title_breadcrums div {
  margin-right: 15px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  /* identical to box height */

  text-decoration-line: underline;

  color: #519025;
}
