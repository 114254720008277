.marker {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  z-index: 10;
  bottom: 80px;
  right: 50%;
  left: 50%;
  width: 355px;

  left: calc(50% - 355px / 2);
  bottom: 60px;

  background: #ffffff;
  box-shadow: 0px 3px 10px 8px rgba(0, 0, 0, 0.06);
  border-radius: 20px;
}

.marker__name {
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 20px;
  background: #f9f9f9;
  border-radius: 20px 20px 0px 0px;
  width: 100%;
}

.marker__item {
  width: 100%;
  padding: 15px 20px;
  border-bottom: 1px solid #f3f3f3;

  &:last-of-type {
    margin-bottom: 20px;
  }
}

.marker__submit-button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  height: 50px;
  width: 335px;
  background: #519025;
  border-radius: 10px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;

  color: #ffffff;
  margin-bottom: 20px;

  &:active {
    background-color: darkcyan;
  }
}

.map-container {
  background-color: #f5f6f7;
  padding: 26px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.11);
  border-radius: 15px;
  margin-bottom: 35px;
  position: relative;
}

.map-title {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 29px;
  color: #2d2d2e;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.map-tools {
  display: grid;
  grid-template-columns: 220px 1fr;
  position: relative;
}

.map-search-block {
  grid-column: 2/3;
  padding-left: 30px;
  margin-bottom: 20px;
}

.map-search {
  position: relative;
  width: 399px;
  height: 48px;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 25px;
  background-color: transparent;
  padding-left: 40px;

  & ::placeholder {
    vertical-align: middle;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    /* identical to box height */

    color: #9ea8ab;
  }
}

.map-search-label {
  position: relative;
}

.map-search-label::after {
  content: "";
  width: 18px;
  height: 18px;
  position: absolute;
  background-image: url("../../../../assets/img/serch-icon.svg");
  top: 4px;
  right: 20px;
}

.map-form {
  position: absolute;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 220px;
  padding: 13px 20px 20px 20px;
  background-color: #f5f6f7;

  border: 1px solid #519025;
  box-sizing: border-box;
  border-radius: 25px;
  cursor: pointer;

  &--closed {
    height: 48px;
    padding-top: 0;
    padding-bottom: 0;
    justify-content: center;
    & .map-form__input-block-item {
      display: none;
    }

    & .map-form__title {
      margin: 0;
    }
  }

  & label {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    /* identical to box height */

    color: #2d2d2e;
  }

  & input {
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    border-radius: 5px;
    margin-right: 20px;
  }
}

.map-form__title {
  position: relative;
  padding-left: 20px;
  margin-bottom: 30px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #519025;

  &::before {
    position: absolute;
    content: "";
    width: 20px;
    height: 17px;
    background-image: url(../../../../assets/img/search-filter.svg);
    left: -5px;
    top: 2px;
  }
}

.map-form__input-block {
  width: 100%;
}

.map-form__input-block-item {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.gm-style .gm-style-iw-t::after {
  display: none;
}

// .gm-style-iw-a button {
//   display: none !important;
// }

// .gm-style {
//     width: 100% !important;
// }

.map-container-mobile__search-button {
  background-image: url("../../../../assets/img/serch-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  height: 50px;
  width: 50px;
  background-color: transparent;
}

.map-container-mobile__head {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 101;
  background-color: white;
}

.map-container-mobile__head-top-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.map-container-mobile__page-button-container {
      display: grid;
        grid-template-columns: 1fr 1fr;
        height: 40px;
        box-sizing: border-box;
        width: 100%;
        box-shadow: inset 0px 7px 19px -10px rgba(184,184,184,1);

        .map-container-mobile__page-button {
              border: none;
              background: none;
              outline: none;
              color: #000;
              font-family: IBM Plex Sans;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 18px;
              text-transform: uppercase;
        }

        .map-container-mobile__page-button--active {
          background: transparent;
          border-bottom: 3px solid #519025;
          box-sizing: border-box;
          color: #519025;
        }
}

.search-mobile-screen {
  padding-top: 120px;
  position: relative;
  height: 100vh;
  background-color: white;
  overflow: auto;

  &--close {
    display: none;
  }
}

.search-mobile-screen__empty-list-image-container {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-mobile {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  padding: 30px 20px 10px 20px;
  background: #ffffff;
  box-shadow: 0px -1px 30px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.form-mobile-filter {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  padding: 30px 20px 10px 20px;
  background: #ffffff;
  box-shadow: 0px -1px 30px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.form-mobile__input {
  grid-column: 1/3;
  grid-row: 1/2;
  height: 40px;
  padding-left: 5px;
  padding-right: 20px;
  background: #eef1f6;
  border-radius: 10px;
  margin-right: 15px;
  padding-left: 10px;
  padding-right: 10px;
}

.lpu-type-serch-list {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 30px;
  z-index: -1;
}

.lpu-type-serch-list__item {
  margin-bottom: 10px;
  background: #f2f2f2;
  border-radius: 18px;
  padding: 5px 15px;
  margin-right: 10px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height */

  color: #757575 !important;

  &:hover {
    background: #757575;
    color: #f2f2f2 !important;
  }

  &--active {
    background: #757575;
    color: #f2f2f2 !important;
  }
}

.form-mobile__text-delate-buttom {
  grid-row: 1/2;
  grid-column: 2/3;
  align-self: center;
  justify-self: right;
  z-index: 2;
  width: 16px;
  height: 16px;
  margin-right: 25px;
}

.form-mobile__text-cansel-buttom {
  grid-column: 3/4;
  margin-left: auto;
  width: min-content;
  height: min-content;
  padding: 12px;
  background: #fceaeb;
  border-radius: 10px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height */

  color: #ea5455;
  &:hover {
    background-color: mistyrose;
  }
}

.lpu-list {
  list-style: none;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-content: center;
  padding-left: 20px;
  padding-right: 20px;
}

.lpu-list-category-color {
  width: 25px;
  height: 25px;
  border: 1px solid #fff;
  border-radius: 50%;
}

.lpu-list__item {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
  background: #f9f9f9;
  border-radius: 10px;
  padding-left: 20px;

  & img {
    height: 14px;
    width: 14px;
    border-radius: 50%;
    margin-right: 15px;
  }

  .lpu-list_item-name-block {
    display: flex;
    flex-direction: column;
    width: 80%;

      & .lpu-list_item-name {
          font-family: IBM Plex Sans;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          color: #787e8a;
        }
            & .lpu-list_item-address {
              font-family: IBM Plex Sans;
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 18px;
              color: #9d9d9d;
            }
  }

  &:hover {
    background-color: #d9d9d9;
    & span {
      color: black;
    }
  }

  &:active {
    background-color: #9ea8ab;
    & span {
      color: black;
    }
  }
}

.gm-style-iw-d {
  width: 265px;
}

.gm-style-iw {
  padding: 0;
  position: relative;
}

.gm-style-iw div h3 {
  position: relative;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;

  color: #2d2d2e;
  margin-bottom: 25px;
  margin-left: 25px;
}

.gm-style-iw div h3::before {
  content: "";
  position: fixed;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: #519025;
  left: 27px;
  top: 17px;
}

.gm-style-iw div div {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  padding-left: 5px;
}

.gm-style-iw div div span:first-of-type {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;

  color: #2d2d2e;
}

.gm-style-iw a {
  position: absolute;
  bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background: #519025;
  border-radius: 25px;
  width: 225px;
  height: 40px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 0;

  color: #ffffff;

  &:hover {
    background-color: #87ce55;
  }
}

.map-container-mobile__filter-button {
  background: none;
  background-image: url("../../../../assets/img/filter.svg");
  width: 23px;
  height: 20px;
  background-color: none;
  margin-left: 20px;
  vertical-align: 14px;
}

.form-mobile__title {
  padding-top: 10px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #2d2d2e;
}

.form-mobile__head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.category-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 0px 20px 100px 20px;
}

.category-form__block {
  display: flex;
  justify-content: right;
  width: 100%;
  padding-left: 65px;
}

.category-form__block-input {
  //display: none;
  --webket-appearance: none;
  --moz-appearance: none;
  appearance: none;
}

.category-form__block-input:checked + .category-form__block-label {
  &::before {
    position: absolute;
    content: "";
    width: 50px;
    height: 50px;
    background-image: url("../../../../assets/img/map-filter-check.svg");
    left: -65px;
    border-radius: 1px;
  }
}

.category-form__block-label {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  padding-left: 15px;
  height: 50px;
  background: #f9f9f9;
  border-radius: 10px;
  margin-bottom: 15px;
  position: relative;

  &::before {
    position: absolute;
    content: "";
    width: 50px;
    height: 50px;
    background-image: url("../../../../assets/img/map-filter-not-check.svg");
    left: -60px;
    border-radius: 1px;
  }

  &:checked {
    background-color: yellow;
  }
}

.category-form__block-img {
  width: 14px;
  height: 14px;
  border: 2px solid #fff;
  margin-right: 15px;
  border-radius: 50%;
}

.search-mobile-screen__button-container {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 90px;
  background: #ffffff;
  box-shadow: 0px -1px 10px 1px rgba(0, 0, 0, 0.05);
  border-radius: 15px 15px 0px 0px;
}

.search-mobile-screen__button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;

  & button {
    height: 50px;
    background: #519025;
    border-radius: 10px;
    width: 100%;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;

    color: #ffffff;
  }
}

.cluster-marker {
  color: #fff;
  background: #1978c8;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
}

.crime-marker {
  background: none;
  border: none;
}

.crime-marker img {
  width: 25px;
}

.cluster-marker {
  background: green;
  padding: 15px;
}

.point-marker {
  position: relative;
}

.point-marker-icon {
  width: 30px;
  height: 30px;
  border: 5px solid #fff;
  border-radius: 50%;
}

.point-marker__info-block {
  position: absolute;
  z-index: 101;
  top: -15px;
  left: -280px;
  background-color: #ea5455;
  width: 265px;
  height: min-content;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: default;

  &--close {
    display: none;
  }

  &--mobile {
    position: fixed !important;
    bottom: 0;
    right: 0;
    left: 0;
  }
}

.point-marker__info-block-icon {
  width: 25px;
  height: 25px;
  border: 5px solid #fff;
  border-radius: 50%;
  margin-right: 10px;
}

.point-marker__info-block-name-mobile {
  width: 90%;
}

.point-marker__info-block-name-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
  position: relative;
  width: 100%;
  word-break: break-all;

  & img {
    width: 30px;
    height: 30px;
    margin-right: 6px;
  }
}

.point-marker__info-block-name {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  width: 90%;
  color: #2d2d2e;
}

.point-marker__info-block-item-title {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;

  color: #2d2d2e;
  margin-bottom: 5px;
}

.point-marker__info-block-item-info {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;

  color: #434343;
  margin-bottom: 20px;
  word-break: break-all;
}

.point-marker__submit-button {
  display: block;
  background: #519025;
  border-radius: 25px;
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}

.point-marker_appoint-button {
  margin-bottom: 10px;
  font-size: 16px;
}

.point-marker__info-block-mobile-wrapper {
  bottom: 70px;
  right: 0;
  left: 0;
  position: fixed;
  height: min-content;
  padding-left: 10px;
  padding-right: 10px;
  z-index: 101;
}

.point-marker__info-block-mobile {
  box-shadow: 0px 3px 10px 8px rgba(0, 0, 0, 0.10);
  border-radius: 20px;
  background: #fff;
  padding-bottom: 20px;
  margin-bottom: 5px;
}

.point-marker__info-block-name-wrapper-mobile {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px;
  background: #e9e6e6;
  border-radius: 20px 20px 0px 0px;
}

.point-marker__info-block-name-wrapper-mobile {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #0a0a0a;
}

.gm-style-iw-c,
.gm-style-iw-tc {
  display: none !important;
}

.point-marker__info-block-item-mobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  min-height: 46px;
  border-bottom: 1px solid #f3f3f3;
  padding: 5px 20px 5px 20px;
  margin-bottom: 10px;
}

.point-marker__info-block-item-title-mobile {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  color: #afafaf;
}

.point-marker__info-block-item-info-mobile {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: right;
  color: #0a0a0a;
  word-break: break-word;
}

.mobile-lpu-screen-null {
    display: block;
    margin: 0 auto;
    margin-top: 100px;
}

.mobile-lpu-screen-item-text-block {
  width: 90%;
}

.mobile-lpu-screen {
  width: 100%;
  position: relative;
  padding: 120px 10px 100px 10px;
}

  .mobile-lpu-screen-item {
    padding: 10px;
    box-shadow: 0px 3px 10px 2px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    margin-bottom: 10px;
    border: 1px solid #E1E1E1;
    cursor: pointer;
    transition: all .25s ease;

    .mobile-lpu-screen-item-content {
      display: flex;
    }

    // .mobile-lpu-screen-item-header-block {
    //   display: flex;
    //   justify-content: space-between;

      .mobile-lpu-screen-item-status-image {
        width: 25px;
        height: 25px;
        border: 5px solid #fff;
        margin: auto 10px auto 0;
        border-radius: 50%;
      }

      .mobile-lpu-screen-item-name {
            font-family: IBM Plex Sans;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 18px;
              color: #0a0a0a;
      }
    // }

    .mobile-lpu-screen-item-category {
    font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #afafaf;
    }

    .mobile-lpu-screen-item-adress {
            font-family: IBM Plex Sans;
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 18px;
              color: #0a0a0a;
    }

    .mobile-lpu-screen-item-buttons-container {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;

      .mobile-lpu-screen-item-button {
            background: #519025;
              border-radius: 25px;
              height: 35px;
              width: 48%;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #ffffff;
              font-size: 12px;
      }
    }
  }

.point-marker__submit-button-wrapper {
  padding-left: 20px;
  padding-right: 20px;
}

.map-container-mobile__filter {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 200;
  // overflow: auto;
}

.get-new-geolocation-button {
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 1000;
  padding: 5px;
  background-color: #fff;
  border-radius: 5px;
  cursor: pointer;

  @media (max-width: 800px) {
    bottom: 80px;
    z-index: 10;
  }
}

.my-geolocation-icon-bg {
  position: relative;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: rgba(0, 0, 254, 0.4);
  z-index: 100;
}

.button-icon-not-have-geolocation-bg {
  position: relative;
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.button-icon-have-geolocation-bg {
  position: absolute;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  border: 2px solid #fff;
  top: 50%;
  left: 50%;
  transform: translate(-51%, -51%);
}

.my-geolocation-icon {
  position: absolute;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: rgba(0, 0, 254, 1);
  border: 2px solid #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.point-marker__info-block-close-button {
  width: 10px;
  height: 10px;
  position: relative;
}

.point-marker__info-block-close-button-wrapper {
  position: absolute;
  top: -10px;
  right: -12px;
}

.point-marker__info-block-close-button::before,
.point-marker__info-block-close-button::after {
  position: absolute;
  height: 12px;
  width: 2px;
  background-color: #0a0a0a;
  content: "";
  border-radius: 1px;
}

.point-marker__info-block-close-button::before {
  transform: rotate(45deg);
}

.point-marker__info-block-close-button::after {
  transform: rotate(-45deg);
}

.point-marker__info-block-close-button:hover::before {
  background-color: #504e4e;
}

.point-marker__info-block-close-button:hover::after {
  background-color: #504e4e;
}

.point-marker__info-block-close-button:active::before {
  background-color: #302e2e;
}

.point-marker__info-block-close-button:active::after {
  background-color: #302e2e;
}
