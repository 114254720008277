:root {
  --text-color-black: #2d2d2e;
  --text-color-light-black: #434343;
  --background-light-grey: #f5f6f7;
  --background-darker-grey: #f0f0f0;
  --button-background-color: #519025;
  --basic-white: #ffffff;
  --input-border: #d9d9d9;
  --button-text-disabled: #9ea8ab;
  --button-hover-color: #87ce55;
  --border-grey: #d9d9d9;
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 100;
  src: local("IBMPlexSans Thin"), local("Roboto-Thin"),
    url("../../../../assets/fonts/IBMPlexSans/IBMPlexSans-Thin.ttf");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("IBMPlexSans"), local("IBMPlexSans-Regular"),
    url("../../../../assets/fonts/IBMPlexSans/IBMPlexSans-Regular.ttf");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  src: local("IBMPlexSans Medium"), local("IBMPlexSans-Medium"),
    url("../../../../assets/fonts/IBMPlexSans/IBMPlexSans-Medium.ttf");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: local("IBMPlexSans Bold"), local("IBMPlexSans-Bold"),
    url("../../../../assets/fonts/IBMPlexSans/IBMPlexSans-Bold.ttf");
}

.visit-button {
  background: var(--button-background-color);
  border-radius: 25px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: var(--basic-white);
  width: min-content;
  height: min-content;
  margin-left: auto;
  width: 215px;
  height: 48px;
  padding: 13px 20px 13px 20px;
}

.visit-button:hover {
  background-color: var(--button-hover-color);
}

.visit-button--submit {
  grid-column: 1/4;
  margin-left: auto;
  margin-right: auto;
  width: 370px;
}

.visit-button--disabled {
  color: var(--button-text-disabled);
  background-color: var(--background-darker-grey);
}

.visit-button--disabled:hover {
  color: var(--button-text-disabled);
  background-color: var(--background-darker-grey);
}

.visit-table-container {
  position: relative;
  background-color: var(--background-light-grey);
  padding: 25px;
  box-sizing: border-box;
  border-radius: 15px;
  position: relative;
  margin-bottom: 35px;
}

.visit-table-container__title {
  color: var(--text-color-black);
  font-size: 22px;
  font-weight: 500;
}

.visit-table {
  grid-column: 1/3;
  margin-top: 20px;
  width: 100%;
  margin-bottom: 20px;
}

.visit-table thead th {
  padding: 10px;
  text-align: left;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: var(--text-color-black);
  background-color: var(--background-darker-grey);
  position: relative;
  vertical-align: middle;
}

.visit-table thead th::after {
  content: "";
  height: 70%;
  width: 1px;
  background-color: var(--border-grey);
  position: absolute;
  right: 0;
  bottom: 10%;
}

.visit-table thead th:last-child::after {
  display: none;
}

.visit-table thead th:first-child {
  border-radius: 10px 0 0 0;
}

.visit-table thead th:last-child {
  border-radius: 0 10px 0 0;
}

.visit-table th:last-of-type {
  padding-right: 30px;
}

.visit-table td {
  padding-left: 10px;
  padding-top: 15px;
  padding-bottom: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  counter-reset: var(--text-color-light-black);
  width: min-content;
  vertical-align: middle;
  border-radius: 10px;
  border: 1px solid var(--background-darker-grey);
}

.visit-table td:last-child {
  padding-left: 10px;
  padding-right: 5px;
}

.visit-form {
  // grid-column: 1/3;
  // align-items: flex-end;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 27px;
  padding-top: 20px;
  border-bottom: 1px solid var(--input-border);
  padding-bottom: 30px;
  margin-bottom: 10px;
}

.visit-form__item {
  display: flex;
  flex-direction: column;
}

.visit-form__item label {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  margin: 5px;
}

.visit-form__item input {
  height: 50px;
  background-color: var(--background-darker-grey);
  border: 1px solid var(--input-border);
  box-sizing: border-box;
  border-radius: 3px;
  padding-left: 10px;
  padding-right: 10px;
  font-family: "IBM Plex Sans";
  font-size: 16px;
}

.visit-form__item-care-general {
  grid-column: 2/4;
  width: 100%;
}

.visit-form__item-care-detailed {
  grid-column: 1/3;
  width: 100%;
}

.refund-button {
  padding: 10px 20px;
  margin-right: 14px;
  margin-left: 14px;
  background: #519025;
  border-radius: 25px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  color: #ffffff;

  &:active {
    background-color: #87ce55;
  }

  &:hover {
    background-color: #87ce55;
  }

  &--disabled {
    background: #f0f0f0;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    border-radius: 25px;
    color: #9ea8ab;
  }
}

.field-wrapper {
  padding: 0 25px 30px 20px;
  
}
//   & div {
//     display: flex;
//     align-items: center;
//   }
// }

.pay-form__calendar-button {
  background: #eceeee;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 12px;
  height: 50px;
  display: flex;
  align-items: center;
}

.pay-form-field-description {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
  margin-bottom: 10px;
}

.field-wrapper input {
  height: 50px;
  width: 100%;
  background: #f0f0f0;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 3px;
  padding-right: 15px;
  padding-left: 15px;
}

.pay-form__submit-button {
  background: #519025;
  border: 1px solid #519025;
  box-sizing: border-box;
  border-radius: 29px;
  padding: 20px 198px 20px 198px;
  margin-bottom: 30px;
  margin-left: 25px;
  margin-right: 25px;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #ffffff;
}

.pay-form__wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pay-form {
  position: relative;
  width: 539px;
  background: #f5f6f7;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.11);
  border-radius: 15px;
  padding-bottom: 10px;
  right: 50%;
  left: 30%;
}

.pay-form__title-wraper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 17px;
  padding-bottom: 17px;
  padding-left: 25px;
  background: #eceeee;
  border-radius: 15px 15px 0px 0px;
  margin-bottom: 20px;
}

.pay-form__title {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  /* identical to box height */

  color: #000000;
}

.pay-form__item-delate-button {
  margin-right: 25px;
}

.pay-form__date {
  margin-left: 20px;
}

.pay-form__soum-input--error {
  box-sizing: border-box;
  border: 1px solid red !important;
}

.pay-form__soum-input {
  height: 50px;
  font-family: "IBM Plex Sans";
  font-size: 16px;
}

.pagination-block {
  grid-column: 2/3;
  margin-left: auto;
  background: #f0f0f0;
  border-radius: 16px;
  height: 32px;
  width: min-content;
  padding: 11px 14px 11px 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
}

.pagination-block__page-button {
  margin-left: 8px;
  margin-right: 8px;
}

.pagination-block__next-button {
  &--back {
    margin-right: 8px;

    & svg path {
      fill: #519025;
    }

    &:disabled svg path {
      fill: #c2c2c2;
    }
  }

  &--forvard {
    margin-left: 8px;

    &:disabled svg path {
      fill: #c2c2c2;
    }
  }
}

.pagination-block__page-button {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;

  color: #c2c2c2;
  &--active {
    background: #519025;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: #ffffff;
  }
}

.refund-list__file-name-block {
  position: relative;
  cursor: pointer;
  background: none;
  outline: none;
  display: flex;
  flex-wrap: wrap;
  word-break: break-word;
}

.refund-list__file-name-block__files {
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.11);
  border-radius: 15px;
  padding: 15px;
  z-index: 10;
}

.refund-list__file-block {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.refund-list__file-block-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 200px;
  background: #f5f6f7;
  border-radius: 10px;
  padding: 17px;
  cursor: pointer;
  margin-bottom: 10px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height */

  color: #787e8a;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.refund-list__file-block-item-name {
  margin-right: auto;
  margin-left: 10px;
}

.visit-form-mobile {
  display: flex;
  flex-direction: column;
  width: 100%;
  // height: 100vh;
  justify-content: flex-start;
  padding-left: 20px;
  padding-right: 20px;
}

.visit-form-mobile__item {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  & label {
    margin-bottom: 5px;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #434343;
  }

  & .label-text.disabled {
    color: #a6a6a6;
  }

  & input {
    padding: 15px;
    height: 50px;
    background: #f9f9f9;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;

    color: #0a0a0a;
  }
}

.visit-form-mobile__button {
  height: 50px;
  background: #519025;
  border-radius: 10px;
  color: #ffffff;
  width: 100%;
}

.visit-button--submit-wrapper {
  // position: fixed;
  // bottom: 30px;
  // right: 0;
  // left: 0;
  // padding-left: 20px;
  // padding-right: 20px;
  justify-self: end;
  margin-bottom: 20px;
}

.visit-form-mobile-field-description {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  color: #434343;
  margin-bottom: 5px;
}

.visit-form-mobile__calendar-button {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 15px;
  height: 50px;
  background: #f9f9f9;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
}

.visit-form-mobile__date {
  margin-left: 15px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  color: #0a0a0a;
}

.personal-area__wrap__right {
  min-width: 776px;
}

.visit-form-input__invalid {
  border: 2px solid red !important;
}

.refund-list__iban-field {
  max-width: 100px;
  word-wrap: break-word;
}

@media (min-width: 800px) {
  .visit-list__contract-number-field {
    max-width: 50px;
    word-wrap: break-word;
  }
}

@media (min-width: 1327px) {
  .visit-list__contract-number-field {
    max-width: 100px;
    word-wrap: break-word;
  }
}

.visit-table-container__title-wrapper {
  display: flex;
  justify-content: space-between;
}

.visit-table__file-list-item {
  display: flex;
  flex-wrap: wrap;
  width: 100% !important;
}

.reqired-form-text {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 18px;
  margin-bottom: 2px;
  color: red;
}

.reqired-form-text.disabled {

}

.edit-medical-appoint-text {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #787e8a;
  margin-bottom: 24px;
}

