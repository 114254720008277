.mobile-dashboard {
  position: relative;
}

.menu-items {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  height: 70px;
  width: 100%;
  padding: 13px 30px 13px 30px;
  background: #ffffff;
  box-shadow: 0px -1px 10px 1px rgba(0, 0, 0, 0.05);
  border-radius: 15px 15px 0px 0px;
  z-index: 100;

  &--hidden {
    display: none;
  }
}

.file-opener__title {
  margin-bottom: 20px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;
}

.file-opener__wrapper {
  padding-top: 100px;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 100% !important;
  height: 100% !important;
}

.react-pdf__Document {
  overflow-x: hidden !important;
}

.mobile__pdf__container #viewerContainer {
  top: 5.5rem !important;
}

.pdfViewer .page {
  border-image: none !important;
  margin: 0 !important;
}

.mobile__pdf__container footer {
  height: 3rem !important;
}

.mobile__pdf__container .toolbarButton.pageUp {
  background-size: 1.6rem !important;
}

.mobile__pdf__container .toolbarButton.pageDown {
  background-size: 1.6rem !important;
}

.mobile__pdf__container .toolbarButton.zoomOut {
  background-size: 1.8rem !important;
}

.mobile__pdf__container .toolbarButton.zoomIn {
  background-size: 1.8rem !important;
}

.mobile__pdf__container #viewerContainer .pdfViewer {
  overflow: visible !important;
}

.download-block {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;

  .download-block-text {
        font-family: IBM Plex Sans;
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 16px;
          color: #000;
          text-align: center;
          margin-bottom: 20px;
  }

  .download-block-link {
    width: 100%;
    background: #519025;
    box-shadow: 0px 3px 10px 2px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    text-align: center;
      align-items: center;
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #ffffff;
      padding: 10px;
  }
}


