.multilogin-menu-container {
  background-color: rgb(255, 255, 255);
  //   left: -22px;
  //   right: -22px;
  //   top: 100px;
  border-radius: 0px 0px 10px 10px;
  border: 1px solid #eceeee;
  //   z-index: 100;
  width: 100%;
  position: relative;
}

.multilogin-menu__separator-line {
  border-bottom: 2px solid #eceeee;
}

.multilogin-menu_item {
  position: relative;
  cursor: pointer;
  padding: 20px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.03);
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.multilogin-menu__trash-button {
  width: 50px;
  height: 50px;
  background: none;
  outline: none;
  z-index: 100;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
}

.multilogin-menu__trash-button-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  z-index: 200;
}

.personal__info__open-menu-arrow {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
}

.multilogin-menu__add-proffile-button-container {
  position: relative;
}

.multilogin-menu__add-proffile-button-wrapper {
  position: absolute;
  z-index: 101;
  left: 0;
  right: 0;
  bottom: -36px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.multilogin-menu__add-proffile-button {
  padding: 10px 25px;
  background: #eceeee;
  border: 1.9px solid #eceeee;
  border-radius: 6px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #2e2e2e;

  &:hover {
    background-color: rgb(236, 238, 250);
  }

  &:active {
    background-color: rgb(236, 238, 210);
  }
  cursor: pointer;
}

.multilogin-menu__add-proffile-button-mobile {
  width: 200px;
  height: 30px;
  left: 87px;
  top: 323px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #f3f3f3;
  border-radius: 4px;

  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  color: #afafaf;

  &:active {
    background-color: rgba(0, 0, 0, 0.05);
  }

  cursor: pointer;
}

.personal-area .persolan-area__conteiner--multilogin {
  padding: 20px 0 0 0;
  margin-bottom: 60px;
}

.persolan-area__conteiner__multilogin__prsonal__wraper {
  padding: 0 20px 20px 20px;
}

.multilogin-menu__item {
  background: #ffffff;
  padding: 0 20px 0 20px;
}

.multilogin-menu__wrapper {
  background-color: rgb(255, 255, 255);
}
