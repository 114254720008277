.mobile-chat__title-block {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 20px;
  height: 80px;
  background: #ffffff;
  box-shadow: 0px -1px 30px rgba(0, 0, 0, 0.1);
}

.mobile-chat_title-mobile {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;

  color: #2d2d2e;
}

.mobile-chat-button-mobile {
  background: none;
  border: none;
  margin-right: 20px;
}

.personal__chat__head.mobile {
  background: #f9f9f9;
  border-radius: 0px;
  height: 60px;
  padding-left: 20px;
  border: none;
  display: flex;
  align-items: center;
}

.personal__chat.mobile {
  padding-top: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  max-width: 100% !important;
  width: 100% !important;
}

.personal__chat__body.mobile {
  padding-bottom: 0;

  padding: 20px 20px 0 20px;
}

.personal__chat-mobile-img {
  width: 30px !important;
  height: 30px !important;
}

.personal__chat__name-mobile {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: bold !important;
  font-size: 14px;
  line-height: 18px;

  color: #0a0a0a;
}

.personal__chat__head__avatar.mobile {
  width: 30px;
  height: 30px;
}

.personal__chat__body .messege__wrap__left .messege .messege__content.mobile {
  background: #f8f8f8;
  box-shadow: 0px 3px 10px 2px rgba(0, 0, 0, 0.1);
  border-radius: 20px 20px 20px 0px;

  &::after {
    display: none;
  }

  & p {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;

    color: #0a0a0a;
  }
}

.personal__chat__body
  .messege__wrap__right
  .messege
  .messege__content
  .messege__content.mobile {
  background: #519025;
  box-shadow: 0px 3px 10px 2px rgba(0, 0, 0, 0.1);
  border-radius: 20px 20px 0px 20px;

  &::after {
    display: none;
  }

  & p {
    position: absolute;
    width: 230px;
    height: 54px;
    left: 110px;
    top: 239px;

    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal !important;
    font-size: 14px;
    line-height: 18px;

    color: #ffffff;
  }
}

.chat__broker-write {
  position: relative;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height */

  color: #afafaf;

  &::before {
    position: absolute;
    content: "";
    height: 8px;
    width: 8px;
    background-image: url("../../../../src/assets/img/pencil.png");
  }
}

.chat-mobile__file-input {
  display: none;
}

.mobile-chat__wrapper--closed {
  position: absolute;
  z-index: -100;
  opacity: 0;
}
