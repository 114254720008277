.app-offer__wraper {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba($color: #fff, $alpha: 0);
}

.app-offer__positioner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.app-offer__main-block-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 80px 1fr;
  width: 989px;
  height: 266px;
  background-color: rgba($color: #fff, $alpha: 0);
}

.app-offer__main-social-links {
  grid-column: 1/3;
  grid-row: 2/3;
  display: flex;
  flex-direction: column;
  width: 989px;
  height: 187px;
  background: #519025;
  box-shadow: 0px 15px 50px rgba(0, 0, 0, 0.17);
  border-radius: 10px;
  padding-left: 62px;
}

.app-offer__close-button {
  background: none;
  outline: none;
  width: 20px;
  height: 20px;
  align-self: flex-end;
  margin-top: 15px;
  margin-right: 25px;
  margin-bottom: 10px;
  position: relative;

  &::before,
  &::after {
    position: absolute;
    content: "";
    height: 2px;
    width: 20px;
    background-color: rgba($color: #fff, $alpha: 1);
    left: 0;
    top: 10px;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
}

.app-offer__close-button:hover {
  &::before,
  &::after {
    background-color: rgba($color: #fff, $alpha: 0.6);
  }
}

.app-offer__close-button:active {
  &::before,
  &::after {
    background-color: rgba($color: #fff, $alpha: 0.4);
  }
}

.app-offer__title {
  margin-bottom: 10px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 39px;
  color: #ffffff;
}

.app-offer__description {
  margin-bottom: 30px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
}

.app-offer__link-block-item {
  background: none;
  text-decoration: none;
  margin-right: 10px;
  cursor: pointer;

  & img {
    width: 137px;
    height: 40px;
  }
}

.app-offer__link-block {
  display: flex;
}

.app-offer__main-block-decor-image {
  grid-column: 2/3;
  grid-row: 1/3;
  width: 275px;
  margin-left: 80px;

  & img {
    width: 100%;
    height: 100%;
  }
}

.app-offer__wraper-mobile {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1000;
}

.app-offer__main-block-info-mobile {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 35px 1fr;
  height: 222px;
  width: 100%;
}

.app-offer__main-social-links-mobile {
  display: grid;
  grid-template-columns: 149px 1fr;
  grid-column: 1/3;
  grid-row: 2/3;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 187px;
  background: #519025;
  box-shadow: 0px 15px 50px rgba(0, 0, 0, 0.17);
  border-radius: 10px;
  padding-left: 150px;
}

.app-offer__main-social-links-wrapper-mobile {
  grid-column: 2/3;
  padding-left: 20px;
  padding-right: 10px;
  display: flex;
  flex-direction: column;

  & button {
    align-self: flex-end;
    margin-right: 10px;
    margin-bottom: -10px;
  }
}

.app-offer__main-block-decor-image-mobile {
  grid-row: 1/3;
  grid-column: 1/2;
  height: 197px;
}

.app-offer__title-mobile {
  margin-bottom: 10px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 100%;
  color: #ffffff;

  &--ios {
    width: 121px;
  }

  &--android {
    width: 100%;
    margin-top: 20px;

    @media (max-width: 385px) {
      margin-top: 0;
    }
  }
}

.app-offer__description-mobile {
  margin-bottom: 10px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  color: #ffffff;

  & span {
    margin-right: 5px;
  }

  & span:nth-of-type(2) {
    margin-left: 2px;
  }
}

.app-offer__main-block {
  margin-bottom: 50px;
}
