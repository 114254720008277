$text-black: #2D2D2E;
$text-light-black:#434343;
$sms-button-text-color: #757575;
$button-background: #F2F2F2;


.check_in {
    padding-top: 50px;
    min-height: calc(100vh - 30px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start; }
    @media only screen and (max-width: 475px) {
      .check_in {
        position: relative;
        padding: 20px; } }
    .check_in__logo {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 50px; }
      @media only screen and (max-width: 475px) {
        .check_in__logo {
          height: 50px;
          margin-bottom: 5px; } }
      .check_in__logo img {
        height: 100%; }
    .check_in .caption {
      font-size: 46px;
      line-height: 52px;
      font-weight: 600;
      font-weight: bold;
      color: $text-black;
      text-align: center;
      margin-bottom: 50px; }
      @media only screen and (max-width: 600px) {
        .check_in .caption {
          font-size: 36px; } }
    @media only screen and (max-width: 600px) and (max-width: 475px) {
      .check_in .caption {
        margin-bottom: 20px; } }
    .check_in .desc {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      font-weight: 400;
      color: $text-light-black;
      text-align: center;
      max-width: 500px;
      margin: 0 auto; }
    .check_in__form {
      position: relative;
      width: 100%;
      max-width: 430px;
      margin: 0 auto;
      margin-top: 50px; }
      @media only screen and (max-width: 475px) {
        .check_in__form {
          margin-top: 20px; } }
      .check_in__form__row {
        display: flex;
        flex-direction: column;
        margin-bottom: 32px; }
        .check_in__form__row label {
          color: $text-light-black;
          font-size: 14px;
          line-height: 24px;
          font-weight: 500;
          font-weight: 400; }
        .check_in__form__row input {
          padding: 15px;
          font-size: 16px;
          line-height: 24px;
          font-weight: 500;
          font-weight: 400;
          color: $text-light-black;
          background: #F5F6F7;
          border: 1px solid #C8C8C8;
          box-sizing: border-box;
          border-radius: 3px; }
      .check_in__form .button {
        width: 100%;
        justify-content: center;
        margin-top: 40px;
        font-weight: 500;
        line-height: 18px;
        font-size: 14px;
        padding: 20px 0px; 

        @media only screen and (max-width: 768px) {
          border-radius: 10px;
          font-size: 16px;
          height: 50px;
        }
      
      }
        .check_in__form .button__success {
          color: white; }
        .check_in__form .button__silver {
          margin-bottom: 20px; }
      .check_in__form .help_desc {
        color: $text-light-black !important; }
      .check_in__form .help__error {
        color: #DF3737;
        line-height: 18px;
        font-size: 14px;
        height: 0px;
        opacity: 0;
        visibility: hidden;
        transition: 0.3s;
        transform: translateX(-50px); }
    .check_in .error .help__error {
      opacity: 1;
      visibility: visible;
      transform: translateX(0px); }
    .check_in .error .help__block__error {
      opacity: 1;
      visibility: visible;
      transform: translateX(0px); }

      .link__other {
        margin-top: 20px; }
        .link__other__row {
          display: flex;
          align-items: center;
          justify-content: center; }
          .link__other__row span {
            font-size: 16px;
            line-height: 26px;
            color: black; }
            @media only screen and (max-width: 768px) {
              .link__other__row span {
                font-size: 14px; } }
          .link__other__row a {
            font-size: 16px;
            line-height: 26px;
            text-decoration: underline;
            color: #18A4E0;
            margin-left: 5px; }
            @media only screen and (max-width: 768px) {
              .link__other__row a {
                font-size: 12px; } } 

.button__sms {
  color: $sms-button-text-color;
  background-color: $button-background;
  border-radius: 10px;
}

.back-button {
  position: absolute;
  top: 20px;
}