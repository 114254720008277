.personal-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;
  position: relative;
  padding-top: 140px;
  padding-bottom: 100px;
}

.personal-info__title {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;

  z-index: 2;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #2d2d2e;
  padding: 39px 0 18px 20px;
  margin-bottom: 40px;
  background: #ffffff;
  box-shadow: 0px -1px 30px rgba(0, 0, 0, 0.1);
}

.personal-info__container {
  position: relative;
  margin-right: 20px;
  margin-left: 20px;
  border-radius: 20px;
  box-shadow: 0px 3px 10px 8px rgba(0, 0, 0, 0.06);
  position: relative;
  margin-bottom: 60px;
  width: 100%;
}

.personal-info__person-title {
  position: absolute;
  left: 0;
  top: -30px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  /* identical to box height */
  color: #020202;
}

.personal-info__photo-container {
  display: flex;
  justify-content: start;
  align-items: center;
  height: 70px;
  background: #f9f9f9;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
}

.personal-info__container-multiprofile {
  display: flex;
  justify-content: start;
  align-items: center;
  height: 70px;
  background: rgb(255, 255, 255);
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
  position: relative;
}

.personal-info__photo {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin-right: 15px;
}

.personal-info__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 15px;
  padding-left: 15px;
  border: 1px solid #f3f3f3;

  & span:first-of-type {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;

    color: #0a0a0a;
  }

  & span:last-of-type {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: right;

    color: #afafaf;

    & a {
      text-decoration: none;
      color: inherit;
    }
  }

  & span:last-of-type.personal-info__item-mail-field-name {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;

    color: #0a0a0a;
  }

  & .personal-info__item-mail {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;

    color: #afafaf !important;
  }
}

.personal-info__photo-insuranse {
  height: 30px;
  width: 30px;
  margin-right: 15px;
  border-radius: 50%;
}

.personal-info__name {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;

  color: #0a0a0a;
}

.personal-info__name--multiprofile {
  width: 100%;
  padding-right: 30px;
}

.personal-info__open-tel-form-button {
  width: 20px;
  height: 20px;
  background: none;
  border: none;
  outline: none;
}

.personal-info__item-personal-phone-number {
  justify-self: flex-end;
  margin-right: 15px;
}

.modal__call__head-mobile {
  display: flex;
  justify-content: start;
  align-items: center;
  height: 80px;
  background: #ffffff;
  box-shadow: 0px -1px 30px rgba(0, 0, 0, 0.1);
  padding-left: 20px;
  margin-bottom: 30px;
}

.tel-form-back-button-mobile {
  margin-right: 20px;
  background: none;
  outline: none;
  border: none;
}

.modal__call__body-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  height: 100%;
}

.caption-mobile {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;

  color: #2d2d2e;
}

.modal__call__body__row-mobile {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 30px;

  label {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #434343;
    margin-bottom: 5px;
  }

  input {
    background: #f9f9f9;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    height: 50px;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 14px !important;
    line-height: 18px;

    color: #0a0a0a;
    margin-bottom: auto;

    &:disabled {
      color: #787e8a;
    }
  }
}

.button__success-mobile {
  background: #519025;
  border-radius: 10px;
  height: 50px;
  width: 100%;
  margin-top: auto;
  justify-self: end;
  margin-bottom: 20px;
}

.madal-change-tel__button-wrapper {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  padding-left: 20px;
  padding-right: 20px;
}

.modal__call__sms-input-mobile {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: auto;

  label {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #434343;
    margin-bottom: 5px;
  }

  input {
    background: #f9f9f9;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    height: 50px;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 14px !important;
    line-height: 18px;

    color: #0a0a0a;
    margin-bottom: auto;
  }
}

.modal__call__sms-input-mobile__wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.modal__wrap-mobile {
  height: 100%;
  width: 100%;
}

.personal-info__logout-button {
  background: #fceaeb;
  border-radius: 10px;
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  color: #ea5455;

  &:active {
    background: #f7cacc;
  }
}

.personal-info__notification-button {
  position: relative;
  width: 51px;
  height: 32px;
}

.personal-info__notification-button--on {
  position: relative;
  width: 51px;
  height: 32px;
  background-color: #519025;
  border-radius: 16px;
  &::after {
    position: absolute;
    content: "";
    top: 3px;
    right: 2px;
    width: 26px;
    height: 26px;
    background: #ffffff;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
    border-radius: 13px;
  }
}

.personal-info__notification-button--off {
  position: relative;
  width: 51px;
  height: 32px;
  background-color: #5a5c58;
  border-radius: 16px;
  &::after {
    position: absolute;
    content: "";
    top: 2.7px;
    left: 2px;
    width: 26px;
    height: 26px;
    background: #ffffff;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
    border-radius: 13px;
  }
}

.personal-info____add-proffile-button-container {
  position: absolute;
  bottom: -15px;
  left: 0;
  right: 0;
}

.personal-info____add-proffile-button-wrapper {
  display: flex;
  justify-content: center;
}
