html, body {
  touch-action: manipulation;
}

.main-information-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-bottom: 70px;
  padding-top: 100px;
}

.main-information-mobile__user {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-right: 20px;
  padding-left: 20px;
  width: 100%;
  height: 80px;
  background: #ffffff;
  box-shadow: 0px -1px 30px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;
}

.personal-info__mobile-photo {
  border-radius: 50%;
  margin-right: 10px;
  & img {
    width: 36px;
    height: 36px;
    border-radius: 50%;
  }
}

.personal-info__mobile-name {
  margin-right: auto;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;

  color: #0a0a0a;
}

.main-information-mobile__call-button {
  width: 36px;
  height: 36px;
  background: #dcfdd3;
  border-radius: 10px;
  display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 6px;
}

.main-information-mobile__chat-button {
  position: relative;
  width: 131px;
  height: 36px;
  background: #dcfdd3;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height */

  color: #519025;
}

.main-information-mobile__chat-button--new-message::after {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background-color: #ea5455;
  right: -6px;
  top: -3px;
  border: 2px solid white;
}

.main-information-mobile__information-block {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-left: 20px;
  padding-right: 20px;
  gap: 15px;
  margin-bottom: 30px;
  position: relative;
}

.main-information-call-to-insurance {
  grid-column: 1 / 3;
  padding: 16px 26px;
  border-radius: 10px;
  background: #ea5455;
  font-family: IBM Plex Sans;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  text-align: center;
  transition: all .5s ease;
}

.calculator-main-page-counter {
    position: absolute;
    min-width: 22px;
    top: 50%;
    transform: translateY(-50%);
    padding: 5px;
    border-radius: 50%;
    font-size: 10px;
    line-height: 12px;
    color: #fff;
    background-color: #ff0000;
    text-align: center;
    margin-left: 10px;
}

.calculator-main-page-icon {
  vertical-align: -2px;
  margin-right: 10px;
}

.calculator-main-page-chevron-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
}

.main-information-call-to-insurance.open {
  color: #ea5455;
  border: 1px solid #ea5455;
  background: transparent;
}

.main-information-call-to-insurance-icon {
  margin-left: 10px;
  vertical-align: bottom;
}

.main-information-mobile__tax-block_item {
  grid-column: 1/3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
    min-height: 87px;
    grid-template-columns: 1fr 1fr;
    background: #ffffff;
    box-shadow: 0px 3px 10px 2px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #ffffff;
    padding: 16px;
}

.main-information-mobile__information-block-head {
  grid-column: 1/3;
  display: grid;
  min-height: 87px;
  grid-template-columns: 1fr 1fr;
  background: #519025;
  box-shadow: 0px 3px 10px 2px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  align-items: center;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height */

  color: #ffffff;

  position: relative;

  &::before {
    position: absolute;
    content: "";
    height: 57px;
    width: 1px;
    background-color: #fff;
    left: 50%;
    right: 50%;
  }
}

.main-information-mobile__information-block-head-name {
  margin-left: 20px;
}

.main-information-mobile__information-block-head-information {
  margin-left: 28px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.main-information-mobile__information-block__item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  min-height: 87px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 3px 10px 2px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 16px;
}

.main-information-mobile__information-block__item-name {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height */

  color: #afafaf;
}

.main-information-mobile__information-block__item-information {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  color: #0a0a0a;
}

.main-information-mobile_files-block {
  padding-right: 20px;
  padding-left: 20px;
  width: 100%;
  margin-bottom: 30px;
}

.main-information-mobile_files-block__title {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  /* identical to box height */

  color: #020202;
  align-self: flex-start;
  margin-bottom: 15px;
}

.main-information-mobile_list__item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 50px;
  width: 100%;
  background: #f9f9f9;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 15px;
  cursor: pointer;

  &:active {
    background-color: #f7eeee;
  }
}

.main-information-mobile_list__item-image {
  margin-right: 15px;
}

.main-information-mobile_list__item-name {
  margin-right: auto;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  color: #787e8a;
}
.main-information-mobile__watch-icon {
  margin-left: 10px;
  width: 17px;
  height: 10px;
}

.main-information-mobile__refund-info {
  position: relative;
  padding-top: 0 !important;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.main-information-mobile__person-title {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;

  color: #020202;
  margin-bottom: 15px;
}

.main-information-mobile-client-card__container {
  grid-column: 1 / 3;

  &--open {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.31);
    backdrop-filter: blur(6px);
    z-index: 1000;
    grid-column: 1 / 3;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.main-information-mobile-client-card__wrapper {
  background: #272730;
  box-shadow: 0px 3px 10px 2px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  width: 100%;
  background-image: url("../../../../../assets/img/client-card/client-card-image.png");
  background-repeat: no-repeat;
  background-size: cover;

  &--rotate {
    transform: rotate(90deg);
  }
}

.main-information-mobile-client-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  box-shadow: 0px 3px 10px 2px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  min-height: 204px;
  width: 100%;

  cursor: pointer;
}

.main-information-mobile-client-card__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 7px;
}

.main-information-mobile-client-card__tbt-name-wrapper-logo {
  width: 62px;
  height: 21px;
}

.main-information-mobile-client-card__name {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;

  /* identical to box height */

  color: #ffffff;
}

.main-information-mobile-client-card__police-container span:first-of-type {
  margin-right: 4px;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;

  color: #ffffff;
}

.main-information-mobile-client-card__company-name {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 500;

  color: #9ed99d;
}

.main-information-mobile-client-card__active-period {
  display: flex;

  & span:first-of-type {
    margin-right: 4px;
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 400;

    /* identical to box height */

    color: #9ed99d;
  }

  & span {
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 600;

    /* identical to box height */

    color: #9ed99d;
  }
}

.main-information-mobile-client-card__phone-info {
  display: flex;
  align-items: center;
}

.main-information-mobile-client-card__phone-number {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  color: #9ed99d;
  margin-left: 4px;
  display: flex;
  align-items: center;
}

.persolan-area__conteiner__show-insurance-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 194px;
  height: 42px;
  background: rgba(81, 144, 37, 0.05);
  border: 1px solid #519025;
  border-radius: 29px;
  cursor: pointer;

  &:hover {
    background: rgba(81, 144, 37, 0.03);
  }

  &:active {
    background: rgba(81, 144, 37, 0.08);
  }

  & span {
    margin-left: 9px;
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;

    color: #519025;
  }
}

.persolan-area__conteiner__row {
  display: flex;
  flex-direction: row;
  align-items: center;

  & .large__18.text {
    width: 23%;
  }
}

.main-information-mobile-client-card__container-descktop {
  position: fixed;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.31);
  backdrop-filter: blur(6px);

  & .main-information-mobile-client-card__wrapper {
    margin-top: 15%;
    width: 600px;
    height: 365px;
    position: relative;
  }
}

.main-information-mobile-client-card__close-button {
  position: absolute;
  top: -16px;
  right: -39px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  z-index: 1002;
}

.main-information-mobile-client-card__card-name-wrapper img {
  height: auto;
}

.calculator-main-page-container {
  grid-column: 1 / 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.calculator-main-page-link {
  position: relative;
width: 100%;
padding: 16px;
color: #519025;
font-family: "IBM Plex Sans";
font-size: 14px;
font-weight: 600;
background-color: #dcfdd3;
border-radius: 10px;
text-align: center;
}

.calculator-main-icons {
  vertical-align: sub;
  margin-right: 10px;
}


////////////////////////////////////////////////


.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal.open {
  display: block;
}

.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: 30% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 90%;
  border-radius: 15px;

  .modal-content-button-container {
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 100%;

    .modal-content-buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      background-color: #dcfdd3;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
      color: #519025;
      font-family: IBM Plex Sans;
      font-size: 14px;
      font-weight: 600;
      padding: 7px 0;
      height: 44px;

      .modal-content-svg {
        vertical-align: middle;
        margin-right: 6px;
      }
    }
  }

  .modal-content-title {
    color: #0A0A0A;
    text-align: center;
    font-family: IBM Plex Sans;
    font-size: 16px;
    font-weight: 700;
    max-width: 190px;
    margin: 0 auto;
    margin-bottom: 10px;
  }

  .modal-content-text {
    color: #000;
    text-align: center;
    font-family: IBM Plex Sans;
    font-size: 12px;
    font-weight: 400;
    max-width: 230px;
    margin: 0 auto;
    margin-bottom: 16px;
  }
}

.close {
  position: absolute;
  top: 4px;
  right: 4px;
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.main-information-mobile-inform-container {
  position: absolute;
  top: -30px;
  width: 300px;
  padding: 10px;
  border-radius: 15px;
  background: #fff;
  border: 1px solid #727272;
}

.user-history-doctor {
  top: -10px;
  left: 20px;
}

.main-information-mobile-inform-text {
  font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
}

.telephone-buttons-block {
  grid-column: 1 / 3;
    display: flex;
    flex-direction: column;
}

.list-item {
  opacity: 0;
  height: 0px;
  margin-top: 0px;
  display: block;
  padding: 0;
  transition: transform .5s ease;
  transform: translateX(-100%);
}

.list-item.show {
  opacity: 1;
  height: auto;
  margin-top: 10px;
  transition: transform .5s ease;
  padding: 16px 26px;
  transform: translateX(0%);
}



