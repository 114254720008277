.notification-page-header-title {
        position: relative;
        margin-right: auto;
        font-family: IBM Plex Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 18px;
        color: #0a0a0a;
}

.notification-page-header-wrapper {
    display: flex;
    align-items: center;
    height: 86px;
    width: 100%;
    position: relative;
    padding-right: 20px;
    padding-left: 20px;
}

.notification-page-header-filter-button {
    padding: 10px;
    cursor: pointer;
    background: transparent;
    border: none;

    svg {
        width: 20px;
        height: 20px;
    }
}

.notification-page-header-counter {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    min-width: 22px;
    padding: 5px;
    border-radius: 50%;
    font-size: 10px;
    line-height: 12px;
    color: #fff;
    background-color: #ff0000;
    text-align: center;
    margin-left: 10px;
}

.notification-page-list-loader {
    display: block;
    width: 20px;
    height: 20px;
    margin: 0 auto;
    animation: rotate 2s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.notification-page-list {
    padding: 0 15px 10px 15px;
}

.notification-page-date-block:not(:first-child) {
    margin-top: 10px;
}

.notification-page-date-title {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #afafaf;
    margin-bottom: 10px;
}

.notification-page-items-container {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.notification-page-item-block-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #519025;
    font-family: IBM Plex Sans;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.5;
    text-align: center;
    color: #FFFFFFA8;
    margin: auto 0;
}

.notification-page-item-block-big-img {
    width: 100%;
}

.notification-page-item-info-block {
    width: 80%;
}

.notification-page-item-block {
    position: relative;
    display: flex;
    gap: 20px;
    padding: 15px;
    border-radius: 10px;
    cursor: pointer;
    border: 1px solid #E1E1E1;
    box-shadow: 0px 3px 10px 2px rgba(0, 0, 0, 0.1);
}

.notification-page-item-block.new-item {
    background: #5190251A;
}

.notification-page-item-block-title {
    font-family: IBM Plex Sans;
    font-size: 14px;
    font-weight: 500;
    line-height: 18.2px;
    text-align: left;
    margin-bottom: 5px;
    width: 80%;
}

.notification-page-item-block-date {
    position: absolute;
    top: 10px;
    right: 10px;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #afafaf;
    margin-bottom: 10px;    
}

.notification-page-item-block-description {
    font-family: IBM Plex Sans;
    font-size: 10px;
    font-weight: 400;
    line-height: 11px;
    text-align: left;
    word-break: break-word;
}

.notification-page-watch-more-button {
    width: 100%;
    left: 20px;
    top: 167px;
    border-radius: 10px;
    border: 2px solid #519025;
    margin-top: 20px;
    background: transparent;
    padding: 15px 0px;
    cursor: pointer;

    p {
        font-family: IBM Plex Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #519025;
        text-align: center;
    }
}

.notification-page-item-block-button-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 20px;
}

.notification-page-item-block-big-description {
    font-family: IBM Plex Sans;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    padding: 20px;
}

.notification-info-page-main-block {
   
}

.notification-page-empty-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
}